import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import DashboardNavigation from "./DashboardMenu";

import { FiMenu } from "react-icons/fi";
const TitleNav = ({ pathname, menuOpen, setMenuOpen }) => {
  return (
    <div className="py-5 md:hidden px-4 lg:px-8 lg:py-5 w-full border-b">
      <div className="flex md:hidden justify-end items-end gap-4">
        <FiMenu
          className="md:hidden  w-8 text-black cursor-pointer"
          onClick={() => setMenuOpen(true)}
        />
      </div>

      {/* mobile menu start */}
      {menuOpen && (
        <div
          className="fixed left-0 right-0 bottom-0 h-screen w-full lg:hidden bg-[#0000003d] z-[99999]"
          onClick={() => setMenuOpen(false)}
        >
          <AnimatePresence>
            <motion.div
              initial={{ x: -100 }}
              animate={{ x: 0 }}
              exit={{ x: -100 }}
              className="fixed top-0 left-0 bg-white h-screen"
              onClick={(e) => e.stopPropagation()}
            >
              <DashboardNavigation pathname={pathname} />
            </motion.div>
          </AnimatePresence>
        </div>
      )}

      {/* mobile menu end */}
    </div>
  );
};

export default TitleNav;
