import React from "react";
import { TiTimes } from "react-icons/ti";
import { NavLink } from "react-router-dom";
import "./Notification.css";
function Notification() {
  return (
    <div>
      <div className="notify-container flex items-center px-2 text-white">
        <div className="w-full sm:flex sm:justify-center text-kbody5 font-semibold">
          We’ve just launched a new feature!{" "}
          <span className="text-[#F4EBFF] font-normal">
            Check out the new{" "}
            <NavLink to="/dashboard/home" className="underline">
              dashboard
            </NavLink>{" "}
            .
          </span>
        </div>
        <div className="flex justify-end">
          <TiTimes />
        </div>
      </div>
    </div>
  );
}

export default Notification;
