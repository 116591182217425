import React from "react";

import { useEffect } from "react";
import { BiSearch } from "react-icons/bi";
import {
  MdKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { NavLink } from "react-router-dom";
import DashboardLayout from "../../DashboardHome/DashboardLayout";

function Guide() {
  useEffect(() => {});
  return (
    <DashboardLayout>
      <div className="w-full mt-0 bg-white h-[auto]">
        <div className="pb-[1.5rem] border-b pl-[3rem] flex bg-white items-center">
          <NavLink
            to="/dashboard/account"
            className="flex items-center mt-[.5rem]"
          >
            <div className="w-[48px] h-[48px] mr-[1rem] flex justify-center cursor-pointer items-center bg-[#F2F4F7] rounded-full">
              <MdKeyboardArrowLeft />
            </div>
            Account
          </NavLink>
        </div>
        <div className="w-[50%] mx-auto py-[5rem] ">
          <h2 className="mb-[1.3rem] text-3xl">
            Hi, what can we help you with today?
          </h2>
          <div className="bg-[#F2F4F7] w-[90%] p-3 flex items-center rounded-[10px]">
            <BiSearch className="text-3xl text-primary mr-[1rem]  " />{" "}
            <input
              className="bg-transparent w-[80%]  outline-none"
              placeholder="Search a topic"
            />
          </div>
          <h6 className="my-[1.4rem]">Popular help resources</h6>
          <div className="flex items-center pb-[.8rem]  justify-between">
            <div>
              <h5>Change password</h5>
              <p>
                Lorem ipsum dolor sit,Lorem ipsum dolor sit,Lorem ipsum dolor
                sit, amet consectetur adipisicing elit. Fugiat nisi,{" "}
              </p>
            </div>
            <div>
              {" "}
              <MdOutlineKeyboardArrowRight className="text-4xl   mr-[1rem] " />
            </div>
          </div>
          <hr className="w-[80%] text-center mr-auto mb-[1.5rem]"></hr>
          <div className="flex pb-[.8rem] items-center mb-[1.3rem] justify-between">
            <div>
              <h5>Reporting an account</h5>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugiat
                nisiLorem ipsum dolor sit,Lorem ipsum dolor sit,,{" "}
              </p>
            </div>
            <div>
              {" "}
              <MdOutlineKeyboardArrowRight className="text-4xl   mr-[1rem] " />
            </div>
          </div>
          <hr className="w-[80%] text-center mr-auto mb-[1.5rem]"></hr>

          <div className="flex pb-[.8rem] items-center mb-[1.3rem] justify-between">
            <div>
              <h5>How to protect your personal information</h5>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugiat
                nisi,{" "}
              </p>
            </div>
            <div>
              {" "}
              <MdOutlineKeyboardArrowRight className="text-4xl   mr-[1rem] " />
            </div>
          </div>
          <hr className="w-[80%] text-center mr-auto mb-[1.5rem]"></hr>

          <div className="flex pb-[.8rem] items-center mb-[1.3rem] justify-between">
            <div>
              <h5>About public and protected post</h5>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugiat
                nisi,{" "}
              </p>
            </div>
            <div>
              {" "}
              <MdOutlineKeyboardArrowRight className="text-4xl   mr-[1rem] " />
            </div>
          </div>
          <hr className="w-[80%] text-center mr-auto mb-[1.5rem]"></hr>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Guide;
