import React from "react";
import Slider from "react-slick";
import "./Slide.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Slide({ data, right }) {
  const settingSlider = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2500,
    rtl: right,
    cssEase: "linear",
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
  };
  return (
    <div className="w-full aradugbo-slider">
      <Slider
        {...settingSlider}
        className="w-full people-row gap-8 flex mb-[30px] sm:mb-[31px]"
        id="people-row-1"
      >
        {data.map((d, i) => (
          <div className="mx-[.5rem] w-[20%]" key={i}>
            <img className="w-full" src={d.img} alt={d.alt} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Slide;
