import axios from "axios";
import { postMessage } from "../api";
import { liveTypes } from "./live.type";

export const setLiveLoading = (payload) => async (dispatch) => {
  dispatch({
    type: liveTypes.SET_LIVE_LOADING,
    payload,
  });
};

export const getRoomDetails = (roomId) => async (dispatch, getState) => {
  const token = getState().auth.accessToken;
  try {
    setLiveLoading(true);
    dispatch({
      type: liveTypes.FETCH_LIVE_CONTENT_START,
    });
    const { data } = await axios.get(
      `https://socket.myaradugbo.com/live/${roomId}`,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setLiveLoading(false);
    return dispatch({
      type: liveTypes.FETCH_LIVE_CONTENT,
      payload: data,
    });
  } catch (err) {
    setLiveLoading(false);
    dispatch({
      type: liveTypes.FETCH_LIVE_CONTENT_ERROR,
      payload: err,
    });
  }
};

export const postMsg = (payload) => async (dispatch) => {
  try {
    setLiveLoading(true);
    dispatch({
      type: liveTypes.POST_MESSAGE_START,
    });
    const response = await postMessage(payload);
    setLiveLoading(false);
    return dispatch({
      type: liveTypes.POST_MESSAGE,

      payload: response,
    });
  } catch (err) {
    setLiveLoading(false);
    dispatch({
      type: liveTypes.POST_MESSAGE_ERROR,
      payload: err.response,
    });
  }
};
