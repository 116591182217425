import React, { useState } from "react";
import { fullscreen_icon } from "../../constants/images";
import { CiSearch, CiZoomIn, CiZoomOut } from "react-icons/ci";
import SingleUserPost from "../SingleUserPost/SingleUserPost";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../../redux/features/features.action";
import { useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import DashboardLayout from "./DashboardLayout";
import { getAllUserPost, searchEvents } from "../../redux/api";
import "mapbox-gl/dist/mapbox-gl.css";
import SocketManager from "../../socket/socketManager";
import MapBox from "../Map/map";
import {
  getMapEvents,
  getUser,
  logOut,
  setModalStatus,
  setStatus,
} from "../../redux/auth/auth.actions";
import ProfileUpdateNotice from "../Modal";
import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import AccountModal from "../Modal/AccountModal";
import ReportModal from "../Modal/ReportModal";
import UploadEmergency from "../Upload/UploadEmergency";
import UploadEmergencyInfo from "../Upload/UploadEmergencyInfo";
import SharePost from "../SingleUserPost/SharePost";
import DashboardSide from "./DashboardSide";
import UserCard from "./UserSlider/UserCard";
import {
  closeSinglePost,
  viewSinglePost,
} from "../../redux/posts/posts.action";

function DashboardHome() {
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const token = useSelector((state) => state.auth.accessToken);
  const uploadModalStatus = useSelector((state) => state.auth.showUploadModal);
  const latestPost = useSelector((state) => state.posts.uploadData);
  const modalId = useSelector((state) => state.posts.modalId);
  const postId = useSelector((state) => state.posts.postId);

  const modalActive = useSelector((state) => state.posts.modalActive);

  const mapEvents = useSelector((state) => state.auth.mapEvents);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newDoc, setNewDoc] = useState(null);
  const [profileIncomplete, setProfileIncomplete] = useState(false);
  const [showAds, setShowAds] = useState(false);
  const [, setLoadingMore] = useState(false);
  const [search, setSearch] = useState("");
  const [, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [activePostId, setActivePostId] = useState("");
  const [, setCount] = useState();
  const { user } = useSelector((state) => state.auth);
  const [stream, setStream] = useState(null);
  const [reportModal, setReportModal] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({
    longitude: null,
    latitude: null,
  });
  const [suspended, setSuspended] = useState(false);
  const [page] = useState(1);
  const [, setTotalPages] = useState(0);
  const [, setEmergencyModalId] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const router = useLocation();
  const [, setIndex] = useState(2);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;

                setCurrentLocation({ longitude, latitude });
                getStream({ longitude, latitude });
                setTrigger(!trigger);
              },
              (error) => {
                console.error("Error getting location:", error);
              },
              { enableHighAccuracy: true, maximumAge: 0, timeout: 60000 }
            );
          }
        })
        .catch((err) => err);
    } else {
      console.error("Geolocation is not supported in this browser.");
    }

    // eslint-disable-next-line
  }, []);

  dayjs.extend(RelativeTime);

  useEffect(() => {
    dispatch(setIsLoading(true));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentLocation?.longitude) {
      SocketManager?.instance?.emitListLiveStream({
        lat: currentLocation?.latitude,
        lon: currentLocation?.longitude,
      });
      SocketManager?.instance?.listenListLiveStream((data) => {
        setStream(data);
      });
    }
    // eslint-disable-next-line
  }, []);

  const getStream = ({ longitude, latitude }) => {
    SocketManager?.instance?.emitListLiveStream({
      lat: latitude,
      lon: longitude,
    });
    SocketManager?.instance?.listenListLiveStream((data) => {
      setStream(data);
    });
  };

  useEffect(() => {
    if (
      (user?.phoneNumber === undefined ||
        user?.displayName === "" ||
        user?.dob === null) &&
      token
    ) {
      const intervalId = setTimeout(function () {
        onOpen();
        setProfileIncomplete(true);
      }, 90000);
      return () => clearInterval(intervalId);
    }

    // eslint-disable-next-line
  }, []);

  const getEvents = useCallback(
    async (more) => {
      setLoading(true);
      try {
        // more ? setLoadingMore(true) : setLoading(true);
        const { data } = await getAllUserPost({ more, newDoc });
        if (data) {
          setNewDoc(data?.lastDoc);
          setEvents([...events, ...data.events]);
          setLoading(false);
          setLoadingMore(true);
        }
      } catch (error) {
        setLoading(false);
      }
      setIndex((prevIndex) => prevIndex + 1);
    },
    // eslint-disable-next-line
    [newDoc]
  );

  const handleSinglePostModal = (post) => {
    if (token) {
      if (user.suspend) {
        setSuspended(true);
      } else {
        dispatch(
          viewSinglePost({
            postId: post?.id,
            modalId: 10,
            modalActive: isOpen,
          })
        );

        setCount(post?.likeCount);
        getEvents();
      }
    } else {
      navigate("/login");
    }
  };

  const closeSinglePostModal = () => {
    dispatch(closeSinglePost());
  };

  useEffect(() => {
    if (user !== {}) {
      dispatch(getUser());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user?.coords) {
      dispatch(getMapEvents(user?.coords));
    } else if (currentLocation) {
      dispatch(getMapEvents(currentLocation));
    }
    // eslint-disable-next-line
  }, []);

  const visibleTodos = useCallback(() => {
    getEvents(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    visibleTodos();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    visibleTodos();
  };

  useEffect(() => {
    if (user.isDeleted) {
      dispatch(setStatus(true));
      dispatch(logOut());
    }
    // eslint-disable-next-line
  }, [user]);

  const reportPost = (id) => {
    setActivePostId(id);
    setReportModal(!reportModal);
  };

  useEffect(() => {
    if (router.pathname === "/dashboard/events/uploads") {
      onOpen();
      setEmergencyModalId(1);
    }
    // eslint-disable-next-line
  }, []);

  const close = (id) => {
    onClose();
    dispatch(setModalStatus(0));
  };

  // const closeAds = () => {
  //   onClose();
  //   setShowAds(false);
  // };

  const updateModalId = (item) => {
    dispatch(setModalStatus(item));
    setEmergencyModalId(item);
    onOpen();
  };

  const handleSearch = async (more = false) => {
    setLoading(false);
    try {
      !more && setLoading(true);
      const { data } = await searchEvents({ page, search });
      if (data) {
        const documents = data?.hits?.map((item) => {
          return {
            ...item.document,
            media: {
              images: item.document["media.images"],
              videos: item.document["media.videos"],
            },
          };
        });
        setEvents(page === 1 ? documents : [...events, ...documents]);
        setTotalPages(Math.ceil(data.found / data.request_params.per_page));
        setLoading(false);
        setLoadingMore(false);
      }
    } catch (error) {
      setLoading(false);
      // Sentry.Native.captureException(error);
    }
  };

  const handleSearchNow = () => {
    handleSearch();
  };

  useEffect(() => {
    setTimeout(setShowAds(!showAds), 100000);
    // eslint-disable-next-line
  }, []);
  return (
    <DashboardLayout>
      <>
        <div
          className={`w-full flex ${
            darktheme ? "bg-kdark3 text-[#888a8b]" : "bg-[#fff] text-[#1C2024]"
          }`}
        >
          <div className="w-full fixed bottom-0 top-0">
            <div className="relative w-[70%]">
              <div className="w-full ">
                {mapEvents && (
                  <MapBox
                    mapEvents={mapEvents?.data}
                    user={user}
                    token={token}
                  />
                )}
              </div>
              <div className="w-[453px] h-[45px] ml-[54px] mt-10 rounded-[43px] pl-[15px] bg-[#F9FAFB] flex absolute top-0 items-center ">
                <CiSearch onClick={handleSearchNow} />
                <input
                  className="pl-[11px] text-[15px] w-full h-full border-none outline-none bg-[#F9FAFB] rounded-[43px]"
                  placeholder="Search Community"
                  onKeyDown={handleSearch}
                  onChange={(e) => setSearch(e.target.value)}
                  name="search"
                />
              </div>
              {/* fullscreen,zoom in and out */}
              <div className="absolute right-3 bottom-3 flex">
                <div className="flex relative bg-[#F9FAFB] px-[18px] h-[40px] items-center rounded-[26px]">
                  <img
                    src={fullscreen_icon}
                    alt="icon"
                    className="w-[18px] h-[18px]"
                  />
                  <span className="text-[14px] ml-[13px]">Fullscreen</span>
                </div>
                <div className="flex relative ml-[9px] bg-[#F9FAFB] px-[18px] h-[40px] items-center rounded-[26px]">
                  <div>
                    <CiZoomIn size={18} />
                  </div>
                  <div className="w-[3px] h-[40px] mx-[13px] bg-[#F3F5F7]"></div>
                  <div>
                    <CiZoomOut size={18} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`w-[386px] absolute h-[100vh] right-0 ${
              darktheme
                ? "bg-kdark3 text-[#888a8b]"
                : "bg-[#fff] text-[#1C2024]"
            }`}
            id="scrollableDiv"
            style={{ overflowY: "scroll" }}
          >
            {stream && currentLocation && (
              <div className="w-full px-[2rem] mt-[1rem]">
                <UserCard item={stream} />
              </div>
            )}

            {/* post */}
            <DashboardSide
              events={events}
              user={user}
              token={token}
              openReportModal={reportPost}
              callEvents={() => getEvents(true)}
              handleSinglePost={handleSinglePostModal}
              updateCounts={getData}
              isOpen={isOpen}
              onClose={onClose}
            />
          </div>
        </div>
        {suspended && (
          <AccountModal
            isOpen={suspended}
            onClose={() => setSuspended(false)}
            title={"Your account has been Suspended"}
            description="Figma ipsum component variant main layer. Flows star follower
       figma shadow connection polygon flows. Effect figjam ellipse union
       draft bullet figjam plugin star. Horizontal draft scrolling."
            type="Suspended"
          />
        )}
        {profileIncomplete && (
          <ProfileUpdateNotice
            user={user?.username}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}

        {modalId === 10 && token && (
          <SingleUserPost
            updateCounts={getData}
            handle={() => handleSinglePostModal()}
            theme={darktheme}
            id={postId}
            isOpen={modalActive && modalId === 10}
            onClose={closeSinglePostModal}
          />
        )}
        {reportModal && (
          <ReportModal
            id={activePostId}
            title={"Your account has been deleted."}
            description="Figma ipsum component variant main layer. Flows star follower
       figma shadow connection polygon flows. Effect figjam ellipse union
       draft bullet figjam plugin star. Horizontal draft scrolling."
            isOpen={reportModal}
            onClose={reportPost}
          />
        )}
        {uploadModalStatus === 6 && (
          <UploadEmergency
            isOpen={uploadModalStatus === 6}
            getEvent={getData}
            onClose={close}
            updateModal={updateModalId}
            type={"My"}
          />
        )}

        <UploadEmergencyInfo
          isOpen={uploadModalStatus === 2 && isOpen}
          onClose={close}
          getEvent={getData}
          updateModal={updateModalId}
          setEmergencyModalId={updateModalId}
          type={"My"}
        />
        <SharePost
          theme={darktheme}
          isOpen={uploadModalStatus === 3 && isOpen}
          onClose={onClose}
          id={latestPost?.id}
          header="Uploaded Successfully"
          title={latestPost?.title}
        />
      </>
    </DashboardLayout>
  );
}

export default DashboardHome;
