import React, { useEffect, useState } from "react";
import DashboardLayout from "../DashboardHome/DashboardLayout";
import LivestreamCard from "./livestreamCard";
import SocketManager from "../../socket/socketManager";
// import LiveSkeletonLoader from "../Skeleton/LivestreamLoader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaFolderOpen } from "react-icons/fa";

function AllLivestream() {
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const [stream, setStream] = useState([]);
  const token = useSelector((state) => state.auth.accessToken);
  const navigate = useNavigate();
  const [currentLocation, setCurrentLocation] = useState({
    longitude: null,
    latitude: null,
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;

                setCurrentLocation({ longitude, latitude });
              },
              (error) => {
                console.error("Error getting location:", error);
              },
              { enableHighAccuracy: true, maximumAge: 0, timeout: 60000 }
            );
          }
        })
        .catch((err) => err);
    } else {
      console.error("Geolocation is not supported in this browser.");
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    SocketManager?.instance?.socket?.connect();
  }, []);

  useEffect(() => {
    SocketManager?.instance?.emitListLiveStream({
      lat: currentLocation?.latitude,
      lon: currentLocation?.longitude,
    });
    SocketManager?.instance?.listenListLiveStream((data) => {
      setStream(data);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/dashboard/home");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <DashboardLayout>
      {stream?.length === 0 && (
        <div className=" text-center h-[100vh] flex justify-center align-middle items-center">
          <div
            className={`${
              darktheme ? "bg-[black]" : "bg-white"
            } p-[4rem] rounded-[10px]`}
          >
            <FaFolderOpen className="w-20 h-20 mx-auto text-[#e5e6e5]" />
            <p>
              There are no streams available yet, your streams will show up.
              here.
            </p>
          </div>
        </div>
        // <div className=" gap-2 flex-col md:flex-row justify-center md:flex flexwrap mt-[2rem]">
        //   <LiveSkeletonLoader />
        //   <LiveSkeletonLoader />
        //   <LiveSkeletonLoader />
        // </div>
      )}
      <div className="flex flex-wrap gap-4 p-4">
        {stream &&
          stream?.map((item, index) => (
            <LivestreamCard stream={item} key={index} />
          ))}
      </div>
    </DashboardLayout>
  );
}
export default AllLivestream;
