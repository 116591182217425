import React from "react";
import { apple_icon, google_play_icon, iphone2 } from "../../constants/images";
import Button from "../Button/Button";

function Join() {
  return (
    <div className="w-full  mt-[100px] px-[17px] sm:px-[40px] text-white">
      <div className="join_container lg:max-w-[1184px] m-auto flex  flex-col justify-between bg-[#FC5059] pt-[24px] sm:pt-[100px]">
        <div className="flex flex-col join_main_body">
          <h5 className="text-[1rem] md:text-kbody4 font-bold">
            Join us – We are on a mission to build a safer community for
            everyone.
          </h5>
          <p className={`mt-[16px] text-[#F2F2F2]`}>
            We are not powerless, but we can make a difference by working
            together to create peace, safety and security for everyone.
          </p>
          <div className="join_button flex mt-[47px] sm:mt-[51px] text-white">
            <Button img={google_play_icon} store="Google Play" />
            <Button img={apple_icon} store="App Store" />
          </div>
        </div>
        <div className="flex self-center">
          <img src={iphone2} alt="two iphones" />
        </div>
      </div>
    </div>
  );
}

export default Join;
