import React, { useState, useRef, useEffect } from "react";

export function WaitlistModal({ closeModal }) {
  const [copied, setCopied] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClick, true);

    return () => {
      document.removeEventListener("mousedown", handleClick, true);
    };
    // eslint-disable-next-line
  }, [modalRef]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText("https://myaradugbo.com");
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 5000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div className={`fixed z-50 inset-0 flex items-center justify-center`}>
      <div ref={modalRef} className="flex items-center justify-center">
        <div className="w-full max-w-md bg-white rounded-lg shadow-md px-5 pt-7 pb-12 flex flex-col text-center">
          <div className="self-center flex justify-center items-center mb-4 w-20 h-20 bg-[#00003B0D]/5 rounded-full">
            <p className="text-4xl">🎉</p>
          </div>
          <p className="text-xl	font-semibold mb-4">You’re on the list!</p>
          <p className="text-sm font-normal	 text-[#425466]">
            You’ll be notified once we launch. Invite your friends and family to
            experience Arad using the button below.
          </p>
          <div className="mt-3 md:mx-8 flex rounded-full justify-between items-center flex-row bg-[#00003B0D]/5 border border-[#00003B0D]/5 px-4 py-2 space-x-2">
            <p>https://myaradugbo.com</p>
            <div
              className="text-white text-normal bg-slightRed rounded-full px-4 py-2 cursor-pointer"
              onClick={handleCopy}
            >
              {copied ? "Copied" : "Copy"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
