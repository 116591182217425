import axios from "axios";
import { toast } from "react-toastify";
import {
  deleteAccount,
  getMapEvent,
  getUserDetails,
  googleLogin,
  login,
  loginWithPhone,
  refresh,
  signUp,
  updateLocation,
  updateProfile,
} from "../api";
import { setIsLoading } from "../features/features.action";
import { authTypes } from "./auth.types";
import { jwtDecode } from "jwt-decode";

export const setUserLoading = (payload) => async (dispatch) => {
  dispatch({
    type: authTypes.LOADING,
    payload,
  });
};

export const setSignInUserByUsernameAndEmail =
  (obj, navigate) => async (dispatch) => {
    dispatch({
      type: authTypes.SET_SIGN_IN_USER_BY_USERNAME_AND_EMAIL_START,
    });
    try {
      const { data } = await login(obj);

      if (data) {
        dispatch({
          type: authTypes.SET_SIGN_IN_USER_BY_USERNAME_AND_EMAIL,
          payload: {
            email: obj.email,
            password: obj.password,
            ...data,
          },
        });

        navigate("/dashboard/home");

        let users = [];
        if (JSON.parse(localStorage.getItem("users"))) {
          const item = JSON.parse(localStorage.getItem("users"));
          users = [...item, data];
          for (var i = 0; i < item.length; i++) {
            if (jwtDecode(item[i])?.email === obj.email) {
              break;
            } else {
              localStorage.setItem("users", JSON.stringify(users));
            }
          }
        } else {
          localStorage.setItem("users", JSON.stringify([data]));
        }

        localStorage.setItem("isAuthenticated", true);
      } else {
        return;
      }
    } catch (err) {
      if (err) {
        dispatch({
          type: authTypes.SET_SIGN_IN_USER_BY_USERNAME_AND_EMAIL_FAILED,
        });
        toast.error(err?.response?.data?.message);
        dispatch(setIsLoading(false));
      }
    }
  };

export const setSignUpUserByUsernameAndEmail =
  (obj, handleSignUpStep, navigate) => async (dispatch, getState) => {
    dispatch({
      type: authTypes.SET_SIGN_UP_USER_BY_USERNAME_AND_EMAIL_START,
    });
    try {
      const { data } = await signUp(obj);

      if (data) {
        toast.success(data?.message);
        dispatch({
          type: authTypes.SET_SIGN_UP_USER_BY_USERNAME_AND_EMAIL,
          payload: data,
        });
        setTimeout(() => {
          handleSignUpStep(4);
          dispatch({
            type: authTypes.SET_SIGN_UP_USER_BY_USERNAME_AND_EMAIL,
            payload: null,
          });
        }, 7000);
      }
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({
        type: authTypes.SET_SIGN_UP_USER_BY_USERNAME_AND_EMAIL_FAILED,
      });
    }
  };

export const setSignInByGoogle =
  (obj, navigate) => async (dispatch, getState) => {
    dispatch({
      type: authTypes.START,
    });
    try {
      const { data } = await googleLogin(obj);
      dispatch({
        type: authTypes.SET_SIGN_IN_WITH_GOOGLE,
        payload: data,
      });
      // localStorage.setItem("accessToken", data?.accessToken);
      // localStorage.setItem("refreshToken", data?.refreshToken);
      // localStorage.setItem("isAuthenticated", true);
      if (data) {
        navigate("/dashboard/home");
      }
    } catch (err) {
      console.log(err?.response);
    }
  };

export const setSignInByApple = (obj) => async (dispatch, getState) => {
  dispatch({
    type: authTypes.START,
  });
  try {
    const { data } = await axios.post(
      `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/login/apple-oauth`,
      obj,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: authTypes.SET_SIGN_IN_WITH_APPLE,
      payload: data,
    });
  } catch (err) {
    toast.error("Error occured signing with Apple");
  }
};

export const setSignInByFacebook = (obj) => async (dispatch, getState) => {
  dispatch({
    type: authTypes.START,
  });
  try {
    const { data } = await axios.post(
      `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/login/fb-oauth`,
      obj,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: authTypes.SET_SIGN_IN_WITH_FACEBOOK,
      payload: data,
    });
  } catch (err) {
    toast.error("Error occured signing with Apple");
  }
};

export const setChangePasswordAction = (obj) => async (dispatch, getState) => {
  dispatch({
    type: authTypes.START,
  });
  const token = getState().auth.accessToken;
  try {
    const { data } = await axios.patch(
      "https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/user/changePassword",
      obj,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data) {
      dispatch({
        type: authTypes.UPDATE_ACCESS_TOKEN,
        payload: data.message,
      });
      toast.success("Password changed successfully");
    }
  } catch (err) {
    toast?.error(err?.response?.data.error);
    dispatch({
      type: authTypes.UPDATE_ACCESS_TOKEN_FAILED,
    });
  }
};

export const setForgetPasswordAction =
  (email, handleloginStep) => async (dispatch) => {
    dispatch({
      type: authTypes.RESET_PASSWORD_START,
    });
    try {
      const { data } = await axios.post(
        `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/forgot-password`,
        {
          email: email,
        },
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      );

      if (data.status === 200) {
        dispatch({ type: authTypes.RESET_PASSWORD_SUCCESSFUL });
        // handleloginStep(5);
        toast.success(data?.message);
      } else if (data.status === undefined) {
        dispatch({ type: authTypes.RESET_PASSWORD_FAILED });

        return toast.error(data.message ? data.message : "User not found");
      }
    } catch (err) {
      toast.error("User not found");
      dispatch({ type: authTypes.RESET_PASSWORD_FAILED });
      dispatch(setIsLoading(false));
    }
  };

export const setLoginPhoneNumberAction =
  (obj, setVerifySuccess) => async (dispatch, getState) => {
    dispatch({
      type: authTypes.SET_LOGIN_PHONE_NUMBER_START,
    });
    try {
      const { data } = await loginWithPhone({
        idToken: obj.token,
        code: obj.code,
      });
      if (data) {
        dispatch({
          type: authTypes.SET_LOGIN_PHONE_NUMBER,
          payload: data,
        });
        dispatch(setIsLoading(false));
        setVerifySuccess(true);
      }
    } catch (err) {
      toast.error("Error occured while verifying");
      toast.error(err?.response?.data?.message?.message);
      dispatch({
        type: authTypes.SET_LOGIN_PHONE_NUMBER_FAILED,
      });
    }
  };

export const setSubscribeToComingSoon =
  (email) => async (dispatch, getState) => {
    dispatch({
      type: authTypes.START,
    });
    dispatch(setIsLoading(true));
    try {
      const { data } = await axios.post(
        `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/notification/coming-soon/email`,
        { email }
      );
      if (data) {
        toast.success("Your request is successful");
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      toast.error("Request not completed try again later!!!");
      dispatch(setIsLoading(false));
    }
  };

export const setCheckRegisteredPhoneNumberAction =
  (phone, handleloginStep, navigate) => async (dispatch, getState) => {
    dispatch({
      type: authTypes.START,
    });
    try {
      const { data } = await axios.post(
        `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/check-phone`,
        { phoneNumber: phone },
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      );

      if (data.status === "unregistered") {
        handleloginStep(3);
        dispatch(setIsLoading(false));
      } else {
        navigate("/dashboard/home");
      }
    } catch (err) {
      toast.error("Error occured ");
      dispatch(setIsLoading(false));
    }
  };

export const setCompletePhoneOauthAction =
  (username, navigate) => async (dispatch, getState) => {
    dispatch({
      type: authTypes.START,
    });
    const token = getState().auth.accessToken;
    try {
      const { data } = await axios.post(
        `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/login/complete-phone-oauth`,
        { username },
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data) {
        navigate("/dashboard/home");
        dispatch(setIsLoading(false));
      }
    } catch (err) {
      toast.error("Error occured while creating username!");
      dispatch(setIsLoading(false));
    }
  };

export const getUser = () => async (dispatch) => {
  try {
    dispatch(setUserLoading(true));

    dispatch({
      type: authTypes.GET_USER_DETAIL_START,
      loading: true,
    });
    const response = await getUserDetails();
    dispatch(setUserLoading(false));
    return dispatch({
      type: authTypes.GET_USER_DETAIL,
      payload: response?.data?.credential,
    });
  } catch (err) {
    dispatch(setUserLoading(false));

    dispatch({
      type: authTypes.GET_USER_DETAIL_FAILED,
      // payload: err.response,
    });
  }
};

export const updateUserProfile = (username, payload) => async (dispatch) => {
  try {
    dispatch(setUserLoading(true));

    dispatch({
      type: authTypes.UPDATE_USER_DETAIL_START,
      loading: true,
    });
    const response = await updateProfile(username, payload);
    dispatch(setUserLoading(false));
    console.log(response);
    toast.success("Profile updated Successfully");
    return dispatch({
      type: authTypes.UPDATE_USER_DETAIL,
      payload: response,
    });
  } catch (err) {
    dispatch(setUserLoading(false));
    console.log(err?.response);
    dispatch({
      type: authTypes.UPDATE_USER_DETAIL_FAILED,
      // payload: err.response,
    });
  }
};

export const refreshTokenNow = (token) => async (dispatch) => {
  try {
    const { data } = await refresh({ refreshToken: token });

    if (data) {
      dispatch({
        type: authTypes.REFRESH_USER_TOKEN,
        payload: data?.data,
      });
      // localStorage.setItem("accessToken", data?.data?.token);
      // localStorage.setItem("refreshToken", data?.data?.refreshToken);
      localStorage.setItem("isAuthenticated", true);
    } else {
      // Handle token refresh failure
    }
  } catch (error) {
    dispatch({
      type: authTypes.REFRESH_USER_TOKEN_FAILED,
      loading: error?.response,
    });
    console.error("Token refresh failed with an error:", error.response);
  }
};

export const logOut = () => async (dispatch) => {
  try {
    localStorage.setItem("isAuthenticated", false);
    dispatch({
      type: authTypes.LOGOUT,
    });
  } catch (error) {
    console.error("Error occured", error.response);
  }
};

export const deleteUserAccount = (item, navigate) => async (dispatch) => {
  try {
    const { data } = await deleteAccount(item);
    if (data) {
      localStorage.setItem("isAuthenticated", false);
      dispatch({
        type: authTypes.DELETE_ACCOUNT,
      });

      navigate("/dashboard/home");

      toast.success(data?.message);
    }
  } catch (error) {
    console.error("Error occured", error.response);
  }
};

export const getMapEvents = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: authTypes.GET_MAP_EVENTS_START,
      loading: true,
    });
    const response = await getMapEvent(payload);

    return dispatch({
      type: authTypes.GET_MAP_EVENTS,
      payload: response?.data,
    });
  } catch (err) {
    dispatch({
      type: authTypes.GET_MAP_EVENTS_FAILED,
      payload: err.response,
    });
  }
};

export const updateUserLocation = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: authTypes.UPDATE_USER_LOCATION_START,
      loading: true,
    });
    const response = await updateLocation(payload);
    toast?.success(response?.data?.message);
    return dispatch({
      type: authTypes.UPDATE_USER_LOCATION,
      payload: response?.data,
    });
  } catch (err) {
    dispatch({
      type: authTypes.UPDATE_USER_LOCATION_FAILED,
      payload: err.response,
    });
  }
};

export const setStatus = (status) => async (dispatch) => {
  try {
    dispatch({
      type: authTypes.SET_USER_STATUS,
      payload: status,
    });
  } catch (error) {
    dispatch({
      type: authTypes.SET_USER_STATUS_FAILED,
      payload: "Error occured",
    });
  }
};

export const setModalStatus = (status) => async (dispatch) => {
  try {
    dispatch({
      type: authTypes.SET_UPLOAD_MODAL,
      payload: status,
    });
  } catch (error) {
    dispatch({
      type: authTypes.SET_UPLOAD_MODAL_FAILED,
      payload: "Error occured",
    });
  }
};

export const setAuthLoading = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: authTypes.SET_AUTH_LOADING,
      payload: payload,
    });
  } catch (error) {}
};

export const setSearchBar = (status, screen) => async (dispatch) => {
  try {
    dispatch({
      type: authTypes.SHOW_SEARCH_BAR,
      payload: { showSearchBar: status, screen: screen },
    });
  } catch (error) {}
};
