import React, { useState, useEffect } from "react";
import { livestreamer } from "../../constants/images";
import DashboardLayout from "../DashboardHome/DashboardLayout";
import { BiArrowToLeft } from "react-icons/bi";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import SocketManager from "../../socket/socketManager";
import { formatDate } from "../../utils/utils";
import { BiSolidMessageDots } from "react-icons/bi";
import { BsFillHeartFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getRoomDetails, postMsg } from "../../redux/live/live.action";
import Flvplayer from "./VideoPlayer/FlvPlayer";
import { getUser } from "../../redux/auth/auth.actions";

function Viewstream({ location }) {
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [, setData] = useState([]);
  const [chatMsg, setChat] = useState("");
  const { name } = useParams();
  const [roomInfo, setRoomInfo] = useState();
  const [, setFilteredUser] = useState([]);
  const [, setStart] = useState(false);
  const dispatch = useDispatch();
  const { liveStream } = useSelector((state) => state.livestream);
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.accessToken);

  useEffect(() => {
    if (!token) {
      navigate("/dashboard/home");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getUser());
    // eslint-disable-next-line
  }, []);

  const showSide = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (name) {
      dispatch(getRoomDetails(name));
    }
    // eslint-disable-next-line
  }, [name]);

  const handleChange = (e) => {
    e.preventDefault();
    setChat(e.target.value);
  };

  const sendMessage = () => {
    const payload = {
      roomId: name,
      user: user?.username,
      question: chatMsg,
    };
    dispatch(postMsg(payload));
    SocketManager.instance.emitAskQuestion(payload);
    SocketManager.instance.listenSendMessage(payload);
  };
  useEffect(() => {
    SocketManager.instance.listenAskQuestion((data) => {});
    SocketManager.instance.emitJoinRoom({
      userName: user?.username,
      roomName: "roomName",
      user: user,
      roomId: name,
    });
    SocketManager?.instance?.listenListLiveStream((data) => {
      const filtered = data.filter((item) => {
        return item?.roomId === name;
      });
      setData(filtered);
    });
    SocketManager?.instance?.emitListLiveStream({
      lat: location?.coords?.latitude,
      lon: location?.coords?.longitude,
    });

    SocketManager.instance.listenStreamInfo((data) => {
      setRoomInfo(data);
      const result = roomInfo?.viewers?.filter(
        (item) => item?.username !== user?.username
      );
      setFilteredUser(result);
      setStart(true);
    });
    // eslint-disable-next-line
  }, []);

  // const askQuestion = (question) => {
  //   if (question.length < 2) return;
  //   SocketManager.instance.emitAskQuestion({
  //     roomId: roomInfo.roomId,
  //     user: profile,
  //     question,
  //   });
  //   setComment("");
  //   Keyboard.dismiss();
  // };

  // const live = (type, operation) => {
  //   if (!type || !operation) return;
  //   SocketManager.instance.emitLiveOperation({
  //     roomId: roomInfo.roomId,
  //     type,
  //     operation,
  //   });
  // };

  // const finishLive = async () => {
  //   SocketManager.instance.emitLeaveRoom({
  //     user: profile,
  //     roomId: info.roomId,
  //   });
  //   if (cameraRef.current) cameraRef.current.stop();
  //   navigation.goBack();
  // };

  // if (roomInfo.replay) {
  //   useEffect(() => {
  //     const unsubscribe = navigation.addListener("blur", () => {
  //       ref.current.stopAsync();
  //     });
  //     return unsubscribe;
  //   }, [navigation]);
  // }

  return (
    <DashboardLayout>
      <div className="flex">
        <div
          className={`${show ? "w-[60%]" : "w-full"} relative  h-[100vh]  " : `}
        >
          <div className="pl-[3rem] flex bg-black items-center">
            <NavLink
              to="/dashboard/livestream"
              className="text-white cursor-pointer flex py-[1.5rem] "
            >
              <AiOutlineArrowLeft className="text-xl text-white mr-[1rem] " />{" "}
              Livestreams
            </NavLink>
          </div>
          <div className="w-full h-full bg-black   top-0 bottom-0  backdrop-blur-md ">
            <div className="bg-black h-[300px] ">
              {liveStream && liveStream?.liveStatus === 1 && name ? (
                <Flvplayer
                  url={`https://livestream.myaradugbo.com/live/${name}.flv`}
                  type="flv"
                />
              ) : (
                liveStream &&
                liveStream?.replayLink && (
                  <video controls className="w-full h-[80vh] object-cover">
                    <source
                      src={liveStream && liveStream?.replayLink}
                      type="video/mp4"
                    />
                    {/* <source src="movie.ogg" type="video/ogg" /> */}
                    Your browser does not support the video tag.
                  </video>
                )
              )}
            </div>
          </div>
          <div
            className=" absolute bottom-[2rem]  flex right-[3rem]"
            onClick={() => showSide()}
          >
            <BsFillHeartFill className="text-xl mr-[1rem] text-white cursor-pointer " />
            <BiSolidMessageDots className="text-xl text-white cursor-pointer " />
          </div>
        </div>
        {/* chat */}
        {show && liveStream && (
          <div className="flex flex-col justify-between w-[40%]  h-[100vh] pt-[2rem]">
            <div
              className="flex mb-[2rem] cursor-pointer  items-center align-middle   px-8"
              onClick={() => setShow(!show)}
            >
              <BiArrowToLeft className="text-xl text-primary  " />
              <div className="ml-4">Close chat</div>
            </div>
            {/* profile of user live streaming */}
            <div className="flex justify-between 1 px-8 pt-[1rem] border-t border-[#EAECF0]">
              <div className="flex">
                <div className=" w-[50px] h-[50px] rounded-full ">
                  <img
                    src={liveStream?.host?.imageUrl}
                    alt="user"
                    className="w-full h-full rounded-[100%]"
                  />
                </div>
                <div className="ml-[17px]">
                  <h3 className=" font-medium text-[20px]">
                    {liveStream?.host?.displayName ||
                      liveStream?.host?.username}
                  </h3>
                  <p className="text-[14px] text-[#E4405F]">Streamer</p>
                </div>
              </div>
              <button className="px-[1.5rem] mt-[.5rem] h-[41px] flex justify-center items-center rounded-[29px] border border-[#425466]">
                view profile
              </button>
            </div>
            <div className="pl-5 text-left  mt-0 border-b">
              <p className="text-[1.rem] pl-2 mb-[1rem]">{liveStream?.title}</p>
            </div>
            {/* viewer message */}
            <div className="mt-[30px] w-full h-[350px] overflow-y-scroll">
              {liveStream?.messages?.map((msg, index) => (
                <div className="flex px-[30px] mb-6" key={index}>
                  <div className="mr-2 w-[50px] h-[50px] bg-red-500 rounded-full">
                    <img
                      src={livestreamer}
                      alt="livestream"
                      className="object-cover w-full h-full"
                    />
                  </div>
                  <div>
                    <div className="text-kbody6">
                      <span className="font-semibold text-[#27272E]">
                        {msg?.user?.displayName || msg?.user?.username}
                      </span>
                      <span className="text-[#718096] ml-[5px]">
                        {formatDate(msg?.createdAt)}
                      </span>
                    </div>
                    <div className="text-[#425466]">{msg?.message}</div>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex w-full h-[89px] border-t border-[#425466]  px-[20px] py-[10px]">
              <div className="mr-[13px] w-[50px] h-[50px] rounded-full ">
                <img
                  src={livestreamer}
                  alt="livestream"
                  className="object-cover w-full h-full"
                />
              </div>
              <div className="flex w-[80%] h-[44px] items-center  rounded-[31px] px-[13px]">
                <input
                  className="w-full border-none outline-none bg-transparent"
                  placeholder="Send a message"
                  name="message"
                  disabled
                  onChange={(e) => handleChange(e)}
                />
                <button
                  className="border-0 bg-transparent text-[#E4405F]"
                  onClick={(e) => sendMessage(e)}
                >
                  Post
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
}

export default Viewstream;
