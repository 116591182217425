import React from "react";

function CustomIcon({ icon, style = "" }) {
  return (
    <div className={`bg-lightPink p-5 inline-block rounded-2xl border border-double ring-offset-2 ring-4 border-[#FF010110/32] ring-[#FF010110/32] ${style}`}>
      <img
        src={icon}
        alt="icon"
        className="object-contain w-6 h-6"
      />
    </div>
  );
}

export default CustomIcon;
