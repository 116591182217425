import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { myroutes } from "./constants/data";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <div>
      <div id="recaptcha-container"></div>
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable={false}
        pauseOnVisibilityChange
        closeOnClick
        pauseOnHover
      />
      <Router>
        <Routes>
          {myroutes.map((data, index) => {
            return <Route path={data.path} element={data.route} key={index} />;
          })}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
