import { addDoc, collection } from "firebase/firestore";
import React, { useState, useRef, useEffect } from "react";
import { closeCircleIcon } from "../../constants/images";
import { db } from "../../firebase/config";
import TextButton from "../Button/TextButton";
import { WaitlistModal } from "./WaitlistModal";

export function JoinWaitlistModal({ closeModal }) {
  const [isOpen, setIsOpen] = useState(false);
  let inputRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClick, true);

    return () => {
      document.removeEventListener("mousedown", handleClick, true);
    };
    // eslint-disable-next-line
  }, [modalRef]);

  const handleSubmit = async () => {
    const email = inputRef?.current?.value;

    if (email && email !== "") {
      const validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!email.match(validRegex)) return alert("Invalid Email");

      if (inputRef.current) inputRef.current.value = "";
      setIsOpen(true);

      // Add a new document with a generated id.
      await addDoc(collection(db, "waitlist"), {
        email,
        createdAt: new Date().toISOString(),
      });
    }
  };

  return (
    <div className={`fixed z-50 inset-0 flex items-center justify-center`}>
      {isOpen && (
        <WaitlistModal
          closeModal={() => {
            closeModal();
            setIsOpen(false);
          }}
        />
      )}
      {!isOpen && (
        <div ref={modalRef} className="flex">
          <div className="w-full max-w-md bg-white rounded-lg shadow-md pt-5 pb-9 flex flex-col">
            <div
              className="self-end mr-5 cursor-pointer"
              onClick={() => {
                closeModal();
                setIsOpen(false);
              }}
            >
              <img
                src={closeCircleIcon}
                alt="closeCircleIcon"
                className="object-contain w-6 h-6"
              />
            </div>
            <div className="px-10">
              <div className="self-center flex space-y-2 justify-center flex-col items-center mt-3">
                <p className="text-2xl font-normal font-Eina01-Bold leading-8">
                  ARAD is launching soon 🚀
                </p>
                <p className="text-sm font-normal font-Eina01-Regular text-[#000000] leading-[18px]">
                  Be the first to be notified and earn a VIP Access!
                </p>
              </div>

              <div className="flex flex-col space-y-10 mt-6">
                <div className="flex flex-col space-y-1">
                  <label className="text-sm font-normal font-Eina01-Regular text-[#60646C]">
                    EMAIL ADDRESS
                  </label>
                  <input
                    ref={inputRef}
                    className="py-4 px-3 md:w-96 outline outline-darkGrey outline-1 focus:outline-none border-green focus:border-2 focus:border-slightRed rounded-lg placeholder:text-sm placeholder:font-normal placeholder:font-SF-Pro-Regular"
                    name="email"
                    type="email"
                    placeholder="Enter Email address"
                  />
                </div>
                <TextButton
                  style="self-center"
                  title={"Get early access"}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
