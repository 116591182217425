import { applyMiddleware, compose } from "redux";
import { legacy_createStore as createStore } from "redux";

import thunk from "redux-thunk";
import { persistStore } from "redux-persist";
import persistedReducer from "./rootReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [thunk];
export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);
