import { authTypes } from "./auth.types";

const initialState = {
  accessToken: "",
  refreshToken: "",
  phone: "",
  error: "",
  notifications: [],
  likes: [],
  mapEvents: [],
  userInfo: {
    name: "",
    email: "",
    password: "",
  },
  isDeleted: false,
  user: {},
  signUp: false,
  loading: false,
  phoneLoading: false,
  emailLoading: false,
  emailUpLoading: false,
  uploadLocationLoading: false,
  uploadMessage: "",
  mapLoading: false,
  showUploadModal: 0,
  isLoading: false,
  leftSideBar: { showSearchBar: false, screen: null },
  resetPasswordLoading: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.START:
      return {
        ...state,
        loading: false,
      };
    case authTypes.SET_SIGN_IN_USER_BY_USERNAME_AND_EMAIL_START:
      return {
        ...state,
        emailLoading: true,
      };
    case authTypes.SET_SIGN_IN_USER_BY_USERNAME_AND_EMAIL:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshtoken,
        emailLoading: false,

        userInfo: {
          name: "",
          email: action.payload.email,
          password: action.payload.password,
        },
      };
    case authTypes.SET_SIGN_IN_USER_BY_USERNAME_AND_EMAIL_FAILED:
      return {
        ...state,
        emailLoading: false,
      };
    case authTypes.SET_SIGN_UP_USER_BY_USERNAME_AND_EMAIL_START:
      return {
        ...state,
        emailUpLoading: true,
      };
    case authTypes.SET_SIGN_UP_USER_BY_USERNAME_AND_EMAIL:
      return {
        ...state,
        signUp: action.payload,
        emailUpLoading: false,
      };
    case authTypes.SET_SIGN_UP_USER_BY_USERNAME_AND_EMAIL_FAILED:
      return {
        ...state,
        emailUpLoading: false,
      };
    case authTypes.SET_USER_STATUS:
      return {
        ...state,
        isDeleted: action.payload,
      };
    case authTypes.SET_AUTH_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case authTypes.SET_UPLOAD_MODAL:
      return {
        ...state,
        showUploadModal: action.payload,
      };
    case authTypes.SET_SIGN_IN_WITH_GOOGLE:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        loading: false,
      };
    case authTypes.SET_LOGIN_PHONE_NUMBER_START:
      return {
        ...state,

        phoneLoading: true,
      };
    case authTypes.SET_LOGIN_PHONE_NUMBER:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        phone: action.payload.phone,
        phoneLoading: false,
      };
    case authTypes.SET_LOGIN_PHONE_NUMBER_FAILED:
      return {
        ...state,

        phoneLoading: false,
      };
    case authTypes.SET_SIGN_IN_WITH_APPLE:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        loading: false,
      };
    case authTypes.SWITCH_ACCOUNT:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        loading: false,
      };
    case authTypes.SET_SIGN_IN_WITH_FACEBOOK:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        loading: false,
        refreshToken: action.payload.refreshToken,
      };
    case authTypes.UPDATE_ACCESS_TOKEN:
      return {
        ...state,
        loading: false,
        accessToken: action.payload,
      };
    case authTypes.UPDATE_ACCESS_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
      };
    case authTypes.GET_USER_DETAIL_START:
      return {
        ...state,
        loading: true,
      };
    case authTypes.GET_USER_DETAIL:
      return {
        ...state,
        loading: false,

        user: action.payload,
      };
    case authTypes.GET_USER_DETAIL_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case authTypes.GET_MAP_EVENTS_START:
      return {
        ...state,
        loading: true,
      };
    case authTypes.GET_MAP_EVENTS:
      return {
        ...state,
        loading: false,

        mapEvents: action.payload,
      };
    case authTypes.GET_MAP_EVENTS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case authTypes.REFRESH_USER_TOKEN_START:
      return {
        ...state,
        loading: true,
      };
    case authTypes.REFRESH_USER_TOKEN:
      return {
        ...state,
        loading: false,
        accessToken: action.payload.token,
        refreshToken: action.payload.refreshToken,
      };
    case authTypes.REFRESH_USER_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    case authTypes.LOGOUT:
      return {
        ...state,
        loading: false,
        accessToken: "",
        refreshToken: "",
        userInfo: {},
        user: {},
      };
    case authTypes.DELETE_ACCOUNT:
      return {
        ...state,
        loading: false,
        accessToken: "",
        refreshToken: "",
        userInfo: {},
      };
    case authTypes.UPDATE_USER_LOCATION_START:
      return {
        ...state,
        uploadLocationLoading: true,
      };
    case authTypes.UPDATE_USER_LOCATION:
      return {
        ...state,
        uploadLocationLoading: false,
        uploadMessage: action.payload?.message,
      };
    case authTypes.UPDATE_USER_LOCATION_FAILED:
      return {
        ...state,
        uploadLocationLoading: false,
      };
    case authTypes.SHOW_SEARCH_BAR:
      return {
        ...state,
        leftSideBar: action?.payload,
      };
    case authTypes.RESET_PASSWORD_START:
      return {
        ...state,
        resetPasswordLoading: true,
      };
    case authTypes.RESET_PASSWORD_SUCCESSFUL:
      return {
        ...state,
        resetPasswordLoading: false,
      };
    case authTypes.RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPasswordLoading: false,
      };
    default:
      return state;
  }
};
