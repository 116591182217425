import React from "react";
function Button({ img, store }) {
  return (
    <div
      className={`btn_container flex justify-center items-center w-[150px] cursor-pointer  sm:w-[170px] h-[54px] mr-3 sm:mr-5 bg-kdark2 rounded-full`}
    >
      <img className="max-w-[26px] mr-3" src={img} alt="mobile_store_logo" />
      <div className="text-left leading-4">
        <p className="text-[.7rem]  md:text-[.9rem] font-extralight  sm:">
          Get it on
        </p>
        <h4 className="text-[.8rem] md:text-[1rem] -mt-[.rem] ">{store}</h4>
      </div>
    </div>
  );
}

export default Button;
