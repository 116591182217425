import React, { useState, useEffect, useRef } from "react";
import TextButton from "../../components/Button/TextButton";
import {
  phone1,
  phone2,
  phone3,
  magicWandIcon,
  macWeb,
} from "../../constants/images";
import GeneralHeader from "../../components/GeneralHeader/GeneralHeader";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import { productFeatures } from "../../constants/data";
import ProductFeature from "../../components/ProductFeature/ProductFeature";
import GeneralFooter from "../../components/GeneralFooter/GeneralFooter";
import introVideo from "../../assets/arad_intro.mp4";
import { JoinWaitlistModal } from "../../components/Modal/JoinWaitlistModal";

const Home = () => {
  const [selectedFeature, setSelectedFeature] = useState(productFeatures[0]);
  const [headers] = useState([
    "Tune into your neighborhood",
    "Amplify your awareness",
    "Build a more connected community",
  ]);
  const [subtitle] = useState([
    "ARAD allows you to stay informed, engaged, and connected with what's happening in your neighborhood in real-time.",
    "ARAD keeps you aware of your immediate environment by providing real-time location-based updates and insights.",
    "With ARAD, you and everyone within your community can actively contribute to community growth, creating a community with stronger bonds.",
  ]);
  const [showHeader, setShowHeader] = useState(true);
  const [hasSeenPopup, setHasSeenPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentHeaderIndex, setCurrentHeaderIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowHeader(false);
      setTimeout(() => {
        setCurrentHeaderIndex((prevIndex) => (prevIndex + 1) % headers.length);
        setShowHeader(true);
      }, 500); // Delay before changing header
    }, 5000); // Change header every 5 seconds

    return () => clearInterval(interval);
  }, [headers]);

  useEffect(() => {
     const timer = setTimeout(() => {
      if(!hasSeenPopup) setIsOpen(true);
   }, 30000);
  return () => clearTimeout(timer);
  }, []);

  const handleFeatureChange = (feature) => {
    setSelectedFeature(feature);
  };

  const scrollToInput = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const footerRef = useRef(null);

  const handleScroll = () => {
    if (footerRef?.current) {
      const footerTop = footerRef?.current?.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (footerTop <= windowHeight && !hasSeenPopup) {
        setIsOpen(true);
        setHasSeenPopup(true);
      }
    }
  };

  useEffect(() => {
    if (!isOpen) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isOpen]);

  return (
    <div>
      <section className="relative h-screen">
        <div className="absolute inset-x-0 z-10">
          <GeneralHeader />
          {isOpen &&
            <JoinWaitlistModal 
              closeModal={() => {
                setIsOpen(false);
                setHasSeenPopup(true);
              }}
            />
          }
          <div className="flex flex-col justify-center items-center">
            <div className="pt-40">
              <p className="text-center font-SF-Pro-Regular text-[#F2F2F5] border border-[#7E808A] bg-gradient-to-r from-[#0000301B]/10.6 to-[#7E808A] rounded-full px-4 py-3 backdrop-blur">
                We are launching soon - join our waitlist
              </p>
            </div>

            <div className="text-white text-center md:text-6xl text-4xl	font-Eina01-Bold font-normal mt-7">
              <p
                className={`font-Eina01-Bold transition-opacity duration-500 ${
                  showHeader ? "opacity-100" : "opacity-0"
                }`}
              >
                {headers[currentHeaderIndex]
                  .split(" ", headers.length + 1)
                  .slice(0, 3)
                  .join(" ")}
                <br />
                {headers[currentHeaderIndex]
                  .split(" ", headers.length + 2)
                  .slice(3, 6)
                  .join(" ")}
              </p>
            </div>

            <div className="font-normal	text-base text-[#EBEBEF] text-center md:w-1/3 w-2/3 mt-6 mb-10 font-SF-Pro-Regular">
              <p
                className={`font-Eina01-Regular transition-opacity duration-500 ${
                  showHeader ? "opacity-100" : "opacity-0"
                }`}
              >
                {subtitle[currentHeaderIndex]}
              </p>
            </div>
             <TextButton
                title={"Join our waitlist"}
                onClick={() => {
                  setIsOpen(true);
                }}
              />
          </div>
        </div>
        <video
          className="w-screen h-screen object-cover"
          controls={false}
          autoPlay
          muted
          loop
        >
          <source src={introVideo} type="video/mp4" />
        </video>
      </section>

      <section className="py-12 md:py-[72px] px-4 md:px-32">
        <div className="space-y-4">
          <p className="font-normal	text-3xl md:text-5xl leading-10 font-Eina01-Bold w-full md:w-9/12">
            Discover, share, and experience your community in the moment.
          </p>
          <p className="font-Eina01-Regular text-sm	md:text-base leading-5 md:leading-6 w-full md:w-9/12">
            Arad app is your go-to platform for a more connected community. With
            Arad, your community can be more secure, connected and alive. Create
            a community with stronger bonds.
          </p>
          <TextButton title={"Join our waitlist"} onClick={scrollToInput} />
        </div>

        <div className="flex bg-white flex-col md:flex-row justify-between item-center pt-20 md:space-y-0 space-y-[10px] md:space-x-[10px]">
          <div className="bg-lightPink flex justify-center">
            <img
              src={phone1}
              alt="phone1"
              className="object-contain pt-16 px-11 md:px-24 w-full"
            />
          </div>
          <div className="bg-lightPink flex justify-center">
            <img
              src={phone2}
              alt="phone2"
              className="object-contain pb-16 px-11 md:px-24 w-full"
            />
          </div>
          <div className="bg-lightPink flex justify-center">
            <img
              src={phone3}
              alt="phone3"
              className="object-contain pt-16 px-11 md:px-24 w-full"
            />
          </div>
        </div>
      </section>

      <section className="py-12 md:py-[72px] px-4 md:px-32">
        <div className="space-y-4">
          <CustomIcon icon={magicWandIcon} />
          <p className="font-normal	text-3xl md:text-5xl leading-10 font-Eina01-Bold">
            Amazing features
          </p>
          <p className="text-sm font-Eina01-Regular	md:text-base leading-5 md:leading-6 w-9/12 md:w-5/12">
            Features that allows you to share real-time updates, get instant
            alerts, as well as connect effortlessly with your family, friends
            and everyone in your community.
          </p>
          <TextButton title={"Join our waitlist"} onClick={scrollToInput} />
        </div>

        <div className="flex flex-col-reverse	wdx:flex-row justify-between items-center pt-40">
          <div className="flex flex-col space-y-14 flex-1">
            {productFeatures.map((feature, index) => (
              <ProductFeature
                key={index}
                title={feature.title}
                active={selectedFeature.title === feature.title}
                onClick={() => handleFeatureChange(feature)}
                description={feature.description}
                icon={feature.icon}
                features={feature.features}
              />
            ))}
          </div>

          <div className="sm:max-w-[380px]">
            <img
              src={selectedFeature.image}
              alt="flatPhone"
              className="object-contain"
            />
          </div>
        </div>
      </section>

      <section className="py-12 md:py-[72px] px-4 md:px-32">
        <div className="space-y-4">
          <p className="font-normal	text-3xl md:text-5xl leading-10 font-Eina01-Bold w-full md:w-9/12">
            We are on the web too
          </p>
          <p className="text-sm	md:text-base font-Eina01-Regular leading-5 md:leading-6 w-full md:w-9/12">
            With Arad, everyone can help keep each other safe, improve the
            community, and make strong bonds. Join in and be part of building a
            better neighborhood.
          </p>
          <TextButton
            title={"Learn More"}
            onClick={() => {
              console.log("Pressed");
            }}
          />
        </div>

        <div className="flex bg-white pt-11 md:pt-[75px]" ref={footerRef}>
          <div className="rounded-[20px] border-8	 border-solid border-slate-900/5 shadow-sm shadow-slate-950/10">
            <img
              src={macWeb}
              alt="macMobile"
              className="object-contain w-full h-full"
            />
          </div>
        </div>
      </section>

      <GeneralFooter />
    </div>
  );
};

export default Home;
