import classNames from "classnames";
const containerStyle = classNames({
  "max-w-[1240px]": true,

  "m-auto": true,
  "mt-[18px]": true,
  "px-4": true,
  "md:px-[50px]": true,
  "text-white": true,
});
const button = {
  "bg-blue-500": true,
  "text-white": true,
  "p-2": true,
  "rounded-md": true,
  "hover:bg-blue-600": true,
};

// const containerStyle = classNames(container);
export { containerStyle, button };
