import { signInWithPopup } from "firebase/auth";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  apple_circle_icon,
  facebook_icon,
  user_icon,
} from "../../constants/images";
import { Appleprovider, auth, Facebookprovider } from "../../firebase/utils";
import {
  FacebookAuthProvider,
  OAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import {
  setSignInByApple,
  setSignInByFacebook,
  setSignInByGoogle,
  setAuthLoading,
} from "../../redux/auth/auth.actions";
import Verification from "../Verification/Verification";
import WithEmail from "../Login/LoginComponent/WithEmail/WithEmail";
import WithUserName from "./withUserName/WithUserName";
import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import SignUpEmail from "./withEmail/SignUpEmail";
import { HiX } from "react-icons/hi";
import { IoChevronBack } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import Loader from "../Loader";
import { toast } from "react-toastify";

function SignUp() {
  const [signUpStep, setSignUpStep] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [, setVerificationToken] = useState("");
  const [disable, setDisable] = useState(true);
  const [, setLoginStep] = useState(0);

  const handleroute = () => {
    navigate("/login");
  };

  const handleSignUpStep = (id) => {
    setSignUpStep(id);
  };
  const goHome = () => {
    navigate("/");
  };

  const goBack = () => {
    setSignUpStep(0);
  };

  const loginWithApple = () => {
    signInWithPopup(auth, Appleprovider)
      .then((result) => {
        // The signed-in user info.

        // Apple credential
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
        dispatch(setSignInByApple({ token: accessToken, secret: idToken }));
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
      });
  };

  const loginFacebook = () => {
    signInWithPopup(auth, Facebookprovider)
      .then((result) => {
        // The signed-in user info.

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        dispatch(setSignInByFacebook({ idToken: accessToken }));

        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {});
  };

  const handleChange = (e) => {
    setPhoneNumber(e);
    if (!e.match("[0-9]{13}")) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": (error) => {},
        },
        auth
      );
    }
  }

  const handlePhoneLogin = (e) => {
    e.preventDefault();
    onSignup();
  };

  function onSignup() {
    dispatch(setAuthLoading(true));
    onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;
    const formatPh = "+" + phoneNumber;
    if (appVerifier) {
      signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setVerificationToken(confirmationResult.verificationId);
          dispatch(setAuthLoading(false));

          setLoginStep(10);
        })
        .catch((error) => {
          console.log(error?.response);
          toast.error("Error occured");
          dispatch(setAuthLoading(false));
        });
    }
  }

  return (
    <div className="w-[438px]  flex flex-col justify-center sm:w-[500px] px-[50px]">
      <div className=" flex justify-between items-center mb-[1.5rem]">
        <div
          className={`${
            signUpStep === 0 ? "bg-white text-white" : ""
          }  w-[30px] mt-[.7rem] flex align-middle text-[#344054] items-center h-[30px] rounded-[50%] bg-[#F2F4F7] text-center cursor-pointer`}
          onClick={goBack}
        >
          <IoChevronBack
            size={18}
            color=""
            className={`${
              signUpStep === 0 ? "text-white" : ""
            } mx-auto  text-center`}
          />
        </div>
        <h4 className=" text-center text-kdark3 text-[24px]">
          {signUpStep === 4 ? "Login in to Aradugbo" : "Sign up for Aradugbo"}
        </h4>

        <div
          className="mt-[.7rem] w-[30px] flex align-middle items-center h-[30px] rounded-[50%] bg-[#F2F4F7] text-center cursor-pointer"
          onClick={goHome}
        >
          <HiX
            size={18}
            color=""
            className="text-[#344054] mx-auto  text-center"
          />
        </div>
      </div>
      <form className="w-[400px]" onSubmit={handlePhoneLogin}>
        <p className="text-[.8rem] text-[#718096]">Phone</p>
        <PhoneInput
          className="w-full  py-[rem] mt-3"
          country={"ng"}
          label="Phone"
          value={phoneNumber}
          inputProps={{
            name: "phone",
            required: true,
            autoFocus: true,
          }}
          onChange={handleChange}
        />
        <div
          className={`flex justify-center items-center w-full h-[48px] mt-[21px] rounded-[5px]   ${
            disable ? "bg-gray-200 text-[#525050]" : "bg-[#F04438] text-white"
          }`}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <button
              type="submit"
              disabled={disable}
              className="w-full h-full border-none outline-none"
            >
              Continue
            </button>
          )}
        </div>
      </form>

      <div className="flex my-[22px] justify-center text-[16px]">OR</div>

      {signUpStep === 0 && (
        <div className=" w-[400px]">
          <div className="w-full flex items-center cursor-pointer mb-4 h-[54px] px-5 border border-[#02023414] bg-[#05055806] rounded-[5px]">
            <div>
              <img src={user_icon} alt="icon" />
            </div>
            <p
              onClick={() => handleSignUpStep(1)}
              className="w-full flex justify-center text-[#718096]"
            >
              Use email / username
            </p>
          </div>
          {/* facebook */}
          <div
            onClick={loginFacebook}
            className="w-full flex items-center  mb-4 h-[54px] px-5 border cursor-pointer border-[#02023414] bg-[#05055806] rounded-[5px]"
          >
            <div>
              <img src={facebook_icon} alt="icon" />
            </div>
            <p className="w-full text-[#718096] flex justify-center">
              Continue with Facebook
            </p>
          </div>

          {/* Apple */}
          <div
            onClick={loginWithApple}
            className="w-full mt-[.7rem] flex items-center  mb-4 h-[54px] px-5 border cursor-pointer border-[#02023414] bg-[#05055806] rounded-[5px]"
          >
            <div>
              <img src={apple_circle_icon} alt="icon" />
            </div>
            <p className="w-full text-[#718096] flex justify-center">
              Continue with Apple
            </p>
          </div>
          {/* Google */}
          <div className="mb-4 text-[1rem] mx-auto w-[100%] flex justify-center ">
            <GoogleLogin
              className="w-[100%] border-[#02023414] bg-[#05055806] rounded-[5px]"
              width="400px"
              onSuccess={(credentialResponse) => {
                dispatch(
                  setSignInByGoogle(
                    {
                      idToken: credentialResponse.credential,
                    },
                    navigate
                  )
                );
              }}
              onError={() => {}}
            />
          </div>
        </div>
      )}
      {signUpStep === 1 && <SignUpEmail handleSignUpStep={handleSignUpStep} />}
      {signUpStep === 10 && (
        <Verification handleSignUpStep={handleSignUpStep} signup={true} />
      )}
      {signUpStep === 3 && <WithUserName />}
      {signUpStep === 4 && <WithEmail />}
      {signUpStep !== 3 && (
        <p className="text-kbody6 text-center mt-[1rem]  text-[#718096]">
          By continuing, you agree to Aradugbo’s{" "}
          <a href="/legal/privacy-policy" className="text-[#27272E]">
            Terms of Service
          </a>{" "}
          and confirm that you have read the{" "}
          <a href="/legal/privacy-policy">Privacy Policy</a> .
        </p>
      )}
      <div
        className="flex text-[#718096]  absolute bottom-[2rem] right-auto left-auto justify-center mt-[5px] cursor-pointer text-[16px]"
        onClick={handleroute}
      >
        <p className="text-left ml-[5rem] w-full">
          {" "}
          Have an account? <span className="text-[#F04438]"> Sign in</span>
        </p>
      </div>
    </div>
  );
}

export default SignUp;
