import React from "react";
import Live from "../../assets/images/live.png";
import { BsDot } from "react-icons/bs";
import { RiWirelessChargingFill } from "react-icons/ri";
import { FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import moment from "moment";

function LivestreamCard({ stream }) {
  const navigate = useNavigate();

  const view = (roomId) => {
    navigate(`/dashboard/livestream/stream/${roomId}`);
  };

  return (
    <div
      className="w-full md:w-[32%] relative cursor-pointer"
      onClick={() => view(stream?.roomId)}
    >
      <span className="bg-red-500 flex items-center text-white absolute right-4 text-[.8rem] top-4 px-[1rem] rounded-[15px]">
        {stream?.liveStatus === 1 ? (
          <RiWirelessChargingFill className="mr-2" />
        ) : (
          <FiEye className="mr-2" />
        )}
        {stream?.liveStatus === 1 ? "Live" : `${stream?.count?.length} View`}
      </span>
      <div className="w-full ">
        <img src={Live} alt="livestream" />
      </div>
      <div className="flex mt-4  ">
        <img
          src={stream?.host?.imageUrl}
          alt="User"
          className="w-[30px] h-[30px] rounded-full object-cover"
        />
        <div className="ml-3">
          <div className="flex mb-1 items-center">
            <p className="text-[.7rem] mr-1  text-[#718096]">
              {stream?.host?.displayName || stream?.host?.username}
            </p>

            <BsDot className="text-red-600 w-4" />
            <span className="text-[.7rem] text-[#718096]">
              Streamed {moment(stream?.timestamp).format("HH:mm")}
            </span>
          </div>
          <h5 className="-mt-[.4rem]">{stream?.title}</h5>
        </div>
      </div>
    </div>
  );
}

export default LivestreamCard;
