import { postsType } from "./posts.type";
import axios from "axios";
import {
  getNotificationsCount,
  getUserFlaggedPosts,
  getUserPosts,
  likeEventById,
  reportPost,
  userUploadPost,
} from "../api";

export const uploadPostByUser = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: postsType.UPLOAD_POST_START,
    });

    const { data } = await userUploadPost(payload);
    if (data) {
      dispatch({
        type: postsType.UPLOAD_POST,
        payload: data,
      });
    }
  } catch (err) {
    dispatch({
      type: postsType.UPLOAD_POST_ERROR,
    });
  }
};

export const fetchAllPosts = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: postsType.FETCH_POSTS,
    });

    const { data } = await getUserPosts();
    if (data) {
      dispatch({
        type: postsType.SET_POSTS,
        payload: data.articles,
      });
    }
  } catch (err) {
    dispatch({
      type: postsType.FETCH_POSTS_ERROR,
    });
  }
};

export const fetchFlaggedPosts = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: postsType.FETCH_FLAGGED_POSTS_START,
    });

    const { data } = await getUserFlaggedPosts();
    if (data) {
      dispatch({
        type: postsType.FETCH_FLAGGED_POSTS_SUCCESSFUL,
        payload: data?.flags,
      });
    }
  } catch (err) {
    dispatch({
      type: postsType.FETCH_FLAGGED_POSTS_FAILED,
    });
  }
};

export const fetchSinglePost = (post) => async (dispatch, getState) => {
  try {
    dispatch({
      type: postsType.FETCH_POST,
    });

    const { data } = await axios.get(
      `https://newsapi.org/v2/top-headlines?sources=techcrunch&apiKey=3cdfdf99b2164df4b066ba9287ad951e`
    );
    const getSinglePost = data.articles.filter(
      (article) => article.title === post
    );
    if (getSinglePost) {
      dispatch({
        type: postsType.SET_POST,
        payload: getSinglePost,
      });
    }
  } catch (err) {
    dispatch({
      type: postsType.FETCH_POST_ERROR,
    });
  }
};

export const likeEvent = (id, payload) => async (dispatch, getState) => {
  try {
    dispatch({
      type: postsType.LIKE_POST_START,
    });

    const { data } = await likeEventById(id, payload);
    if (data) {
      dispatch({
        type: postsType.LIKE_POST,
        payload: data,
      });
    }
    return data;
  } catch (err) {
    dispatch({
      type: postsType.LIKE_POST_FAILED,
    });
  }
};

export const fetchAllNotification = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: postsType.FETCH_NOTIFICATON_COUNT_START,
    });

    const { data } = await getNotificationsCount();
    if (data) {
      dispatch({
        type: postsType.FETCH_NOTIFICATON_COUNT,
        payload: data,
      });
    }
  } catch (err) {
    dispatch({
      type: postsType.FETCH_NOTIFICATON_COUNT_ERROR,
    });
  }
};

export const getLikeCounts = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: postsType.GET_LIKES_COUNTS,
      payload,
    });
  } catch {}
};

export const togglePostComment = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: postsType.SWITCH_POST_COMMENT_START,
    });
    if (payload) {
      dispatch({
        type: postsType.SWITCH_POST_COMMENT,
        payload,
      });
    }
  } catch {}
};

export const setCategoryType = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: postsType.SET_CATEGORY_TYPE_START,
    });
    if (payload) {
      dispatch({
        type: postsType.SET_CATEGORY_TYPE_SUCCESSFUL,
        payload,
      });
    }
  } catch {}
};

export const getPostCommentStatus = () => async (dispatch) => {
  try {
    dispatch({
      type: postsType.GET_POST_COMMENT_STATUS_START,
    });

    dispatch({
      type: postsType.GET_POST_COMMENT_STATUS,
    });
  } catch {}
};

export const reportUserPost = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: postsType.REPORT_POST_START,
    });

    const { data } = await reportPost(payload);
    if (data) {
      dispatch({
        type: postsType.REPORT_POST,
        payload: data,
      });
    }
  } catch (err) {
    dispatch({
      type: postsType.REPORT_POST_ERROR,
    });
  }
};

export const viewSinglePost = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: postsType.VIEW_SINGLE_POST,
      payload,
    });
  } catch {}
};

export const closeSinglePost = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: postsType.CLOSE_SINGLE_POST,
      payload,
    });
  } catch {}
};
