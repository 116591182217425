import React from "react";
import CustomIcon from "../CustomIcon/CustomIcon";

function ProductFeature({ title, description, icon, active, features, onClick }) {
  return (
    <div className={`flex space-y-4 md:space-y-0 md:space-x-8 flex-col md:flex-row cursor-pointer opacity-100 ${!active && "md:opacity-50"}`} onClick={onClick}>
      <div>
        <CustomIcon icon={icon} />
      </div>
      <div className="flex flex-col">
        <p className="mb-2 font-normal text-xl font-Eina01-Semibold text-[#27272E]">
          {title}
        </p>
        {description && <p className="font-Eina01-Regular text-[#425466] font-normal text-base w-full md:w-7/12">
          {description}
        </p>}

        <div className="flex flex-col">
          {
            features.map((feature, index) => (
              <div key={index} className="flex flex-row space-x-2 space-y-1 items-center">
                <div className="rounded-full w-[15px] h-[15px] border-4 border-[#FCBE5A]"></div>
                <p className="text-[#425466] font-normal leading-6 font-Eina01-Regular text-base">
                  {feature}
                </p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default ProductFeature;
