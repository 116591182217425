import React, { useState } from "react";
import { useSelector } from "react-redux";
import InputField from "../../InputField";
import Loader from "../../Loader";

function NewPassword() {
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const handleConfirmPassword = async (e) => {
    e.preventDefault();
    // setLoading(true);
    // try {
    //   const { data } = await fetchUnAuth(
    //     "post",
    //     "auth/password-reset",
    //     {},
    //     { code: oobCode, password }
    //   );
    //   if (data) {
    //     setError("");
    //     setLoading(false);
    //     navigation.navigate("PasswordResetSuccess", { mail: data.email });
    //   }
    // } catch (error) {
    //   setLoading(false);
    //   console.log("error", error.data);
    //   setError(error.data.message);
    //   Sentry.Native.captureException(error);
    // }
  };

  return (
    <form className="w-full" onSubmit={handleConfirmPassword}>
      <div>
        <InputField
          label={"New Password"}
          name="newPassword"
          value={newPassword}
          placeholder="Enter New Password"
          changeValue={(e) => setNewPassword(e.target.value)}
        />
      </div>
      <div>
        <InputField
          label={"Confirm Password"}
          name="confirmPassword"
          value={confirmPassword}
          placeholder="Confirm Password"
          changeValue={(e) => setConfirmPassword(e.target.value)}
        />
      </div>
      <div className="flex justify-center items-center w-full h-[48px] mt-[21px] rounded-[5px] text-white bg-[#F04438]">
        {isLoading ? (
          <Loader />
        ) : (
          <button
            type="submit"
            className="w-full h-full cursor-pointer border-none outline-none"
          >
            Continue
          </button>
        )}
      </div>
    </form>
  );
}

export default NewPassword;
