import axios from "axios";
import { setIsLoading } from "../features/features.action";
import { peoplePostTypes } from "./peoplepost.types";

export const getAllUserPosts = () => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));
    const { data } = await axios.get(
      `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/events`
    );

    if (data.length > 0) {
      dispatch(setIsLoading(false));
      dispatch({
        type: peoplePostTypes.FETCH_PEOPLE_POSTS,
        payload: data,
      });
    } else {
      dispatch({
        type: peoplePostTypes.FETCH_PEOPLE_POSTS_ERROR,
        payload: "No Data found",
      });
    }
  } catch (err) {
    dispatch({
      type: peoplePostTypes.FETCH_PEOPLE_POSTS_ERROR,
      payload: "error while fetching data",
    });
  }
};

export const getPost = (id) => async (dispatch, getState) => {
  try {
    console.log(id);
    dispatch({
      type: peoplePostTypes.FETCH_PEOPLE_POST_START,
    });
    const { data } = await axios.get(
      `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/events/${id}`
    );

    if (data) {
      dispatch({
        type: peoplePostTypes.FETCH_PEOPLE_POST,
        payload: data,
      });
    }
  } catch (err) {
    dispatch({
      type: peoplePostTypes.FETCH_PEOPLE_POST_ERROR,
    });
  }
};

export const setEventCommentAction =
  (message, eventId) => async (dispatch, getState) => {
    const token = getState().auth.accessToken;
    try {
      dispatch({
        type: peoplePostTypes.POST_COMMENT_START,
      });
      const { data } = await axios.post(
        `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/events/${eventId}/comment`,
        {
          text: message,
        },
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data) {
        dispatch({
          type: peoplePostTypes.POST_COMMENT,
          payload: data.text,
        });
      }
    } catch (err) {
      dispatch({
        type: peoplePostTypes.POST_COMMENT_FAILED,
      });
    }
  };
