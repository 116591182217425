import { useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import SkeletonLoader from "../Skeleton/PostLoader";
import dayjs from "dayjs";
import UserPostShare from "../SingleUserPost/UserPostShare/UserPostShare";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import { useLocation } from "react-router-dom";
import { getDistanceFromLatLonInKm } from "../../utils/utils";

function DashboardSide({
  events,
  callEvents,
  handleSinglePost,
  token,
  user,
  openReportModal,
  updateCounts,
  isOpen,
  onClose,
}) {
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const loaderRef = useRef(null);
  const router = useLocation();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        callEvents(true);
      }
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        // eslint-disable-next-line
        observer.unobserve(loaderRef.current);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <InfiniteScroll
      dataLength={40}
      next={() => callEvents(true)}
      hasMore={true} // Replace with a condition based on your data source
      scrollableTarget="scrollableDiv"
      scrollThreshold={1}
      key={1}
      loader={
        <div className="w-full px-3 justify-center">
          <SkeletonLoader />
        </div>
      }
      useWindow={true}
      // eslint-disable-next-line
      endMessage={<p key={1}>No more data to load.</p>}
    >
      {/* // eslint-disable-next-line */}

      <div
        className={`${
          darktheme ? "bg-kdark3 text-[#5c5c5e]" : "bg-[#fff]  text-[#1D2939]"
        }   rounded-[5px] `}
      >
        {events &&
          events?.map((post, index) => {
            return (
              <>
                <div className={`w-full  `} key={index}>
                  <div
                    className={`w-full h-full py-[23px]  ${
                      router?.pathname === "/dashboard/explore"
                        ? " border-b-[1px]"
                        : "border-b-[5px]"
                    } ${darktheme ? "border-[#323232]" : "border-[#EAECF0]"}
                        ?`}
                  >
                    <div className=" px-4">
                      <div className="flex  w-full">
                        <img
                          alt="user"
                          className="w-[40px] h-[40px] rounded-full"
                          src={post?.userImage}
                        />

                        <div className="ml-[10px] w-full ">
                          <div className="flex items-center justify-between w-full text-[13px]">
                            <div className="flex items-center">
                              <p className=" mr-[10px] text-[#667085]">
                                {post?.username?.length > 10 ? (
                                  <>
                                    {`${post?.username.substring(0, 10)}...`}{" "}
                                  </>
                                ) : (
                                  post?.username
                                )}{" "}
                              </p>
                              <div className="w-[5px] h-[5px] bg-[#EAECF0] rounded-full"></div>
                              <div className=" m-[10px] flex text-[#667085]">
                                {dayjs(post?.createdAt).fromNow()}
                              </div>
                            </div>
                            {token ? (
                              <div className="text-[#FC5C65] bg-[#FC5C651A] rounded-[15px] px-[.5rem] py-[.2rem] text-[12px] font-semibold ml-[6px]">
                                {getDistanceFromLatLonInKm(
                                  user?.coords?.latitude,
                                  user?.coords?.longitude,
                                  post?.coords?.latitude,
                                  post?.coords?.longitude
                                )}
                                km
                              </div>
                            ) : (
                              " "
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={() => handleSinglePost(post)}
                        className="mt-[.5rem] cursor-pointer  font-bold text-[15px]"
                      >
                        {post?.title}
                      </div>
                    </div>

                    <Carousel
                      className=" "
                      autoPlay={false}
                      infiniteLoop={true}
                      showStatus={false}
                      showThumbs={false}
                      // centerMode={true}
                      centerSlidePercentage={100}
                      showArrows={false}
                      // interval={5000}
                    >
                      {post?.media?.videos?.length > 0 ? (
                        <div
                          onClick={() => handleSinglePost(post)}
                          className={`mt-[15px] cursor-pointer  w-full px-4 rounded-[5px]   ${
                            router?.pathname === "/dashboard/explore"
                              ? "h-[300px]"
                              : "h-[200px]"
                          } `}
                        >
                          {post?.media?.videos?.map((item, i) => (
                            <video
                              key={i}
                              loop
                              muted
                              playsInline
                              autoPlay={true}
                              controls
                              className="w-full object-cover rounded-[5px] h-full  mx-auto"
                            >
                              <source src={item} type="video/mp4" />
                              {/* <source src="movie.ogg" type="video/ogg" /> */}
                              Your browser does not support the video tag.
                            </video>
                          ))}
                        </div>
                      ) : post?.media?.images.length === 0 ? (
                        <div className=" px-4 rounded-[5px] cursor-pointer">
                          <div
                            className={`   ${
                              router?.pathname === "/dashboard/explore"
                                ? "h-[300px]"
                                : "h-[200px]"
                            } w-full bg-[#272323] rounded-[5px] mt-2 `}
                          ></div>
                        </div>
                      ) : (
                        post?.media?.images?.map((item, i) => (
                          <div
                            onClick={() => handleSinglePost(post)}
                            key={i}
                            className={`mt-[15px]  w-full px-4 cursor-pointer  rounded-[5px]   ${
                              router?.pathname === "/dashboard/explore"
                                ? "h-[300px]"
                                : "h-[200px]"
                            } `}
                          >
                            <img
                              key={i}
                              className="w-full object-cover rounded-[5px] h-full  mx-auto"
                              src={item}
                              alt="media"
                            />
                          </div>
                        ))
                      )}
                    </Carousel>

                    <UserPostShare
                      theme={darktheme}
                      key={index}
                      index={index}
                      updateCounts={updateCounts}
                      isOpen={isOpen}
                      onClose={onClose}
                      handle={() => handleSinglePost(post)}
                      user={user}
                      token={token}
                      openReportModal={openReportModal}
                      selectedPost={post}
                    />
                  </div>
                </div>
              </>
            );
          })}
      </div>
    </InfiniteScroll>
  );
}

export default DashboardSide;
