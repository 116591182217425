import React from "react";
import { useState } from "react";
import { BiMenuAltLeft } from "react-icons/bi";
import { ImCancelCircle } from "react-icons/im";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { aradugbo_icon } from "../../constants/images";

function FixedNav({ defaultColor }) {
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const fix = useSelector((state) => state.features.settings.fix);
  const [activeMenu, setActiveMenu] = useState(false);
  const navigate = useNavigate();
  // const activeLink = `text-[#1D2939] font-bold ml-2`;
  // const normalLink = `text-[#475467] ml-2`;
  const activeLink = `${
    darktheme || defaultColor ? "text-[#FCFCFD]" : "text-[#1D2939]"
  } font-bold ml-2`;
  const normalLink = `${
    darktheme || defaultColor ? "text-[#F2F4F7]" : "text-[#475467]"
  }  ml-2 `;
  const handleOpenSidebar = () => {
    setActiveMenu(!activeMenu);
  };
  const handleNavigate = () => {
    navigate("/");
  };
  const handleAuth = () => {
    navigate("/login");
  };
  return (
    <header
      className={darktheme ? "bg-black text-white" : "bg-white text-[#101828]"}
    >
      <div
        className={`fixed z-[99999]   w-full h-[80px] ${
          fix ? "flex" : "hidden"
        } ${darktheme ? "bg-black text-white" : "bg-white text-[#101828]"}`}
      >
        <div className="flex w-full  md:w-[65%] justify-between items-center mx-auto ">
          <div
            className="flex items-center cursor-pointer mr-[35px]"
            onClick={handleNavigate}
          >
            <div className="pl-[1rem] md:pl-0">
              <img
                src={aradugbo_icon}
                className="object-contain"
                alt="aradugbo_logo"
              />
            </div>
          </div>
          <ul className="navlink-container flex items-center">
            <li className="mr-[39px]">
              <NavLink
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li className="mr-[39px]">
              <NavLink
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                to="/about"
              >
                About
              </NavLink>
            </li>
            <li className="mr-[39px]">
              <NavLink
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                to="/blog"
              >
                Blog
              </NavLink>
            </li>
            <button
              className={`w-[150px] h-[43px]  text-kbody6 rounded-full border border-[#475467]`}
            >
              Download App
            </button>
            <button
              className="login-container w-[150px] h-[43px] text-white text-kbody6 rounded-full ml-3"
              onClick={handleAuth}
            >
              Login/Sign up
            </button>
          </ul>
          <div
            className="hamburger-container pr-[1rem] cursor-pointer"
            onClick={handleOpenSidebar}
          >
            <BiMenuAltLeft color={"#475467"} size={24} />
          </div>
        </div>
      </div>
      {activeMenu && (
        <>
          <div className="sidebar-container w-[100%] h-[100%] bg-[#000] overflow-hidden fixed top-[0%] right-0 z-[999]  text-white">
            <ul className="w-[100%] h-[100%] flex flex-col justify-center items-center text-[30px]">
              <li onClick={handleOpenSidebar}>
                <Link to="/">Home</Link>
              </li>
              <li onClick={handleOpenSidebar}>
                <Link to="/about">About</Link>
              </li>
              <li onClick={handleOpenSidebar}>
                <Link to="/blog">Blog</Link>
              </li>
            </ul>
            <div
              className="z-[999] w-[50px] h-[50px] absolute top-[10%] right-[3%] cursor-pointer"
              onClick={handleOpenSidebar}
            >
              <ImCancelCircle size={24} color="#fff" />
            </div>
          </div>
        </>
      )}
    </header>
  );
}

export default FixedNav;
