import React from "react";
import { aradugbo_icon } from "../../constants/images";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
function Footer({ containerStyle }) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };
  const date = new Date();
  return (
    <div
      className={`${containerStyle} w-full sm:h-[91px] bg-kdark4 text-[#fff] px-4`}
    >
      <div className="max-w-[1240px] h-full m-auto py-2 sm:py-0 flex flex-col sm:flex-row justify-between items-center">
        <div
          className="flex items-center cursor-pointer"
          onClick={handleNavigate}
        >
          <div>
            <img src={aradugbo_icon} alt="aradugbo logo" />
          </div>
          <p className=" my-4 ml-[3rem] sm:my-0">
            © {date.getFullYear()} Aradugbo. All Right Reserved |{" "}
            <span
              className="cursor-pointer"
              onClick={() => navigate("/legal/privacy-policy")}
            >
              Legal
            </span>
          </p>
        </div>

        {/* social network icon */}
        <div className="flex">
          <a
            className="flex justify-center items-center w-[40px] h-[40px] bg-kdark2 rounded-3xl"
            href="https://web.facebook.com/myaradugbo?_rdc=1&_rdr"
          >
            <FaFacebook size={24} color="#fff" />
          </a>
          <a
            className="flex justify-center items-center w-[40px] h-[40px] bg-kdark2 rounded-3xl mx-[18px]"
            href="https://twitter.com/MyAradugbo"
          >
            <FaTwitter size={24} color="#fff" />
          </a>
          <a
            className="flex justify-center items-center w-[40px] h-[40px] bg-kdark2 rounded-3xl"
            href="https://www.instagram.com/MyAradugbo/"
          >
            <FaInstagram size={24} color="#fff" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
