import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import React from "react";
import Xclose from "../../assets/images/x-close.svg";

function SingleLivestream({ isOpen, onClose, liveStream }) {
  return (
    <Modal className="p-0 m-0" isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent
        className="p-2 m-0  relative"
        containerProps={{ padding: 0, margin: 0 }}
      >
        <div
          className=" ml-[27px] z-20 absolute top-6 h-[10%] cursor-pointer"
          onClick={() => onClose()}
        >
          <div className=" bg-[#F9F9FB] flex items-center rounded-[50%] px-[.3rem] w-[27px] h-[27px] text-center">
            <img className="w-full h-full " src={Xclose} alt="cancel" />
          </div>
        </div>
        <div className="bg-black h-[400px] ">
          {liveStream && liveStream?.replayLink && (
            <video controls className="w-full h-full  object-cover">
              <source
                src={liveStream && liveStream?.replayLink}
                type="video/mp4"
              />
              {/* <source src="movie.ogg" type="video/ogg" /> */}
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
}

export default SingleLivestream;
