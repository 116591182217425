import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";

function ProfileUpdateNotice({ isOpen, onClose }) {
  return (
    <Modal className="w-full " isOpen={isOpen} onClose={onClose} size={"md"}>
      <ModalOverlay />
      <ModalContent
        className="w-full overflow-auto "
        containerProps={{ justifyContent: "center" }}
      >
        <ModalCloseButton />
        <ModalBody>
          <div className="text-center p-[2rem] py-[2rem] mt-[2rem]">
            <p className="mb-[2rem]">
              We noticed that your profile is not yet complete. Kindly complete
              your profile for a better Experience.
            </p>
            <NavLink
              to="/dashboard/account"
              className="login-container text-white py-[.5rem] px-[1.5rem] "
            >
              Complete
            </NavLink>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ProfileUpdateNotice;
