import React from "react";
import Privacy from "../Privacy";

function UserDeletion() {
  return (
    <Privacy>
      <h2>UserDeletion</h2>
    </Privacy>
  );
}

export default UserDeletion;
