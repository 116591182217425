import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { containerStyle } from "./privacyStyle";
import { NavLink, useLocation } from "react-router-dom";
import GeneralHeader from "../GeneralHeader/GeneralHeader";
import GeneralFooter from "../GeneralFooter/GeneralFooter";

const mapState = (state) => ({
  posts: state.posts.posts,
  darktheme: state.features.settings.darktheme,
});

function Privacy({ children }) {
  const router = useLocation();

  const { darktheme } = useSelector(mapState);
  const [title] = useState("Legal");

  const [, setTabId] = useState(1);

  const date = new Date();

  useEffect(() => {
    if (router?.pathname === "/legal")
      return window.location.replace("/legal/privacy-policy");
  }, [router?.pathname]);

  return (
    <div className="w-full">
      <GeneralHeader mode={"black"} />
      <div
        className={`relative  overflow-x-hidden h-full ${
          darktheme ? "bg-black text-white" : "bg-white text-[#101828]"
        } `}
      >
        <div className={containerStyle}>
          <div className="text-center text-[#101828] my-[5rem]">
            <div className=" text-[#FC5059] text-[18px] font-Eina01-Regular">
              Current as of {date.toDateString()}
            </div>
            <div className="text-[2rem] my-[1rem] text-[#101828] font-Eina01-Semibold">
              {title}
            </div>
            <div className="md:px-72">
              <p className="font-Eina01-Regular">
                Your privacy is important to us at Aradugbo. We respect your
                privacy regarding any information we may collect from you across
                our website.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`border-b ${
          darktheme ? "bg-black text-white" : "bg-white text-[#101828]"
        } `}
      >
        <div className="flex justify-center">
          <div className="inline-flex  md:w-full overflow-x-scroll overflow-y-hidden md:justify-center cursor-pointer">
            <NavLink
              to="/legal/privacy-policy"
              className={`text-[#667085] font-normal font-Eina01-Semibold ${
                router?.pathname === "/legal/privacy-policy"
                  ? "text-[#F97066] bg-[#f970661f] border-b-2 border-[#F97066]"
                  : ""
              } px-[.5rem] py-[.3rem] `}
              onClick={() => setTabId(1)}
            >
              Privacy Policy
            </NavLink>
            <NavLink
              to="/legal/user-data-deletion"
              className={`text-[#667085] font-normal font-Eina01-Semibold	${
                router?.pathname === "/legal/user-data-deletion"
                  ? "text-[#F97066] bg-[#f970661f] border-b-2 border-[#F97066]"
                  : ""
              } px-[.4rem] py-[.3rem] `}
              onClick={() => setTabId(2)}
            >
              User Data Deletion
            </NavLink>
            <NavLink
              to="/legal/terms-of-use"
              className={`text-[#667085] font-normal font-Eina01-Semibold ${
                router?.pathname === "/legal/terms-of-use"
                  ? "text-[#F97066] bg-[#f970661f] border-b-2 border-[#F97066]"
                  : ""
              } px-[.4rem] py-[.3rem] `}
              onClick={() => setTabId(3)}
            >
              Terms of Use
            </NavLink>
          </div>
        </div>
      </div>

      <div
        className={`flex mt-6 justify-center ${
          darktheme ? "bg-black text-white" : "bg-white text-[#101828]"
        } `}
      >
        <div className="md:w-1/2 w-full md:mx-0 mx-4">{children}</div>
      </div>
      <GeneralFooter />
    </div>
  );
}

export default Privacy;
