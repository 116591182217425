import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function SkeletonLoader() {
  return (
    <div className="w-[100%] ">
      <SkeletonTheme color="grey" style={{ width: "100%", marginTop: ".5rem" }}>
        <div className="w-full flex ">
          <Skeleton
            circle
            height={70}
            width={70}
            style={{ marginRight: "1rem" }}
            count={1}
          />
          <div className="mt-[1rem]">
            <Skeleton height={10} width={200} count={1} />
            <Skeleton height={10} width={250} count={1} />
          </div>
        </div>
        <div className="w-full ">
          <Skeleton
            height={300}
            // width={00}
            count={1}
            style={{ width: "100%", marginBottom: "1rem" }}
          />
        </div>
      </SkeletonTheme>
    </div>
  );
}

export default SkeletonLoader;
