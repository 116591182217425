import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { GrLocation } from "react-icons/gr";
import "mapbox-gl/dist/mapbox-gl.css";
import GooglePlace from "../../Map/GoglePlaces";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import { useDispatch, useSelector } from "react-redux";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Logo from "../../../assets/images/logo.png";
import { updateUserLocation } from "../../../redux/auth/auth.actions";
import Loader from "../../Loader";

/* eslint-disable import/no-webpack-loader-syntax */
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function ChangeLocation({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const [address, setAddress] = useState("");
  const [disabled, setDisabled] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const uploadLoading = useSelector(
    (state) => state.auth.uploadLocationLoading
  );
  const [mapCenter, setMapCenter] = useState({
    longitude: null,
    latitude: null,
  });

  const handleLocationChange = (locations, item) => {
    if (locations && item) {
      setDisabled(false);
      setMapCenter(locations);
      setAddress(item);
    } else {
      setDisabled(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      coords: {
        longitude: mapCenter?.longitude,
        latitude: mapCenter?.latitude,
      },
    };
    dispatch(updateUserLocation(payload));
  };

  const Map = ReactMapboxGl({
    accessToken:
      "pk.eyJ1IjoiYWRtaW5lZmZpY2lvbiIsImEiOiJjbGI2d2JvZHUwNTFsM3ZvZGhmaHYxZ21pIn0.LCwPwD2DbgM0piyDwL4CJw",
    logoPosition: "bottom-right",
  });

  useEffect(() => {
    if (user !== {}) {
      setMapCenter({
        longitude: user?.coords?.longitude,
        latitude: user?.coords?.latitude,
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Modal className="w-full" isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent
        className="w-[869px] h-[600px] overflow-hidden "
        containerProps={{ justifyContent: "center" }}
      >
        <ModalHeader color="#2B2B2B" fontWeight={700} fontSize="21px">
          <div className="w-full flex justify-center">Change Location</div>
          {/* <button onClick={handleLocationChange}>Change Location</button> */}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <GooglePlace propsValue={handleLocationChange} />
          <form className="w-full">
            <div>
              <label className="mb-[15px]">location</label>
              <div className="flex h-[54px] border border-[#CBD5E0] justify-between items-center pr-[17px]">
                <input
                  className="px-[22px] w-[60%] outline-none"
                  value={address}
                  placeholder=""
                />
                <GrLocation />
              </div>
            </div>
          </form>
          <div className="w-full h-[300px]">
            {mapCenter?.longitude && (
              <Map
                // eslint-disable-next-line
                onBoxZoomEnd={(item, e) => {
                  item.flyTo({
                    center: [mapCenter.longitude, mapCenter?.latitude],
                  });
                }}
                zoom={[12]}
                // eslint-disable-next-line
                style="mapbox://styles/mapbox/streets-v8"
                center={[mapCenter.longitude, mapCenter?.latitude]}
                movingMethod="easeTo"
                containerStyle={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <Marker
                  coordinates={[mapCenter.longitude, mapCenter?.latitude]}
                  anchor="bottom"
                  className=" w-[30px] h-[30px] rounded-[50%] bg-white p-[.3rem]"
                >
                  <img
                    src={Logo}
                    alt="logo"
                    className="object-contain  rounded-[50%] w-full h-full"
                  />
                </Marker>
              </Map>
            )}
          </div>

          <button
            disabled={disabled}
            onClick={handleSubmit}
            type="submit"
            className={`flex justify-center items-center w-full h-[48px] mt-[21px] rounded-[5px] text-white  ${
              disabled ? "bg-[#D0D5DD]" : "bg-[#FC5059]"
            }`}
          >
            {uploadLoading ? <Loader /> : "Save"}
          </button>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ChangeLocation;
