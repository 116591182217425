import React, { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { aradugbo_icon } from "../../constants/images";
import { BiMenuAltLeft } from "react-icons/bi";
import { ImCancelCircle } from "react-icons/im";
import "./Header.css";
import { useDispatch, useSelector } from "react-redux";
import { setFixAction } from "../../redux/features/features.action";
import { useEffect } from "react";

function Header({ defaultColor }) {
  const [activeMenu, setActiveMenu] = useState(false);
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const fix = useSelector((state) => state.features.settings.fix);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = useLocation();
  const activeLink = `${
    darktheme || defaultColor ? "text-[#FCFCFD]" : "text-[#1D2939]"
  } font-bold ml-2`;
  const normalLink = `${
    darktheme || defaultColor ? "text-[#F2F4F7]" : "text-[#475467]"
  }  ml-2 `;
  const handleOpenSidebar = () => {
    setActiveMenu(!activeMenu);
  };
  const handleNavigate = () => {
    navigate("/");
  };
  const handleAuth = () => {
    navigate("/login");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleFix);
    return () => {
      window.removeEventListener("scroll", handleFix);
    };
    // eslint-disable-next-line
  }, [fix]);

  const handleFix = () => {
    if (window.scrollY >= 392) {
      dispatch(setFixAction(true));
    } else {
      dispatch(setFixAction(false));
    }
  };
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setScroll(window.pageYOffset > 200)
      );
    }
    // eslint-disable-next-line
  }, []);

  return (
    <header
      className={`w-full h-[100px] ${
        path?.pathname !== "/"
          ? `sticky top-0    z-[2000] ${darktheme ? "bg-black " : "bg-white"}`
          : " bg-transparent "
      } ${scroll && "shadow"}`}
    >
      <div
        className={`flex justify-between h-full w-[90%] md:w-[65%]  pt-[3rem] mx-auto items-center `}
      >
        <div
          className="flex items-center cursor-pointer"
          onClick={handleNavigate}
        >
          <div>
            <img
              src={aradugbo_icon}
              alt="aradugbo_logo"
              className="object-contain"
            />
          </div>
          <div
            className={`ml-[7px]  ${
              darktheme || defaultColor ? "text-white" : "text-black"
            } text-[21px] sm:text-[24px] `}
          ></div>
        </div>
        <ul className="navlink-container flex items-center">
          <li className="mr-[39px]">
            <NavLink
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li className="mr-[39px]">
            <NavLink
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              to="/about"
            >
              About
            </NavLink>
          </li>
          <li className="mr-[39px]">
            <NavLink
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              to="/blog"
            >
              Blog
            </NavLink>
          </li>
          <button
            className={`w-[150px] h-[43px] ${
              darktheme || defaultColor ? "text-white" : "text-[#475467]"
            } text-kbody6 rounded-full border ${
              darktheme || defaultColor ? "border-white" : "border-[#475467]"
            }`}
          >
            Download App
          </button>
          <button
            className={`${
              path?.pathname === "/" ? "text-black bg-white" : "login-container"
            } rounded-[28px] w-[150px] h-[43px]  text-kbody6  ml-3`}
            onClick={handleAuth}
          >
            Login/Sign up
          </button>
        </ul>
        <div
          className="hamburger-container cursor-pointer"
          onClick={handleOpenSidebar}
        >
          <BiMenuAltLeft
            color={`${darktheme || defaultColor ? "#fff" : "#475467"}`}
            size={24}
          />
        </div>
      </div>
      {activeMenu && (
        <>
          <div className="sidebar-container w-[100%] h-[100%] bg-[#000] overflow-hidden fixed top-[0%] right-0 z-[9999999]  text-white">
            <ul className="w-[100%] h-[100%] flex flex-col justify-center items-center text-[30px]">
              <li onClick={handleOpenSidebar}>
                <Link to="/">Home</Link>
              </li>
              <li onClick={handleOpenSidebar}>
                <Link to="/about">About</Link>
              </li>
              <li onClick={handleOpenSidebar}>
                <Link to="/blog">Blog</Link>
              </li>
            </ul>
            <div
              className="z-[999] w-[50px] h-[50px] absolute top-[10%] right-[3%] cursor-pointer"
              onClick={handleOpenSidebar}
            >
              <ImCancelCircle size={24} color="#fff" />
            </div>
          </div>
        </>
      )}
    </header>
  );
}

export default Header;
