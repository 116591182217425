import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
} from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { IoChevronBack } from "react-icons/io5";
import { useDispatch } from "react-redux";
import {
  getPostCommentStatus,
  reportUserPost,
} from "../../redux/posts/posts.action";

function ReportModal({ isOpen, onClose, type, id }) {
  const [step, setStep] = useState(1);
  const [reason, setReason] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPostCommentStatus());
    // eslint-disable-next-line
  }, [reason]);

  const submitReport = () => {
    const payload = {
      value: reason,
      type: "post",
      id: id,
      text: "",
      reported: "",
    };
    dispatch(reportUserPost(payload));
    setStep(3);
  };
  return (
    <Modal className="w-full " isOpen={isOpen} onClose={onClose} size={"md"}>
      {/* <ModalOverla1y /> */}
      <ModalContent
        className="w-full overflow-auto "
        containerProps={{ justifyContent: "center" }}
      >
        <ModalCloseButton />
        <ModalBody>
          <div className="mt-[1rem]   flex align-middle  ">
            <div>
              {step === 1 || step === 3 ? (
                ""
              ) : (
                <IoChevronBack
                  className={`text-xl float-right text-kdark3
               
                `}
                  onClick={() => setStep(step - 1)}
                />
              )}
            </div>

            <h4 className="text-center flex justify-center mx-auto">REPORT</h4>
          </div>
          {step === 1 && (
            <div className="mt-[2rem]  pb-[3rem]">
              <h4 className="mb-[1rem]">Welcome to report.</h4>
              <p className="mb-[1rem] text-[.8rem]">
                We're sorry to hear that you're having concerns with another
                user's account on our platform. We take reports of inappropriate
                behavior very seriously, and we're here to help.
              </p>

              <p className="text-[.8rem] mb-[1rem]">
                To report an account, please click the 'Next' button below to
                access the report form. The form will ask you to choose a reason
                for the report and provide additional details about the issue.
              </p>
              <p className="text-[.8rem] mb-[1rem]">
                Please note that your report will be anonymous and confidential.
                We appreciate your help in keeping our community safe and
                enjoyable for everyone.
              </p>

              <p className="text-[.8rem] mb-[1rem]">
                Thank you for taking the time to report this account. Let's work
                together to make our platform a positive and welcoming space for
                all users.
              </p>
              <button
                onClick={() => setStep(2)}
                className="w-[100%] py-[.5rem] text-white bg-[#FC5059] rounded-[5px]"
              >
                Next
              </button>
              {type === "Suspended" && (
                <>
                  <h4 className="mb-[1rem] mt-[1rem]">What you can do:</h4>
                  <ul className="list-disc">
                    To unlock your account, you must do the following:
                    <li className="ml-[1rem]">Pass an Arkose challenge</li>
                    <li className="ml-[1rem]">Verify your phone number</li>
                  </ul>
                </>
              )}
            </div>
          )}
          {step === 2 && (
            <div className="mt-[1rem]  pb-[3rem]">
              <h4 className="mb-[1rem]">Why are you reporting this account?</h4>
              <p className="-mt-[1rem] text-[.8rem]">
                Select the kind of sensitive or disturbing content?.
              </p>
              <div class="flex-col gap-10">
                <div
                  onClick={() => setReason("Attacked because of my identity")}
                  className="mb-[.8rem] mt-[.5rem] block min-h-[1.5rem] pl-[1.5rem]"
                >
                  <input
                    className="relative float-left -ml-[1.5rem] mr-[.5rem] mt-[.4rem] h-4 w-4 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] disabled:opacity-60 dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                    type="radio"
                    name="flexRadioDisabled"
                    id="flexRadioCheckedDisabled"
                  />
                  <div className="">
                    <label
                      class="text-[.8rem] font-bold  text-gray-700 cursor-pointer select-none"
                      htmlFor="on"
                    >
                      Attacked because of my identity
                    </label>

                    <p className="text-[.8rem] pl-[.2rem]">
                      Slurs, misgendering, racist or sexist steroetypes,
                      encouraging others to harass, sending hateful imagery.
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => setReason("Violence")}
                  className="mb-[.8rem]  block min-h-[1.5rem] pl-[1.5rem]"
                >
                  <input
                    className="relative float-left -ml-[1.5rem] mr-[.5rem] mt-[.4rem] h-4 w-4 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] disabled:opacity-60 dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                    type="radio"
                    name="flexRadioDisabled"
                    id="flexRadioCheckedDisabled"
                  />
                  <div className="">
                    <label
                      class="text-[.8rem] font-bold  text-gray-700 cursor-pointer select-none"
                      htmlFor="on"
                    >
                      Harassed or intimated with violence
                    </label>

                    <p className="text-[.8rem]  pl-[.2rem]">
                      Sexual harassment, group harassment, insult or name
                      calling, posting private info, threatening to expose
                      private info, violent incident denial, violent threats,
                      celebration of violent acts.
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => setReason("Spammed")}
                  className="mb-[.8rem]   block min-h-[1.5rem] pl-[1.5rem]"
                >
                  <input
                    className="relative float-left -ml-[1.5rem] mr-[.5rem] mt-[.4rem] h-4 w-4 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] disabled:opacity-60 dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                    type="radio"
                    name="flexRadioDisabled"
                    id="flexRadioCheckedDisabled"
                  />
                  <div className="">
                    <label
                      class="text-[.8rem] font-bold  text-gray-700 cursor-pointer select-none"
                      htmlFor="on"
                    >
                      Spammed
                    </label>

                    <p className="text-[.8rem]  pl-[.2rem]">
                      Posting malicious links, misusing hastags, fake
                      engagement, repetitive replies, Retweets, or Direct
                      Message.
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => setReason("False Information")}
                  className="mb-[.8rem]  block min-h-[1.5rem] pl-[1.5rem]"
                >
                  <input
                    className="relative float-left -ml-[1.5rem] mr-[.5rem] mt-[.4rem] h-4 w-4 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] disabled:opacity-60 dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                    type="radio"
                    name="flexRadioDisabled"
                    id="flexRadioCheckedDisabled"
                  />
                  <div className="">
                    <label
                      class="text-[.8rem] font-bold  text-gray-700 cursor-pointer select-none"
                      htmlFor="on"
                    >
                      False Information
                    </label>

                    <p className="text-[.8rem]  pl-[.2rem]">
                      Health, Politics, Social issue, Something else.
                    </p>
                  </div>
                </div>
              </div>
              <button
                onClick={() => submitReport()}
                disabled={reason === "" ? true : false}
                className={`w-[100%] mt-[1rem] py-[.5rem] text-white ${
                  reason === "" ? "bg-[grey]" : "bg-[#FC5059]"
                }  rounded-[5px]`}
              >
                Submit
              </button>
            </div>
          )}
          {step === 16 && (
            <div className="mt-[1rem]  pb-[3rem]">
              <h4 className="mb-[1rem]">Why are you reporting this account?</h4>
              <p className="-mt-[1rem] text-[.8rem]">
                Select who you are reporting for.
              </p>

              <div class="flex-col gap-10">
                <div className="mb-[.8rem] mt-[.5rem] block min-h-[1.5rem] pl-[1.5rem]">
                  <input
                    className="relative float-left -ml-[1.5rem] mr-[.5rem] mt-[.4rem] h-4 w-4 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] disabled:opacity-60 dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                    type="radio"
                    name="flexRadioDisabled"
                    id="flexRadioCheckedDisabled"
                    defaultChecked
                  />
                  <div className="">
                    <label
                      class="text-[.8rem] font-bold  text-gray-700 cursor-pointer select-none"
                      htmlFor="on"
                    >
                      Myself
                    </label>

                    <p className="text-[.8rem] pl-[.2rem]">
                      Showing inappropriate body part, sexually suggestive,
                      sharing private images, involves a child
                    </p>
                  </div>
                </div>
                <div className="mb-[.8rem]  block min-h-[1.5rem] pl-[1.5rem]">
                  <input
                    className="relative float-left -ml-[1.5rem] mr-[.5rem] mt-[.4rem] h-4 w-4 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] disabled:opacity-60 dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                    type="radio"
                    name="flexRadioDisabled"
                    id="flexRadioCheckedDisabled"
                    defaultChecked
                  />
                  <div className="">
                    <label
                      class="text-[.8rem] font-bold  text-gray-700 cursor-pointer select-none"
                      htmlFor="on"
                    >
                      Someone else or a specific
                    </label>

                    <p className="text-[.8rem]  pl-[.2rem]">
                      This Profile is directed at or mentions a group of people
                      -- like racial or religious groups.
                    </p>
                  </div>
                </div>
                <div className="mb-[4rem]  block min-h-[1.5rem] pl-[1.5rem]">
                  <input
                    className="relative float-left -ml-[1.5rem] mr-[.5rem] mt-[.4rem] h-4 w-4 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] disabled:opacity-60 dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                    type="radio"
                    name="flexRadioDisabled"
                    id="flexRadioCheckedDisabled"
                    defaultChecked
                  />
                  <div className="">
                    <label
                      class="text-[.8rem] font-bold  text-gray-700 cursor-pointer select-none"
                      htmlFor="on"
                    >
                      Everyone on Aradugbo
                    </label>

                    <p className="text-[.8rem]  pl-[.2rem]">
                      This affects everyone
                    </p>
                  </div>
                </div>
              </div>
              <button
                onClick={() => setStep(3)}
                className="w-[100%] mt-[1rem] py-[.5rem] text-white bg-[#FC5059] rounded-[5px]"
              >
                Next
              </button>
            </div>
          )}
          {step === 14 && (
            <div className="mt-[1rem]  pb-[3rem]">
              <h4 className="mb-[1rem]">Who is this reporting for?</h4>
              <p className="-mt-[1rem] text-[.8rem]">
                Select who you are reporting for.
              </p>

              <div class="flex-col gap-10">
                <div className="mb-[.8rem] mt-[.5rem] block min-h-[1.5rem] pl-[1.5rem]">
                  <input
                    className="relative float-left -ml-[1.5rem] mr-[.5rem] mt-[.4rem] h-4 w-4 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] disabled:opacity-60 dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                    type="radio"
                    name="flexRadioDisabled"
                    id="flexRadioCheckedDisabled"
                    defaultChecked
                  />
                  <div className="">
                    <label
                      class="text-[.8rem] font-bold  text-gray-700 cursor-pointer select-none"
                      htmlFor="on"
                    >
                      Myself
                    </label>

                    <p className="text-[.8rem] pl-[.2rem]">
                      Showing inappropriate body part, sexually suggestive,
                      sharing private images, involves a child
                    </p>
                  </div>
                </div>
                <div className="mb-[.8rem]  block min-h-[1.5rem] pl-[1.5rem]">
                  <input
                    className="relative float-left -ml-[1.5rem] mr-[.5rem] mt-[.4rem] h-4 w-4 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] disabled:opacity-60 dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                    type="radio"
                    name="flexRadioDisabled"
                    id="flexRadioCheckedDisabled"
                    defaultChecked
                  />
                  <div className="">
                    <label
                      class="text-[.8rem] font-bold  text-gray-700 cursor-pointer select-none"
                      htmlFor="on"
                    >
                      Someone else or a specific
                    </label>

                    <p className="text-[.8rem]  pl-[.2rem]">
                      This Profile is directed at or mentions a group of people
                      -- like racial or religious groups.
                    </p>
                  </div>
                </div>
                <div className="mb-[4rem]  block min-h-[1.5rem] pl-[1.5rem]">
                  <input
                    className="relative float-left -ml-[1.5rem] mr-[.5rem] mt-[.4rem] h-4 w-4 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] disabled:opacity-60 dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                    type="radio"
                    name="flexRadioDisabled"
                    id="flexRadioCheckedDisabled"
                    defaultChecked
                  />
                  <div className="">
                    <label
                      class="text-[.8rem] font-bold  text-gray-700 cursor-pointer select-none"
                      htmlFor="on"
                    >
                      Everyone on Aradugbo
                    </label>

                    <p className="text-[.8rem]  pl-[.2rem]">
                      This affects everyone
                    </p>
                  </div>
                </div>
              </div>
              <button
                onClick={() => setStep(5)}
                className="w-[100%] mt-[1rem] py-[.5rem] text-white bg-[#FC5059] rounded-[5px]"
              >
                Next
              </button>
            </div>
          )}
          {step === 3 && (
            <div className="mt-[2rem]  pb-[3rem]">
              <h4 className="mb-[1rem]">
                Thank you for reporting this account.
              </h4>
              <p className="mb-[1rem] text-[.8rem]">
                Your report has been received and our team will investigate the
                issue. We appreciate your help in keeping our community safe and
                enjoyable for all users. We take reports of inappropriate
                behavior very seriously and we will take appropriate action as
                needed.
              </p>

              <p className="text-[.8rem] mb-[1rem]">
                Please note that due to privacy and confidentiality reasons, we
                will not be able to share the outcome of our investigation with
                you. However, rest assured that we have a dedicated team of
                moderators who review all reports thoroughly and take
                appropriate action in accordance with our community guidelines.
              </p>
              <p className="text-[.8rem] mb-[2rem]">
                If you have any further concerns or questions, please don't
                hesitate to reach out to our support team. Thank you again for
                helping us maintain a positive and welcoming community.
              </p>

              <button
                onClick={onClose}
                className="w-[100%] py-[.5rem] text-white bg-[#FC5059] rounded-[5px]"
              >
                Close
              </button>
              {type === "Suspended" && (
                <>
                  <h4 className="mb-[1rem] mt-[1rem]">What you can do:</h4>
                  <ul className="list-disc">
                    To unlock your account, you must do the following:
                    <li className="ml-[1rem]">Pass an Arkose challenge</li>
                    <li className="ml-[1rem]">Verify your phone number</li>
                  </ul>
                </>
              )}
            </div>
          )}
          {/* <div className="mt-[2rem]  pb-[3rem]">
            <h4 className="mb-[1rem]">Welcome to report.</h4>
            <p className="mb-[1rem] text-[.8rem]">
              We're sorry to hear that you're having concerns with another
              user's account on our platform. We take reports of inappropriate
              behavior very seriously, and we're here to help.
            </p>

            <p className="text-[.8rem] mb-[1rem]">
              To report an account, please click the 'Next' button below to
              access the report form. The form will ask you to choose a reason
              for the report and provide additional details about the issue.
            </p>
            <p className="text-[.8rem] mb-[1rem]">
              Please note that your report will be anonymous and confidential.
              We appreciate your help in keeping our community safe and
              enjoyable for everyone.
            </p>

            <p className="text-[.8rem] mb-[1rem]">
              Thank you for taking the time to report this account. Let's work
              together to make our platform a positive and welcoming space for
              all users.
            </p>
            <button className="w-[100%] py-[.5rem] bg-[#FC5059] rounded-[5px]">
              Next
            </button>
            {type === "Suspended" && (
              <>
                <h4 className="mb-[1rem] mt-[1rem]">What you can do:</h4>
                <ul className="list-disc">
                  To unlock your account, you must do the following:
                  <li className="ml-[1rem]">Pass an Arkose challenge</li>
                  <li className="ml-[1rem]">Verify your phone number</li>
                </ul>
              </>
            )}
          </div> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ReportModal;
