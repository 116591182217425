import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FaSpinner } from "react-icons/fa";
import React, { useEffect } from "react";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  setCategoryType,
  uploadPostByUser,
} from "../../redux/posts/posts.action";

function UploadEmergency({ isOpen, onClose, updateModal, getEvent }) {
  const [emergencyCategories, setEmergencyCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setUploadLoading] = useState(false);
  const { coords } = useLocation();
  const [trigger, setTrigger] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({
    longitude: null,
    latitude: null,
  });
  const dispatch = useDispatch();
  const routePath = useLocation();
  const token = useSelector((state) => state.auth.accessToken);
  const loadingPost = useSelector((state) => state.posts.uploadLoading);
  const activeCategory = useSelector((state) => state.posts.activeCategory);

  const handleEmergencyType = (type) => {
    dispatch(setCategoryType(type));
  };
  const onTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    onTop();
  }, [routePath]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        "https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/emergencies/categories",
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setEmergencyCategories(response.data);
      })
      .catch((err) => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  const next = () => {
    updateModal(2);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;

                setCurrentLocation({ longitude, latitude });
                setTrigger(!trigger);
              },
              (error) => {
                console.error("Error getting location:", error);
              },
              { enableHighAccuracy: true, maximumAge: 0, timeout: 60000 }
            );
          }
        })
        .catch((err) => err);
    } else {
      console.error("Geolocation is not supported in this browser.");
    }

    // eslint-disable-next-line
  }, []);

  const handleInstaPost = async () => {
    try {
      setUploadLoading(true);
      const instantPostPayload = {
        media: { videos: [], images: [] },
        coords:
          currentLocation.latitude !== null
            ? {
                latitude: currentLocation.latitude,
                longitude: currentLocation.longitude,
              }
            : coords,
        type: "instant",
        title: activeCategory,
        mapUrl: "",
        platform: "",
        text: activeCategory,
        label: "",
        icon: "",
        category: activeCategory,
      };
      dispatch(uploadPostByUser(instantPostPayload));

      setTimeout(() => {
        getEvent();
        onClose();
        updateModal(3);
        dispatch(setCategoryType(""));
      }, 2000);
    } catch (rejectedValueOrSerializedError) {
      // handle error here
      setUploadLoading(false);
    }
  };

  return (
    <>
      <Modal className="w-full " isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay />
        <ModalContent className="w-full  overflow-hidden ">
          <ModalHeader color="#2B2B2B">
            <div className="flex flex-col items-center">
              <h4 className="text-black font-semibold text-[24px]">
                Upload Emergency
              </h4>
              <span className="text-[.8rem]">
                Select Type of <bold>Incident</bold> you are in
              </span>
            </div>
          </ModalHeader>
          <ModalCloseButton className="bg-black">
            <div className="w-[30px] h-[30px] bg-[#F2F4F7] rounded-full justify-center flex items-center">
              <FaTimes size={12} />
            </div>
          </ModalCloseButton>
          {loading ? (
            <FaSpinner className="text-center mx-auto" />
          ) : (
            <div className="w-full h-[500px] px-[1rem]">
              <div className="w-full  justify-between flex   flex-wrap  mt-[1rem]">
                {emergencyCategories.map((item, index) => {
                  return (
                    <button
                      key={index}
                      className={`text-center border-[#9c9b9b]  w-[48%] hover:border-[#F04438] hover:bg-[#F04438] hover:text-[white] text-[.8rem]  py-[.5rem]  mb-5 px-[1rem]  border-dashed border-[1px]  rounded-[5px] ${
                        activeCategory === item.name
                          ? "bg-[#F04438] text-[white] border-[#F04438]"
                          : "bg-[#F9FAFB]"
                      }`}
                      onClick={() => handleEmergencyType(item.name)}
                    >
                      {item.name}
                    </button>
                  );
                })}
              </div>
            </div>
          )}

          <ModalFooter>
            <div className="w-full">
              <button
                onClick={handleInstaPost}
                disabled={!activeCategory ? true : false}
                className="cursor-pointer w-full py-2 text-[#F04438] font-bold bg-[#FF010110] rounded-[5px]"
              >
                {loadingPost ? (
                  <FaSpinner className="w-5 h-5 mx-auto text-[#F04438]" />
                ) : (
                  "Instant Post"
                )}
              </button>
              <button
                onClick={next}
                disabled={!activeCategory ? true : false}
                className="py-2 cursor-pointer w-full bg-[#F04438] font-bold border text-white border-[#f04438] rounded-[5px] mt-[20px]"
              >
                Next
              </button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UploadEmergency;
