import { liveTypes } from "./live.type";

const initialState = {
  liveStream: {},
  error: {},
  isLoading: false,
  chats: {},
};
export const livestreamReducer = (state = initialState, action) => {
  switch (action.type) {
    case liveTypes.FETCH_LIVE_CONTENT_START:
      return {
        ...state,
      };
    case liveTypes.FETCH_LIVE_CONTENT:
      return {
        ...state,
        liveStream: action.payload,
      };
    case liveTypes.FETCH_LIVE_CONTENT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case liveTypes.POST_MESSAGE_START:
      return {
        ...state,
      };
    case liveTypes.POST_MESSAGE:
      return {
        ...state,
        chats: action.payload,
      };
    case liveTypes.POST_MESSAGE_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
