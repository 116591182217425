import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyD1l7GxQli_v3G0zywqEkl3vSr6v36i44g",
  authDomain: "aradugbo-ac17a.firebaseapp.com",
  databaseURL: "https://aradugbo-ac17a-default-rtdb.firebaseio.com",
  projectId: "aradugbo-ac17a",
  storageBucket: "aradugbo-ac17a.appspot.com",
  messagingSenderId: "271509946343",
  appId: "1:271509946343:web:c865823cd9120b34720742",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

