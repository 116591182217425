import React from "react";
import CustomIcon from "../CustomIcon/CustomIcon";

function AboutFeature({ title, shortDescription, icon }) {
  function getFirstWordAndRemainder(str) {
    // Split the string into an array of words
    const words = str.trim().split(/\s+/);

    // Extract the first word
    const firstWord = words.shift(); // Remove and return the first element of the array

    // Get the remainder by joining the remaining words
    const remainder = words.join(" ");

    return [firstWord, remainder];
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="pt-6 pb-2">
        <CustomIcon icon={icon} />
      </div>
      <p className="pb-2 font-normal text-2xl font-Eina01-Semibold text-center text-[#1C2024]">
        {getFirstWordAndRemainder(title)[0]}
        <br />
        {getFirstWordAndRemainder(title)[1]}
      </p>
      <p className="text-[#60646C] font-normal font-Eina01-Regular text-center text-base">
        {shortDescription}
      </p>
    </div>
  );
}

export default AboutFeature;
