import axios from "axios";
import { getActivities, getSingleActivities } from "../api";
import { setIsLoading } from "../features/features.action";
import { alertTypes } from "./alert.types";
const token = localStorage.getItem("accessToken");

export const getAllAroundAction = () => async (dispatch, getState) => {
  // let newAroundArray = [];
  try {
    dispatch(setIsLoading(true));

    const { data } = await getActivities("null");
    if (data) {
      const getSingleData = async (eventId) => {
        const { data } = await getSingleActivities(eventId);
        if (data) {
          dispatch({
            type: alertTypes.SET_ALL_AROUND_SUCCESS,
            payload: data,
          });
        }
      };
      data.allNotification.map((evId) => getSingleData(evId.eventId));
    } else {
      dispatch({
        type: alertTypes.NO_MORE_DATA,
      });
    }
    dispatch(setIsLoading(false));
  } catch (err) {
    dispatch({
      type: alertTypes.SET_ALL_AROUND_ERROR,
    });
    dispatch(setIsLoading(false));
  }
};

export const getAllActivityAction = () => async (dispatch, getState) => {
  const getIndex = getState().alert.index;

  // let newActivityArray = [];
  try {
    dispatch(setIsLoading(true));

    const { data } = await axios.get(
      `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/alertActivity/${
        getIndex === 1 ? "null" : "newDoc"
      }`,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      const getSingleData = async (eventId) => {
        const { data } = await axios.get(
          `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/events/${eventId}`
        );
        if (data) {
          dispatch({
            type: alertTypes.SET_ALL_ACTIVITY_SUCCESS,
            payload: data,
          });
        }
      };
      data.allNotification.map((evId) => {
        return getSingleData(evId.eventId);
      });
    } else {
      dispatch({
        type: alertTypes.NO_MORE_DATA,
      });
    }
    dispatch(setIsLoading(false));
  } catch (err) {
    dispatch({
      type: alertTypes.SET_ALL_ACTIVITY_ERROR,
    });
    dispatch(setIsLoading(false));
  }
};

export const getMoreData = () => {
  return {
    type: alertTypes.GET_MORE_DATA,
  };
};
