import React from "react";
import Privacy from "../Privacy";

function Policy() {
  return (
    <Privacy>
      <div className="w-full text-[#475467] font-Eina01-Regular">
        <p>
          Efficion Limited (" <strong>We</strong> ") respect your privacy and
          are committed to protecting it through our compliance with this
          policy.
        </p>
        <p>This policy describes:</p>
        <ul className="list-disc list-inside p-8">
          <li>
            The types of information we may collect or that you may provide when
            you purchase, download, install, register with, access, or use the
            Aradugbo App. (the "<strong>App</strong>").
          </li>
          <li>
            Our practices for collecting, using, maintaining, protecting, and
            disclosing that information.
          </li>
        </ul>
        <p>
          This policy applies only to information we collect in this App, in
          email, text, and other electronic communications sent through or in
          connection with this App.
        </p>
        <p>This policy DOES NOT apply to information that:</p>
        <ul className="list-disc list-inside p-8">
          <li>
            We collect offline or on any other Company apps or websites,
            including websites you may access through this App.
          </li>
          <li>
            You provide to or is collected by any third party (see
            <a href="#informtaion-collection">
              Third-Party Information Collection
            </a>
            )
          </li>
        </ul>
        <p>
          Our websites and apps, and these other third parties may have their
          own privacy policies, which we encourage you to read before providing
          information on or through them.
        </p>
        <p className="py-4">
          Please read this policy carefully to understand our policies and
          practices regarding your information and how we will treat it. If you
          do not agree with our policies and practices, do not download,
          register with, or use this App. By downloading, registering with, or
          using this App, you agree to this privacy policy. This policy may
          change from time to time (see
          <a href="#change-privacy">Changes to Our Privacy Policy</a> ). Your
          continued use of this App after we revise this policy means you accept
          those changes, so please check the policy periodically for updates.
        </p>
        <h1 className="text-[28px] py-8  text-[#101828]">
          <u>Children Under the Age of 13</u>
        </h1>
        <p>
          The App is not intended for children under 13 years of age, and we do
          not knowingly collect personal information from children under 13. If
          we learn we have collected or received personal information from a
          child under 13 without verification of parental consent, we will
          delete that information. If you believe we might have any information
          from or about a child under 13, please contact us at
          policy@efficion.org.
        </p>
        <h1 className="text-[28px] py-8 font-Eina01-Semibold text-[#101828]">
          <u>Information We Collect and How We Collect It</u>
        </h1>
        <p>We collect information from and about users of our App:</p>
        <ul className="list-disc list-inside p-8">
          <li>Directly from you when you provide it to us</li>
          <li>Automatically when you use the App</li>
        </ul>
        <p className="text-[28px] py-8 font-Eina01-Semibold text-[#101828]">
          <i>Information You Provide to Us</i>
        </p>
        <p>
          When you download, register with, or use this App, we may ask you
          provide information:
        </p>
        <ul className="list-disc list-inside p-8">
          <li>
            By which you may be personally identified, such as name, postal
            address, email address, telephone number or any other identifier by
            which you may be contacted online or offline
            <strong> personal information</strong>
          </li>
          <li>That is about you but individually does not identify you.</li>
          <p>This information includes: </p>
          <li>
            Information that you provide by filling in forms in the App. This
            includes information provided at the time of registering to use the
            App and subscribing to our service, and posting material, and
            requesting further services. We may also ask you for information
            when you enter a contest or promotion sponsored by us, and when you
            report a problem with the App.
          </li>
          <li>
            Records and copies of your correspondence (including email addresses
            and phone numbers), if you contact us.
          </li>
          <li>
            Your responses to surveys that we might ask you to complete for
            research purposes.
          </li>
          <li>
            Details of transactions you carry out through the App and of the
            fulfillment of your orders. You may be required to provide financial
            information before placing an order through the App.
          </li>
          <li>Your search queries on the App.</li>
          <li>Information about ongoing events.</li>
        </ul>
        <p>
          You may also provide information for publication or display ("{" "}
          <strong>Posted</strong> ") on public areas of the app or websites you
          access through the App or website (collectively, "
          <strong>User Contributions</strong>"). Your User Contributions are
          Posted and transmitted to others at your own risk. Additionally, we
          cannot control the actions of third parties with whom you may choose
          to share your User Contributions. Therefore, we cannot and do not
          guarantee that your User Contributions will not be viewed by
          unauthorized persons.
        </p>
        <h1 className="text-[28px] py-8 font-Eina01-Semibold text-[#101828]">
          <i>Automatic Information Collection and Tracking</i>
        </h1>
        <p>
          When you download, access, and use the App, it may use technology to
          automatically collect:
        </p>
        <ul className="list-disc list-inside p-8">
          <li>
            <strong>Usage Details.</strong> When you access and use the App, we
            may automatically collect certain details of your access to and use
            of the App, including traffic data, location data, logs, and other
            communication data and the resources that you access and use on or
            through the App.
          </li>
          <li>
            <strong>Device Information.</strong> We may collect information
            about your mobile device and internet connection, including the
            device's unique device identifier, IP address, operating system,
            browser type, mobile network information, and the device's telephone
            number.
          </li>
          <li>
            <strong>Stored Information and Files.</strong> The App also may
            access metadata and other information associated with other files
            stored on your device. This may include, for example, photographs,
            audio and video clips, personal contacts, and address book
            information.
          </li>
          <li>
            <strong>Location Information.</strong> This App collects real-time
            information about the location of your device.
          </li>
        </ul>
        <p>
          If you do not want us to collect this information [do not download the
          App or delete it from your device/you may opt out at any time by
          disabling your location sharing on your device. For more information,
          see{" "}
          <a href="#choices">
            Your Choices About Our Collection, Use, and Disclosure of Your
            Information
          </a>
          . Note, however, that opting out of the App's collection of location
          information will disable its location-based features.
        </p>
        <p>
          We also may use these technologies to collect information about your
          activities over time and across third-party websites, apps, or other
          online services (behavioral tracking).{" "}
        </p>
        <h1
          className="text-[28px] py-8 font-Eina01-Semibold text-[#101828]"
          id="informtaion-collection"
        >
          Information Collection [and Tracking] Technologies
        </h1>
        <p>
          The technologies we use for automatic information collection may
          include:
        </p>
        <ul>
          <li>
            <strong>Cookies (or mobile cookies).</strong> A cookie is a small
            file placed on your smartphone. It may be possible to refuse to
            accept mobile cookies by activating the appropriate setting on your
            smartphone. However, if you select this setting you may be unable to
            access certain parts of our App.
          </li>
          <li>
            <strong>Web Beacons.</strong>Pages of the App [and our emails] may
            contain small electronic files known as web beacons (also referred
            to as clear gifs, pixel tags, and single-pixel gifs) that permit the
            Company, for example, to count users who have visited those pages
            [or opened an email] and for other related app statistics (for
            example, recording the popularity of certain app content and
            verifying system and server integrity).
          </li>
        </ul>
        <h1 className="text-[28px] py-8 font-Eina01-Semibold text-[#101828]">
          <i>Third-Party Information Collection</i>
        </h1>
        <p>
          When you use the App or its content, certain third parties may use
          automatic information collection technologies to collect information
          about you or your device. These third parties [may] include:{" "}
        </p>
        <ul className="list-disc list-inside p-8">
          <li>Advertisers, ad networks, and ad servers.</li>
          <li>Analytics companies.</li>
          <li>Your mobile device manufacturer.</li>
          <li>Your mobile service provider.</li>
        </ul>
        <p>
          These third parties may use tracking technologies to collect
          information about you when you use this app. The information they
          collect may be associated with your personal information or they may
          collect information, including personal information, about your online
          activities over time and across different websites, apps, and other
          online services websites.] They may use this information to provide
          you with interest-based (behavioral) advertising or other targeted
          content.{" "}
        </p>
        <p>
          We do not control these third parties' tracking technologies or how
          they may be used. If you have any questions about an advertisement or
          other targeted content, you should contact the responsible provider
          directly. [For information about how you can opt out of receiving
          targeted advertising from many providers, see Your Choices About Our
          Collection, Use, and Disclosure of Your Information
        </p>
        <h1 className="text-[28px] py-8 font-Eina01-Semibold text-[#101828]">
          <u>How We Use Your Information</u>
        </h1>
        <p>
          We use information that we collect about you or that you provide to
          us, including any personal information, to:
        </p>
        <ul className="list-disc list-inside p-8">
          <li>
            Provide you with the App and its contents, and any other
            information, products, or services that you request from us.
          </li>
          <li>Fulfill any other purpose for which you provide it.</li>
          <li>
            Give you notices about your account including expiration and renewal
            notices.
          </li>
          <li>
            Carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection.
          </li>
          <li>
            Notify you when App updates are available, and of changes to any
            products or services we offer or provide though it.
          </li>
        </ul>
        <p>
          The usage information we collect helps us to improve our App and to
          deliver a better and more personalized experience by enabling us to:
        </p>
        <ul className="list-disc list-inside p-8">
          <li>Estimate our audience size and usage patterns.</li>
          <li>
            Store information about your preferences, allowing us to customize
            our App according to your individual interests.
          </li>
          <li>Speed up your searches.</li>
          <li>Recognize you when you use the App.</li>
        </ul>
        <p>
          We use location information we collect to verify the accuracy of
          related real time posts or comments.
        </p>
        <p>
          We may also use your information to contact you about [our own and
          third parties'] goods and services that may be of interest to you. If
          you do not want us to use your information in this way, please [check
          the relevant box located on the form on which we collect your data
          (the [order form/registration form])/adjust your user preferences in
          your account profile.]] For more information, see Your Choices About
          Our Collection, Use, and Disclosure of Your Information
        </p>
        <p>
          We may use the information we collect to display advertisements to our
          advertisers' target audiences. Even though we do not disclose your
          personal information for these purposes without your consent, if you
          click on or otherwise interact with an advertisement, the advertiser
          may assume that you meet its target criteria.
        </p>
        <h1
          className="text-[28px] py-8 font-Eina01-Semibold text-[#101828]"
          id="#choices"
        >
          <u>Disclosure of Your Information</u>
        </h1>
        <p>
          We may disclose aggregated information about our users without
          restriction.{" "}
        </p>
        <p>
          In addition, we may disclose personal information that we collect or
          you provide:
        </p>
        <ul className="list-disc list-inside p-8">
          <li>To our subsidiaries and affiliates.</li>
          <li>
            To contractors, service providers, and other third parties we use to
            support our business[ and who are bound by contractual obligations
            to keep personal information confidential and use it only for the
            purposes for which we disclose it to them].
          </li>
          <li>
            To a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or
            transfer of some or all of Efficion Limited’s assets, whether as a
            going concern or as part of bankruptcy, liquidation, or similar
            proceeding, in which personal information held by Efficion Ltd.
            about our App users is among the assets transferred.
          </li>
          <li>
            [To third parties to market their products or services to you if you
            have [consented to/not opted out of] these disclosures. We
            contractually require these third parties to keep personal
            information confidential and use it only for the purposes for which
            we disclose it to them.] For more information, see Your Choices
            About Our Collection, Use, and Disclosure of Your Information
          </li>
          <li>
            To fulfill the purpose for which you provide it. For example, if you
            give us an email address to use the "email a friend" feature of our
            Website or App, we will transmit the contents of that email and your
            email address to the recipients.
          </li>
          <li>
            For any other purpose disclosed by us when you provide the
            information.
          </li>
          <li>With your consent.</li>
          <li>
            To comply with any court order, law, or legal process, including to
            respond to any government or regulatory request.
          </li>
          <li>
            To enforce our rights arising from any contracts entered into
            between you and us, including the App EULA, and for billing and
            collection.
          </li>
          <li>
            If we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of Efficion Ltd., our customers, or
            others. This includes exchanging information with other companies
            and organizations for the purposes of fraud protection and credit
            risk reduction.
          </li>
        </ul>
        <h1 className="text-[28px] py-8 font-Eina01-Semibold text-[#101828]">
          <u>
            Your Choices About Our Collection, Use, and Disclosure of Your
            Information
          </u>
        </h1>
        <p>
          We strive to provide you with choices regarding the personal
          information you provide to us. This section describes mechanisms we
          provide for you to control certain uses and disclosures of over your
          information.{" "}
        </p>
        <ul className="list-disc list-inside p-8">
          <li>
            <strong>Tracking Technologies.</strong> You can set your browser to
            refuse all or some browser cookies, or to alert you when cookies are
            being sent. You can choose whether or not to allow the App to
            collect information through other tracking technologies by opting
            out when prompted. If you disable or refuse cookies or block the use
            of other tracking technologies, some parts of the App may then be
            inaccessible or not function properly.
          </li>
          <li>
            <strong>Location Information.</strong> You can choose whether or not
            to allow the App to collect and use real- time information about
            your device's location through the device's privacy settings. If you
            block the use of location information, some parts of the App may
            become inaccessible or not function properly.
          </li>
          <li>
            <strong>Promotion by the Company.</strong> If you do not want us to
            use your email address or contact information to promote our own or
            third parties' products or services, you can opt-out when prompted.
            You can also always opt-out by logging into the App and adjusting
            your user preferences in your account profile by checking or
            unchecking the relevant boxes or by sending us an email stating your
            request to policy@efficion.org.{" "}
          </li>
          <li>
            <strong>Targeted Advertising by the Company.</strong> If you do not
            want us to use information that we collect or that you provide to us
            to deliver advertisements according to our advertisers'
            target-audience preferences, you can opt-out when prompted. Or you
            can also always adjust your user advertising preferences in your
            account profile by checking or unchecking the relevant boxes or by
            sending us an email [or text message] stating your request to
            policy@efficion.org.
          </li>
          <li>
            <strong>
              Disclosure of Your Information for Third-Party Advertising and
              Marketing.
            </strong>{" "}
            If you do not want us to share your personal information with
            unaffiliated or non-agent third parties for advertising and
            marketing purposes, you can opt-out when prompted. Or you can also
            always opt- out by logging into the App and adjusting your user
            preferences in your account profile by checking or unchecking the
            relevant boxes or by sending us an email stating your request to
            policy@efficion.org{" "}
          </li>
        </ul>
        <p>
          We do not control third parties' collection or use of your information
          to serve interest-based advertising. However, these third parties may
          provide you with ways to choose not to have your information collected
          or used in this way. You can opt out of receiving targeted ads from
          members of the Network Advertising Initiative ("<strong>NAI</strong>")
          on the NAI's website.
        </p>
        <p>
          Residents in certain states, such as California, may have additional
          personal information rights and choices. Please see Your State Privacy
          Rights for more information.
        </p>
        <h1 className="text-[28px] py-8 font-Eina01-Semibold text-[#101828]">
          <u>Accessing and Correcting Your Personal Information</u>
        </h1>
        <p>
          You can review and change your personal information by logging into
          the App and visiting your account profile page.
        </p>
        <p>
          You may also send us an email at policy@efficion.org to request access
          to, correct, or delete any personal information that you have provided
          to us. We cannot delete your personal information except by also
          deleting your user account. We may not accommodate a request to change
          information if we believe the change would violate any law or legal
          requirement or cause the information to be incorrect.
        </p>
        <p>
          If you delete your User Contributions from the App, copies of your
          User Contributions may remain viewable in cached and archived pages or
          might have been copied or stored by other App users. Proper access and
          use of information provided on the App, including User Contributions,
          is governed by our terms of use. Residents in certain states, such as
          California, may have additional personal information rights and
          choices. Please see Your State Privacy Rights for more information.
        </p>
        <h1 className="text-[28px] py-8 font-Eina01-Semibold text-[#101828]">
          <u>Your State Privacy Rights</u>
        </h1>
        <p>
          State consumer privacy laws may provide their residents with
          additional rights regarding our use of their personal information.
          California's "Shine the Light" law (Civil Code Section § 1798.83)
          permits users of our App that are California residents to request
          certain information regarding our disclosure of personal information
          to third parties for their direct marketing purposes. To make such a
          request, please send an email to policy@efficion.org.
        </p>
        <p>
          Colorado, Connecticut, Virginia, and Utah each provide their state
          residents with rights to:
        </p>
        <p>Confirm whether we process their personal information.</p>
        <p>Access and delete certain personal information.</p>
        <h1 className="text-[28px] py-8 font-Eina01-Semibold text-[#101828]">
          Data portability.
        </h1>
        <ul className="list-disc list-inside p-8">
          <li>
            Opt-out of personal data processing for targeted advertising and
            sales.
          </li>
          <li>
            Colorado, Connecticut, and Virginia also provide their state
            residents with rights to:
          </li>
          <li>
            Correct inaccuracies in their personal information, taking into
            account the information's nature processing purpose.
          </li>
          <li>
            Opt-out of profiling in furtherance of decisions that produce legal
            or similarly significant effects.{" "}
          </li>
        </ul>
        <p>
          To exercise any of these rights please send an email to
          policy@efficion.org. To appeal a decision regarding a consumer rights
          request please send an email to policy@efficion.org with the subject
          heading “Appeal”.
        </p>
        <p>
          Nevada provides its residents with a limited right to opt-out of
          certa-0—0-in personal information sales. Residents who wish to
          exercise this sale opt-out rights may submit a request to this
          designated address: policy@efficion.org . However, please know we do
          not currently sell data triggering that statute's opt-out
          requirements.
        </p>
        <h1 className="text-[28px] py-8 font-Eina01-Semibold text-[#101828]">
          <u>Data Security</u>
        </h1>
        <p>
          We have implemented measures designed to secure your personal
          information from accidental loss and from unauthorized access, use,
          alteration, and disclosure. All information you provide to us is
          stored on our secure servers behind firewalls. Any payment
          transactions will be encrypted using SSL technology.
        </p>
        <p>
          The safety and security of your information also depends on you. Where
          we have given you (or where you have chosen) a password for access to
          certain parts of our App, you are responsible for keeping this
          password confidential. We ask you not to share your password with
          anyone.{" "}
        </p>
        <p>
          Unfortunately, the transmission of information via the internet and
          mobile platforms is not completely secure. Although we do our best to
          protect your personal information, we cannot guarantee the security of
          your personal information transmitted through our App. Any
          transmission of personal information is at your own risk. We are not
          responsible for circumvention of any privacy settings or security
          measures we provide.
        </p>
        <h1
          className="text-[28px] py-8 font-Eina01-Semibold text-[#101828]"
          id="change-privacy"
        >
          <u>Changes to Our Privacy Policy</u>
        </h1>
        <p>
          We may update our privacy policy from time to time. If we make
          material changes to how we treat our users' personal information, we
          will post the new privacy policy on this page [with a notice that the
          privacy policy has been updated and notify you by an in-App alert the
          first time you use the App after we make the change.
        </p>
        <p>
          The date the privacy policy was last revised is identified at the top
          of the page. You are responsible for ensuring we have an up-to-date
          active and deliverable policy@efficion.org for you and for
          periodically visiting this privacy policy to check for any changes.
        </p>
        <h1 className="text-[28px] py-8 font-Eina01-Semibold text-[#101828]">
          <u>Contact Information</u>
        </h1>
        <p>
          To ask questions or comment about this privacy policy and our privacy
          practices, contact us at: to policy@efficion.org.
        </p>
      </div>
    </Privacy>
  );
}

export default Policy;
