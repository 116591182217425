import React, { useState, useEffect } from "react";
import {
  post_message_icon,
  share_forward_icon,
} from "../../../constants/images";
import SharePost from "../SharePost";
import { useDisclosure } from "@chakra-ui/react";
import { AiOutlineHeart } from "react-icons/ai";
import { selectEmoticons, emotionsIcons } from "../../../constants/data";
import { BsThreeDots } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { likeEvent } from "../../../redux/posts/posts.action";
import { motion } from "framer-motion";

function UserPostShareHome({
  user,

  handle,

  shared,
  index,
  theme,
  selectedPost: { id, likedBy, likeCount, commentCount, title },
}) {
  const [like, setLike] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [liked, setLiked] = useState(false);
  const [emotions, setEmoticons] = useState("");
  const [likes, setLikes] = useState(0);
  const dispatch = useDispatch();
  const [emoticon, setEmoticon] = useState("");
  // const [open, setOpen] = useState(false);

  const postLike = (item) => {
    setLike(!like);
    let payload = { reactionType: item };

    dispatch(likeEvent(id, payload))
      .then((data) => {
        const likeStatus = getLiked(data.data.likedBy);
        // updateCounts(data.data.likeCount);
        if (likes >= 0) {
          if (likeStatus) {
            getEmoticons(data.data.likedBy);
            setLikes(data.data.likeCount);
          } else {
            if (likes >= 1) setLikes(data.data.likeCount);
            setEmoticons("");
          }
        }
      })
      .catch((err) => {});
  };

  const getLiked = (data) => {
    if (data && data.length === 0) return false;
    else if (data && data.find((item) => item.user === user.username))
      return true;
  };

  const getEmoticons = (data) => {
    // eslint-disable-next-line
    data?.map((each) => {
      if (each?.user === user?.username) {
        setEmoticons(each?.reaction);
      }
    });
  };

  const clickIcon = (item, e) => {
    e.stopPropagation();

    setEmoticon(item);
    postLike(item);
    setLike(!like);
  };

  const ownership = () => {
    if (likedBy && likedBy?.length === 0) setEmoticons("");
    else if (
      likedBy !== undefined &&
      likedBy?.find((item) => item.user === user?.username)
    ) {
      // eslint-disable-next-line
      likedBy?.map((each) => {
        if (each.user === user?.username) {
          setEmoticons(each.reaction);
          setLiked(true);
        }
      });
    } else setEmoticons("");
  };

  useEffect(() => {
    ownership();
    // eslint-disable-next-line
  }, [likedBy]);

  useEffect(() => {
    setLikes(likeCount);
    // eslint-disable-next-line
  }, [likedBy]);

  return (
    <div key={index}>
      {/* comment box */}
      <div
        className={`flex justify-between items-center pt-2 mt-[.5rem] ${
          theme ? "bg-kdark3 text-white" : "bg-[#fff] text-[#27272E]"
        } `}
      >
        <div className="flex">
          <div className="flex justify-between items-center">
            <div
              className={`flex items-center hover:bg-[#E5484D] hover:text-white ${
                theme
                  ? "bg-black text-white"
                  : "bg-[#F7F7F7] px-4 py-1 rounded-[25px]  text-[#27272E]"
              } `}
            >
              <div
                className="flex w-full  group relative items-center cursor-pointer "
                // onClick={() => handle()}
              >
                <button
                  class={`invisible group-hover:visible
            absolute h-[50px] left-0 bottom-4 pb-2 
            ml-0 flex justify-around  items-center rounded-[15px] cursor-pointer w-[200px] ${
              theme ? "bg-kdark3 text-white" : "bg-[#fff] text-[#27272E]"
            }  `}
                >
                  {emotionsIcons?.map((item) => (
                    <motion.img
                      initial={{ x: -100 }}
                      animate={{ x: 0 }}
                      exit={{ x: -100 }}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      src={item?.icon}
                      alt={item?.name}
                      className="object-contain w-[13%]"
                      onClick={(e) => clickIcon(item?.name, e)}
                    />
                  ))}
                </button>

                {like ? (
                  selectEmoticons(emoticon)
                ) : liked ? (
                  <span onClick={(e) => clickIcon("", e)}>
                    {selectEmoticons(emotions)}
                  </span>
                ) : (
                  <AiOutlineHeart
                    className=""
                    size={24}
                    // onClick={(e) => clickIcon("love", e)}
                  />
                )}
              </div>
              <div className="ml-2">{likes} </div>
            </div>
            <div
              onClick={() => handle()}
              className={`flex items-center ml-6 cursor-pointer px-[.7rem] py-[.2rem] rounded-[20px]  ${
                theme ? "bg-black text-white" : " text-[#27272E]"
              } `}
            >
              <img src={post_message_icon} alt="message" />
              <span className="ml-[6px]">{commentCount}</span>
            </div>
          </div>
          {/* share */}
          <div
            onClick={onOpen}
            className={`flex ml-[1rem] justify-center items-center  rounded-[21px] w-[124px] h-[40px] ${
              theme ? "bg-black text-white" : " text-[#27272E]"
            } `}
          >
            <img src={share_forward_icon} alt="forward" />
            <span className="ml-[7px]">Share({shared ? shared : 0})</span>
          </div>
        </div>

        <div onClick={() => handle()}>
          <BsThreeDots size={24} />
        </div>
      </div>
      {
        <SharePost
          theme={theme}
          isOpen={isOpen}
          onClose={onClose}
          id={id}
          title={title}
        />
      }
    </div>
  );
}

export default UserPostShareHome;
