import React from "react";
import GeneralHeader from "../../components/GeneralHeader/GeneralHeader";
import { aboutFeatures, ltr, rtl } from "../../constants/data";
import GeneralFooter from "../../components/GeneralFooter/GeneralFooter";
import AboutFeature from "../../components/ProductFeature/AboutFeature";
import Slide from "../../components/Slide/Slide";

const About = () => {
  return (
    <div>
      <section className="flex flex-col bg-about-bg bg-no-repeat bg-cover h-screen relative">
        <div className="z-20">
          <GeneralHeader />
        </div>
        <div className="flex flex-col justify-center items-center flex-1">
          <div className="absolute inset-0 bg-[#000000] opacity-50"></div>
          <div className="relative z-10">
            <div className="text-white text-center md:text-7xl text-4xl font-normal mt-7">
              <p className="font-Eina01-Bold">Fostering a safer</p>
              <p className="font-Eina01-Bold mt-4">Community for all</p>
            </div>

            <div className="font-normal	font-SF-Pro-Regular text-base text-[#EBEBEF] text-center mt-6 mb-10">
              We believe that stronger communities are safer communities. We
              live in a world <br />
              where people can access information quickly, share effortlessly,
              and connect easily
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 md:py-32 px-4 md:px-32 space-y-4 text-center">
        <p className="font-normal	text-3xl md:text-5xl font-Eina01-Bold w-full">
          What is Aradugbo?
        </p>
        <p className="text-sm md:text-xl w-full font-Eina01-Regular">
          The Aradugbo app provides a way for people to connect with each other
          via instant notification, live streaming, local and systemwide
          broadcasting and instantaneous reporting of emergency situations or
          real time events with the hope of involving community members in
          facilitating rescue efforts by informing first responders and local
          authorities. The platform allows users to share information about
          crimes, suspicious activity , fire outbreak, or other emergency
          situation on the app. It also allows users to seek help from other
          users on the app within close proximity to the reported incidents.
          Users can also share safety tips from experts, authorities and first
          responders to assist those in an emergency.
        </p>
      </section>

      <section className="py-12 md:py-28 px-4 md:px-32 bg-[#F9F9FB]">
        <div className="flex flex-col md:flex-row md:space-x-14">
          {aboutFeatures.map((feature, index) => (
            <AboutFeature
              key={index}
              title={feature.title}
              shortDescription={feature.shortDescription}
              icon={feature.icon}
            />
          ))}
        </div>
      </section>

      <section className="py-12 md:py-32 px-4 md:px-32 space-y-4 text-center">
        <p className="font-normal	text-3xl md:text-5xl font-Eina01-Bold w-full">
          Our Mission
        </p>
        <p className="text-sm md:text-xl font-Eina01-Regular w-full">
          Our aim is to facilitate a system for reporting and seeking emergency
          response from local authorities and members of a local community in
          close proximity to the reported events. Our aim is to also alert
          members of the community of real time events occurring within a
          particular locality. Users of the app may subscribe to receive alerts
          for events occurring within a particular geographical location.
        </p>
      </section>

      <section className="py-12 md:py-28 w-full overflow-hidden">
        <Slide data={ltr} right={false} />
        <Slide data={rtl} right={true} />
      </section>

      <GeneralFooter />
    </div>
  );
};

export default About;
