import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { dayToggle, nightToggle } from "../../constants/images";
import { setThemeAction } from "../../redux/features/features.action";

function ThemeButton() {
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const dispatch = useDispatch();
  const handleThemeSetting = () => {
    dispatch(setThemeAction());
  };

  return (
    <div
      className="fixed top-[85%] right-[10%] z-[998] cursor-pointer"
      onClick={handleThemeSetting}
    >
      {darktheme ? (
        <img src={dayToggle} className="w-10 h-10" alt="light mode" />
      ) : (
        <img src={nightToggle} className="w-10 h-10" alt="dark mode" />
      )}
    </div>
  );
}

export default ThemeButton;
