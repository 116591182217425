import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSignInUserByUsernameAndEmail } from "../../../../redux/auth/auth.actions";

import InputField from "../../../InputField";
import Loader from "../../../Loader";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { toast } from "react-toastify";

function WithEmail({ handleloginStep }) {
  const isLoading = useSelector((state) => state.auth.emailLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const [signInData, setSignInData] = useState({
    email: "",
    password: "",
  });

  const changeSignInData = (e) => {
    setSignInData({
      ...signInData,
      [e.target.name]: e.target.value,
    });
    if (signInData?.email) {
      if (isValidEmail(signInData.email) === false) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
    if (signInData.password === "" || isValid === false) {
      setIsDisabled(true);
    }
  };

  const handleSignIn = (e) => {
    e.preventDefault();

    if (
      signInData.email === "" ||
      isValidEmail(signInData.email) === false ||
      signInData.password === ""
    ) {
      setIsValid(false);
      toast.error("Please fill in the form");
    } else {
      dispatch(setSignInUserByUsernameAndEmail(signInData, navigate));
    }
  };

  const isValidEmail = (email) => {
    // Regular expression pattern for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <form className="w-full" onSubmit={handleSignIn}>
      <div>
        <InputField
          label={"Email Address"}
          name="email"
          value={signInData.email}
          placeholder="e.g Anthonysam@gmail.com"
          changeValue={(e) => changeSignInData(e)}
        />
        {!isValid && (
          <span className="mt-[15px] text-[red] text-[14px]">
            Invalid email address
          </span>
        )}
      </div>
      <div className="mt-[18px]">
        <label className="mb-[15px] text-[#718096] text-[14px]">Password</label>
        <div className="flex w-full h-[54px] border pr-[18px] border-[#CBD5E0] justify-between rounded-[4px] items-center">
          <input
            name="password"
            type={showPassword ? "text" : "password"}
            className="w-full h-full border-none outline-none px-[22px] rounded-[4px]"
            value={signInData.password}
            placeholder="Password"
            onChange={(e) => changeSignInData(e)}
          />
          {showPassword ? (
            <FiEye
              className="cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
              color="#718096"
            />
          ) : (
            <FiEyeOff
              className="cursor-pointer"
              color="#718096"
              onClick={() => setShowPassword(!showPassword)}
            />
          )}
        </div>
        <div
          className="text-[14px] text-[#a1a3a3] cursor-pointer"
          onClick={() => handleloginStep(4)}
        >
          Forgotten My Password?
        </div>
      </div>

      <div
        className={`flex justify-center items-center w-full h-[48px] mt-[21px] cursor-pointer rounded-[5px]  text-white ${
          isDisabled && isValid === false ? "bg-[#cac6c6]" : "bg-[#F04438]"
        }`}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <button
            type="submit"
            className="w-full  h-full"
            disabled={isDisabled && isValid === false ? true : false}
          >
            Continue
          </button>
        )}
      </div>
    </form>
  );
}

export default WithEmail;
