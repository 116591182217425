import React, { useState } from "react";

import { useSelector } from "react-redux";
const mapState = (state) => ({
  posts: state.posts.posts,
  darktheme: state.features.settings.darktheme,
  isLoading: state.features.isLoading,
});

function Search({ title, handleSubscription }) {
  const [email, setEmail] = useState("");
  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const submitting = (event) => {
    event.preventDefault();
    handleSubscription(email);
  };
  const { darktheme, isLoading } = useSelector(mapState);
  return (
    <form
      className="flex flex-col sm:flex-row items-center"
      onSubmit={submitting}
    >
      <input
        className={`w-[300px] outline-none sm:w-[360px] h-[48px] rounded-[100px] ${
          !darktheme ? "border border-[#D0D5DD]" : "border-none"
        } bg-white text-black pl-[14px]`}
        type="email"
        required
        placeholder="Enter your email"
        value={email}
        onChange={(event) => handleChange(event)}
      />
      <button
        type="submit"
        className="subscribe_btn flex justify-center items-center mt-3 sm:mt-0 ml-0 sm:ml-4"
      >
        {isLoading ? "Please wait!!!" : title}
      </button>
    </form>
  );
}

export default Search;
