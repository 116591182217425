import React from "react";
import { useNavigate } from "react-router-dom";
import { RiWirelessChargingFill } from "react-icons/ri";
import { FiEye } from "react-icons/fi";

function UserCard({ item }) {
  const navigate = useNavigate();

  const view = (roomId) => {
    navigate(`/dashboard/livestream/stream/${roomId}`);
  };

  return (
    <div className="w-full  flex">
      {item?.map((stream, i) => (
        <div
          className="flexflex-col cursor-pointer"
          onClick={() => view(stream?.roomId)}
          key={i}
        >
          <div className="relative w-[64px] h-[64px] border-[1px] border-[red] rounded-full">
            <img
              className="w-[full] h-[ful] object-cover rounded-full"
              src={stream?.host?.imageUrl}
              alt={stream?.title}
            />
            <span className="bg-red-500 flex items-center text-white absolute right-2 text-[.5rem] bottom-1 w-[15px] h-[15px] py-[.1rem] px-[.2rem] rounded-[50%]">
              {stream?.liveStatus === 1 ? (
                <RiWirelessChargingFill className="mr-" />
              ) : (
                <FiEye className="mr-" />
              )}
            </span>
          </div>
          <p className=" overflow-hidden truncate w-[50%] text-center cursor-pointer">
            {stream?.host?.displayName || stream?.host?.username}
          </p>
        </div>
      ))}
    </div>
  );
}

export default UserCard;
