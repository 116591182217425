import React, { useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const GooglePlace = ({ propsValue }) => {
  const [value] = useState(null);
  const API_KEY = "AIzaSyBvsPfW6EzKwgkLFsn9wQMg07ms8iFzhls";

  const handlePlaceSelect = (place) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${place.label}&key=${API_KEY}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.results && data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          propsValue({ latitude: lat, longitude: lng }, place.label);
        } else {
          console.error(
            "Unable to retrieve coordinates for the selected place."
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey="AIzaSyBvsPfW6EzKwgkLFsn9wQMg07ms8iFzhls"
        nearbyPlacesAPI="GooglePlacesSearch"
        enablePoweredByContainer={false}
        fetchDetails={true}
        selectProps={{
          value,
          onChange: handlePlaceSelect,
        }}
      />
    </div>
  );
};

export default GooglePlace;
