import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getUser,
  setChangePasswordAction,
} from "../../../redux/auth/auth.actions";
import InputField from "../../InputField";
import Loader from "../../Loader";

function ChangePassword({ isOpen, onClose }) {
  const loading = useSelector((state) => state?.auth?.loading);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();

  const handleSumbitPassword = (e) => {
    e.preventDefault();
    if (newPassword === "" || confirmPassword === "") {
      toast.error("Fill all fields");
    } else {
      let obj = {
        currentPassword: password,
        newPassword: newPassword,
        confirmedPassword: confirmPassword,
      };
      dispatch(setChangePasswordAction(obj));
      onClose();
    }
  };

  useEffect(() => {
    dispatch(getUser());
    // eslint-disable-next-line
  }, []);
  return (
    <Modal className="w-full" isOpen={isOpen} onClose={onClose} size={"xl"}>
      <ModalOverlay />
      <ModalContent
        className="w-full h-[600px] overflow-hidden "
        containerProps={{ justifyContent: "center" }}
      >
        <ModalHeader color="#2B2B2B" fontWeight={700} fontSize="21px">
          <div className="w-full flex justify-center">Change password</div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form className="w-full">
            <div>
              <InputField
                label={"Old Password"}
                name="password"
                value={password}
                changeValue={(e) => setPassword(e.target.value)}
                placeholder="*********"
              />
            </div>
            <div className="mt-[18px]">
              <InputField
                label={"New Password"}
                name="newPassword"
                value={newPassword}
                placeholder="*********"
                changeValue={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="mt-[18px]">
              <InputField
                label={"Confirm Password"}
                name="confirmPassword"
                value={confirmPassword}
                placeholder="*********"
                changeValue={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <div
            className={`flex justify-center items-center w-full h-[48px] mt-[21px] rounded-[5px] text-white ${
              newPassword === confirmPassword &&
              confirmPassword?.length !== 0 &&
              newPassword?.length !== 0
                ? "bg-[#F04438]"
                : "bg-[#D0D5DD]"
            }`}
          >
            {loading ? (
              <Loader />
            ) : (
              <button
                onClick={handleSumbitPassword}
                disabled={
                  newPassword === confirmPassword &&
                  confirmPassword?.length !== 0 &&
                  newPassword?.length !== 0
                    ? false
                    : true
                }
                className="w-full h-full cursor-pointer border-none outline-none"
              >
                Change Password
              </button>
            )}
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ChangePassword;
