import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setSignUpUserByUsernameAndEmail } from "../../../redux/auth/auth.actions";
import Loader from "../../Loader";
import { FiEyeOff, FiEye } from "react-icons/fi";

function SignUpEmail({ handleSignUpStep }) {
  const isLoading = useSelector((state) => state.auth.emailUpLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signUpData, setSignUpData] = useState({
    fullname: "",
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleSignUpWithEmail = (e) => {
    e.preventDefault();
    if (
      signUpData.fullname === "" ||
      signUpData.email === "" ||
      signUpData.password === ""
    ) {
      toast.error("Please fill in all fields.");
    } else {
      dispatch(
        setSignUpUserByUsernameAndEmail(
          {
            email: signUpData.email,
            password: signUpData.password,
            username: signUpData.fullname,
          },
          handleSignUpStep,
          navigate
        )
      );
    }

    // handleSignUpStep(2);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSignUpData({
      ...signUpData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      {/* {signUp && (
        <div className="w-full h-[40px] flex justify-center items-center bg-[rgb(230,255,243)]">
          <AiOutlineInfoCircle />
          <span>Please confirm the verification link on your email</span>
        </div>
      )} */}
      <form className="w-full" onSubmit={handleSignUpWithEmail}>
        <div>
          <label className="">Full Name</label>
          <div className="w-full mt-[.5rem] flex h-[54px] border border-[#CBD5E0] justify-between items-center ">
            <input
              name="fullname"
              className="w-full border-none outline-none h-full px-[22px]"
              value={signUpData.fullName}
              placeholder="e.g Anthony Samuel"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="mt-[18px]">
          <label className="mb-[15px]">Email Address</label>
          <div className="w-full flex  mt-[.5rem] h-[54px] border border-[#CBD5E0] justify-between items-center ">
            <input
              name="email"
              className="w-full border-none outline-none h-full px-[22px]"
              value={signUpData.email}
              placeholder="e.g Anthonysam@gmail.com"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        {/* <div className='mt-[18px]'>
          <label className='mb-[15px]'>Phone</label>
          <div className="flex h-[54px] border border-[#CBD5E0] justify-between items-center ">
            <input name="phoneNumber" className='px-[22px]' value="" placeholder="Phone number" />
          </div>
        </div> */}
        <div className="mt-[18px]">
          <label className="mb-[15px]">Password</label>
          <div className=" mt-[.5rem] pr-2 w-full flex h-[54px] border border-[#CBD5E0] justify-between items-center ">
            <input
              name="password"
              className="w-full border-none outline-none h-full px-[22px]"
              value={signUpData.password}
              placeholder="Password"
              onChange={(e) => handleChange(e)}
            />
            {showPassword ? (
              <FiEye
                className="cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
                color="#718096"
              />
            ) : (
              <FiEyeOff
                className="cursor-pointer"
                color="#718096"
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
          </div>
        </div>
        <div className="flex justify-center items-center w-full h-[48px] mt-[21px] rounded-[5px] text-white bg-[#F04438]">
          {isLoading ? (
            <Loader />
          ) : (
            <button
              type="submit"
              className="border-none outline-none cursor-pointer"
            >
              Continue
            </button>
          )}
        </div>
      </form>
    </>
  );
}

export default SignUpEmail;
