import dayjs from "dayjs";
import React, { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAroundAction,
  getMoreData,
} from "../../../redux/alert/alert.actions";
import { setIsLoading } from "../../../redux/features/features.action";
import { viewSinglePost } from "../../../redux/posts/posts.action";
import { getDistanceFromLatLonInKm } from "../../../utils/utils";
import Loader from "../../Loader";

function AroundYou() {
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const token = useSelector((state) => state.auth.accessToken);

  const { AroundData, index, hasMore, isLoading } = useSelector(
    (state) => state.alert
  );

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(getAllAroundAction());
    // eslint-disable-next-line
  }, [index]);

  const getMoreAroundData = () => {
    dispatch(getMoreData());
  };

  const handleSinglePostModal = (post) => {
    if (token) {
      if (user.suspend) {
        // setSuspended(true);
      } else {
        dispatch(
          viewSinglePost({
            postId: post?.id,
            modalId: 10,
            modalActive: true,
          })
        );
      }
    } else {
      // navigate("/login");
    }
  };

  return (
    <div
      className={`w-full pt-[1rem]  ${darktheme ? "bg-kdark3" : "bg-[#fff]"}`}
    >
      <InfiniteScroll
        dataLength={AroundData.length}
        next={getMoreAroundData}
        hasMore={hasMore}
        // loader={<div className="text-center">Loading ...</div>}
      >
        {isLoading ? (
          <div className="w-full mt-[20px] flex justify-center">
            <Loader />
          </div>
        ) : AroundData?.length > 0 ? (
          AroundData.map((data, index) => {
            return (
              <div
                className="w-full mb-[33px]"
                key={index}
                onClick={() => handleSinglePostModal(data)}
              >
                <div className="max-w-100%  mx-auto px-[20px] flex justify-between items-center">
                  <div className="w-[70%]">
                    <span className="text-[#425466] text-kbody7">
                      {" "}
                      {dayjs(data?.createdAt).fromNow()}
                    </span>
                    <h4 className="text-[#27272E]  text-wrap  text-[13px]  font-normal my-2 ">
                      {data.title}
                    </h4>
                    <div className="flex w-[90%] ">
                      <p className="  text-[#34A853] font-bold mr-2 rounded-[20px] bg-[rgba(52,168,83,0.075)]  px-[1rem] text-[.5rem] py-[.5rem]">
                        {data.category}
                      </p>
                      <p
                        className={`  text-[#F16063] rounded-[20px] font-bold ${
                          darktheme ? "bg-[#681d1d92]" : "bg-[#FFF0F1]"
                        } text-[.5rem] py-[.5rem] px-4`}
                      >
                        {getDistanceFromLatLonInKm(
                          user?.coords?.latitude,
                          user?.coords?.longitude,
                          data?.coords?.latitude,
                          data?.coords?.longitude
                        )}{" "}
                        kms away
                      </p>
                    </div>
                  </div>
                  {/* image */}
                  <div className="rounded-[12px] w-[28%]  h-[100px] ">
                    <img
                      src={data.media.images[0]}
                      alt="post pics"
                      className="w-full h-full object-cover rounded-[12px]"
                    />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="mx-auto w-[80%] text-center shadow-sm py-[2rem]">
            <p className="text-xl "> There are no activities around You.</p>
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
}

export default AroundYou;
