import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Join from "../../components/Join/Join";
import Notification from "../../components/Notification/Notification";
import { fetchAllPosts } from "../../redux/posts/posts.action";
import "./Blog.css";
import { useState } from "react";
import ThemeButton from "../../components/ThemeButton/ThemeButton";

const mapState = (state) => ({
  posts: state.posts.posts,
  darktheme: state.features.settings.darktheme,
});

function Blog() {
  const [, setCurrentItems] = useState([]);
  const [, setPageCount] = useState(0);
  const [itemOffset] = useState(0);
  const itemsPerPage = 6;
  const { posts, darktheme } = useSelector(mapState);
  const dispatch = useDispatch();
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    onTop();
  }, [routePath]);

  useEffect(() => {
    dispatch(fetchAllPosts());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(posts?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(posts?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, posts]);

  return (
    <div className="w-full">
      {/* <FixedNav /> */}
      <Notification />
      <div
        className={`relative max-w-[1920px] overflow-x-hidden h-full ${
          darktheme ? "bg-black text-white" : "bg-white text-[#101828]"
        } `}
      >
        <ThemeButton />
        <Header />
        <div className="max-w-[1240px] m-auto mt-[1px]  px-4 md:px-[0px] text-white">
          <div className="header-text mt-[96px] flex flex-col items-center text-center sm:text-left">
            <h6
              className={`text-kbody5 ${
                darktheme ? "text-[#98A2B3]" : "text-[#F97066]"
              }`}
            >
              Our blog
            </h6>
            <h2
              className={`text-[1.5rem] sm:mx-4 sm:text-[3rem] md:text-[3rem] leading-[2rem]  md:leading-[3.3rem] ${
                darktheme ? "text-white" : "text-[#101828]"
              }`}
            >
              Coming Soon
            </h2>
            <p
              className={`mt-[10px] text-kfontm ${
                darktheme ? "text-[#EAECF0]" : "text-[#475467]"
              }`}
            >
              In the meantime, Sign up for our monthly newletter to stay up to
              date.
            </p>
            <div className="mt-10">
              <div>
                <form className="flex flex-col sm:flex-row items-center">
                  <input
                    className={`w-[300px] outline-none sm:w-[360px] h-[48px] rounded-[100px] ${
                      !darktheme ? "border border-[#D0D5DD]" : "border-none"
                    } bg-white text-black pl-[14px]`}
                    placeholder="Enter your email"
                  />
                  <div className="subscribe_btn flex justify-center items-center mt-3 sm:mt-0 ml-0 sm:ml-4">
                    Get started
                  </div>
                </form>
              </div>
              <div
                className={`${
                  !darktheme ? "text-[#475467]" : "text-[#EAECF0]"
                } text-kbody6 font-normal`}
              >
                We care about your data in our{" "}
                <Link className="underline" to="/legal/privacy-policy">
                  Privacy Policy
                </Link>
                .
              </div>
            </div>
          </div>
          {/* <div>
            <div className="mt-[96px]">
              <img src={blog_bg} alt="main blog post" />
            </div>
            <div className="text-[#F97066] text-kbody6 mt-8">
              Olivia Rhye • 20 Jan 2022
            </div>
            <div className="flex justify-between">
              <div>
                <div className="">
                  <span
                    className={`${!darktheme && "text-[#101828]"} text-kbody3`}
                  >
                    UX review presentations
                  </span>
                </div>
                <div
                  className={`${
                    darktheme ? "text-[#EAECF0]" : "text-[#475467]"
                  } text-kbody5`}
                >
                  How do you create compelling presentations that wow your
                  colleagues and impress your managers?
                </div>
              </div>
              <div>
                <img
                  src={arrowup_icon}
                  alt="arrowup icon"
                  className="w-full h-full"
                />
              </div>
            </div>
          </div> */}
          {/* <div className="mb-[60px]">
            <div className="flex justify-center sm:justify-between  flex-wrap mt-[66px]">
              {currentItems && currentItems.length > 0 ? (
                currentItems.map((post, index) => {
                  return <Card post={post} key={index} />;
                })
              ) : (
                <div>loading ...</div>
              )}
            </div>
            <div
              className={`w-full h-[1px] ${
                darktheme ? "bg-white" : "bg-black"
              } text-white mb-5`}
            ></div>
            <ReactPaginate
              breakLabel="..."
              nextLabel={<NextLabel darktheme={darktheme} />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel={<PreviousLabel darktheme={darktheme} />}
              renderOnZeroPageCount={null}
              containerClassName={`pagination ${darktheme ? "dark" : "light"}`}
              pageClassName="page-num"
              pageLinkClassName="page-num"
              previousClassName="previous"
              nextClassName="next"
              activeLinkClassName="active"
            />
          </div> */}
        </div>
        <div className="mb-[100px] text-white">
          <Join />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Blog;
