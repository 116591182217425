import { featuresType } from "./features.type"

export const setThemeAction = () => async(dispatch) => {
    dispatch({
        type: featuresType.SET_THEME
    })
}

export const setFixAction = (payload) => async(dispatch) => {
    dispatch({
        type: featuresType.FIX_NAV,
        payload
    })
}


export const setDashboardSidebarAction = (payload) => async(dispatch) => {
    dispatch({
        type: featuresType.SET_DASHBOARD_SIDEBAR,
        payload
    })
}

export const setShowSideMenuAction = (payload) => async(dispatch) => {
    dispatch({
        type: featuresType.SHOW_SIDE_MENU,
        payload
    })
}

export const setScreenSizeAction = (payload) => async(dispatch) => {
    dispatch({
        type: featuresType.SET_SCREEN_SIZE,
        payload
    })
}

export const setModalAction = (payload) => async(dispatch) => {
    dispatch({
        type: featuresType.OPEN_MODAL,
        payload
    })
}

export const setIsLoading = (payload) => async(dispatch) => {
    dispatch({
        type: featuresType.IS_LOADING,
        payload
    })
}