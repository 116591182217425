import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./config";
import { FacebookAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage(app);
export const provider = new GoogleAuthProvider();

export const Appleprovider = new OAuthProvider("apple.com");
Appleprovider.addScope("email");
Appleprovider.addScope("name");

export const Facebookprovider = new FacebookAuthProvider();
