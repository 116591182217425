import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BiMessageCheck } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Support({ isOpen, onClose }) {
  const navigate = useNavigate();
  const [accountModalId, setAccountModalId] = useState(0);
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const [, setReason] = useState("");
  const [disable, setDisabled] = useState(true);

  const view = (path) => {
    navigate(`/dashboard/account/${path}`);
  };

  const handleAccountModal = (id) => {
    setAccountModalId(id);
    // onOpen();
  };

  const change = (e) => {
    if (e.target.value.length === 3) {
      setDisabled(true);
      setReason(e.target.value);
    } else if (e.target.value.length > 0) {
      setReason(e.target.value);
      setDisabled(false);
    }
  };

  useEffect(() => {
    setAccountModalId(0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setAccountModalId(0);
    onClose();
  };

  const close = () => {
    onClose();
    setAccountModalId(0);
  };
  return (
    <>
      <Modal className="w-full" isOpen={isOpen} onClose={close} size={"md"}>
        <ModalOverlay />
        <ModalContent
          className=" overflow-hidden "
          containerProps={{ justifyContent: "center" }}
        >
          <ModalHeader color="#2B2B2B">
            {accountModalId === 7 ? (
              <h5 className="w-full flex justify-center">Report a problem</h5>
            ) : (
              <h5 className="w-full flex justify-center">Help and Support</h5>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {accountModalId === 7 ? (
              <div>
                <div className="py-3 p-2 pr-[rem] cursor-pointer">
                  <h3>What are you having an issue with?</h3>
                  <select
                    type="text"
                    name="reason"
                    onChange={(e) => change(e)}
                    className="w-[100%] mt-[1rem] px-[1rem] py-[.6rem] border rounded-[6px]  border-[#D0D5DD]"
                  >
                    <option>Creating & Verifying your account</option>
                  </select>
                </div>
                <div className="py-3 p-2 pr-[rem] cursor-pointer">
                  <h3>Tell us more</h3>
                  <textarea
                    type="text"
                    name="reason"
                    placeholder="Give us more details on what the problem is about."
                    onChange={(e) => change(e)}
                    className="w-[100%] mt-[1rem] px-[1rem] py-[.5rem] h-[100px] rounded-[6px] border border-[#D0D5DD]"
                  />
                </div>
                <button
                  className={`flex justify-center items-center w-full h-[48px] mt-[21px] mb-[1rem] rounded-[5px] text-white  ${
                    disable ? "bg-[#dad9d9]" : "bg-pry"
                  }`}
                  onClick={(e) => handleSubmit(e)}
                >
                  Submit
                </button>
              </div>
            ) : (
              <div className="py-3 pl-2 pr-[3rem] cursor-pointer">
                <div
                  className="flex items-center mb-[1rem] "
                  onClick={() => view("support")}
                >
                  <div>
                    {" "}
                    <BiMessageCheck className="text-5xl bg-[#F2F4F7] p-2 rounded-full mr-[1rem] " />
                  </div>
                  <div>
                    <h5>Help Center</h5>
                    <p>Browse our guide docs and FAQs</p>
                  </div>
                </div>
                <div className="flex items-center mb-[1rem]">
                  <div>
                    {" "}
                    <HiOutlineMail className="text-5xl bg-[#F2F4F7] p-2 rounded-full mr-[1rem] " />
                  </div>
                  <div>
                    <h5>Support</h5>
                    <p>Get help directly from our support agents</p>
                  </div>
                </div>
                <div
                  className="flex items-center mb-[1rem]"
                  onClick={() => handleAccountModal(7)}
                >
                  <div>
                    {" "}
                    <AiOutlineInfoCircle className="text-5xl bg-[#F2F4F7] p-2 rounded-full mr-[1rem] " />
                  </div>
                  <div>
                    <h5>Report a problem</h5>
                    <p>Send a complaint to us</p>
                  </div>
                </div>
              </div>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Support;
