import { peoplePostTypes } from "./peoplepost.types";

const initialState = {
  peoplePosts: [],
  peoplePost: {},
  message: "",
  hasMore: true,
  index: 1,
  error: false,
  loading: false,
  comment: {},
};

export const peoplePostReducer = (state = initialState, action) => {
  switch (action.type) {
    case peoplePostTypes.FETCH_PEOPLE_POSTS:
      return {
        ...state,
        peoplePosts: action.payload,
      };
    case peoplePostTypes.FETCH_PEOPLE_POST_START:
      return {
        ...state,
      };
    case peoplePostTypes.FETCH_PEOPLE_POST:
      return {
        ...state,
        peoplePost: action.payload,
      };
    case peoplePostTypes.FETCH_PEOPLE_POST_ERROR:
      return {
        ...state,
      };
    case peoplePostTypes.POST_COMMENT_START:
      return {
        ...state,
        loading: false,
      };
    case peoplePostTypes.POST_COMMENT:
      return {
        ...state,
        loading: true,
        comment: action.payload,
      };
    case peoplePostTypes.POST_COMMENT_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
