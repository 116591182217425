import React from "react";

import { Route, Routes } from "react-router-dom";
import Explore from "../../components/Explore/Explore";
import Livestream from "../../components/Livestream/Livestream";
import Alert from "../../components/AlertComponent/Alert";
import Account from "../../components/AccountComponent/Account";
import DashboardHome from "../../components/DashboardHome/DashboardHome";
import AllLivestream from "../../components/Livestream/viewAllLive";
import Viewstream from "../../components/Livestream/Viewstream";
import Guide from "../../components/AccountComponent/SupportHelp/Guide";
import ViewSingleUserPost from "../../components/SingleUserPost/ViewSharedPost";
import Profile from "../../components/Profile";
import PostUpload from "../../components/Upload/Upload";

function Dashboard() {
  return (
    <>
      <Routes>
        <Route path="explore" element={<Explore />} />
        <Route path="home" element={<DashboardHome />} />
        <Route path="search" exact element={<DashboardHome />} />
        <Route path="alert" exact element={<DashboardHome />} />
        <Route exact path="events/:id" element={<ViewSingleUserPost />} />
        <Route path="livestream" element={<AllLivestream />} />
        <Route path="my-profile" element={<Profile />} />
        <Route path="events/uploads" element={<PostUpload />} />
        <Route exact path="livestream/:name" element={<Livestream />} />
        <Route exact path="livestream/stream/:name" element={<Viewstream />} />
        <Route exact path="account/support" element={<Guide />} />
        <Route exact path="alert" element={<Alert />} />
        <Route exact path="profile" element={<Profile />} />
        <Route exact path="account" element={<Account />} />
      </Routes>
    </>
  );
}

export default Dashboard;
