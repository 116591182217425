import { featuresType } from "./features.type";

const initialState = {
  settings: {
    darktheme: false,
    fix: false,
  },
  dashboardSidebar: true,
  showSideMenu: true,
  screenSize: false,
  modal: false,
  isLoading: false,
};
export const featuresReducer = (state = initialState, action) => {
  switch (action.type) {
    case featuresType.SET_THEME:
      return {
        ...state,
        settings: {
          ...state.settings,
          darktheme: !state.settings.darktheme,
        },
      };
    case featuresType.FIX_NAV:
      return {
        ...state,
        settings: {
          ...state.settings,
          fix: action.payload,
        },
      };
    case featuresType.SET_DASHBOARD_SIDEBAR:
      return {
        ...state,
        dashboardSidebar: action.payload,
      };
    case featuresType.SHOW_SIDE_MENU:
      return {
        ...state,
        showSideMenu: action.payload,
      };
    case featuresType.SET_SCREEN_SIZE:
      return {
        ...state,
        screenSize: action.payload,
      };
    case featuresType.OPEN_MODAL:
      return {
        ...state,
        modal: action.payload,
      };
    case featuresType.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};
