import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Activity from "./Activity/Activity";
import AroundYou from "./AroundYou/AroundYou";

function Alert() {
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const AroundData = useSelector((state) => state.alert.AroundData);

  const [alertTab, setAlertTab] = useState(1);

  useEffect(() => {}, []);

  return (
    <div className={`w-full h-full   ${darktheme ? "bg-kdark3" : "bg-[#fff]"}`}>
      <div
        className={`w-full pt-[74px] ${darktheme ? "bg-kdark3" : "bg-[#fff]"}`}
      >
        <div className="w-full flex justify-center border-b border-[#F5F5F5] font-semibold text-kbody6">
          <div
            className={`flex justify-center cursor-pointer pb-[14px] w-[49%]  ${
              alertTab === 1 ? "border-b-[3px] border-[#F97066]" : "border-none"
            }`}
            onClick={() => setAlertTab(1)}
          >
            <span>Around You</span>
            {AroundData.length > 0 && (
              <div className="w-[20px] h-[20px] rounded-[16px] bg-[#F97066] text-[.6rem] flex justify-center items-center text-white ml-2">
                {AroundData?.length}
              </div>
            )}
          </div>
          <div
            className={`flex justify-center cursor-pointer pb-[14px] w-[262px] ${
              alertTab === 2 ? "border-b-[3px] border-[#F97066]" : "border-none"
            }`}
            onClick={() => setAlertTab(2)}
          >
            Activity
          </div>
        </div>
        {alertTab === 1 && <AroundYou />}
        {alertTab === 2 && <Activity />}
      </div>
    </div>
  );
}

export default Alert;
