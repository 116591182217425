import Dashboard from "./Dashboard";

export const PrivateRoute = () => {
  return (
    <>
      {" "}
      <Dashboard />{" "}
    </>
  );
};
