import React from "react";
import Logo from "../Logo/Logo";
import Search from "../Search/Search";
import Footer from "../Footer/Footer";
import { woman_hand } from "../../constants/images";
import { setSubscribeToComingSoon } from "../../redux/auth/auth.actions";
import { useDispatch } from "react-redux";

function ComingSoon() {
  const dispatch = useDispatch();

  const handleSubscribeToComingSoon = (email) => {
    dispatch(setSubscribeToComingSoon(email));
  };
  return (
    <div className="min-h-screen bg-market-bg lg:pt-16 pt-8">
      <Logo containerStyle="lg:ml-20 ml-10" />
      <h1 className="lg:ml-20 ml-10 lg:mt-20 mt-10 text-white">Coming Soon</h1>
      <div className="flex lg:flex-row flex-col text-white">
        <div className="lg:w-1/2 lg:px-20 px-10">
          <h1 className="lg:font-bold font-bold lg:text-6xl text-3xl my-5">
            Get notified when <br />
            we launch
          </h1>
          <p className="lg:pr-24 mb-10">
            Aradugbo gives you real-time alerts and updates on emergencies and
            happenings in your area so you can stay safe. Stay safe and know
            first - with Aradugbo.
          </p>
          <Search
            title="Notify Me"
            handleSubscription={handleSubscribeToComingSoon}
          />
          <p className="mt-4">
            *We send regular email updates, safety tips and <br />
            more to your inbox weekly.
          </p>
        </div>
        <div className="lg:w-1/2 basis-full w-0">
          <img className="bg-cover" src={woman_hand} alt="Iphone on hand" />
        </div>
      </div>
      <Footer containerStyle="absolute bottom-0" />
    </div>
  );
}

export default ComingSoon;
