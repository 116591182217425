import React from "react";

function TextButton({ title, onClick, style = "" }) {
  return (
    <button
      onClick={onClick}
      className={`text-neutral text-base font-normal font-SF-Pro-Regular bg-slightRed px-5 py-3 rounded-full ${style}`}
    >
      {title}
    </button>
  );
}

export default TextButton;
