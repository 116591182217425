import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { pickemoji } from "../../constants/images";
import {
  getPost,
  setEventCommentAction,
} from "../../redux/peoplePost/peoplepost.actions";
import { getPostCommentStatus } from "../../redux/posts/posts.action";
import dayjs from "dayjs";
import CommentCard from "../DashboardHome/CommentCard";
import UserPostShareHome from "./UserPostShare/UserPostShareHome";
import Xclose from "../../assets/images/x-close.svg";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
/* eslint-disable import/no-webpack-loader-syntax */
import mapboxgl from "mapbox-gl";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import { getDistanceFromLatLonInKm } from "../../utils/utils";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoiYWRtaW5lZmZpY2lvbiIsImEiOiJjbGI2d2JvZHUwNTFsM3ZvZGhmaHYxZ21pIn0.LCwPwD2DbgM0piyDwL4CJw",
  logoPosition: "bottom-right",
});

function SingleUserPost({ page, isOpen, onClose, id, theme, updateCounts }) {
  const [message, setMessage] = useState("");
  const [onReply, setOnReply] = useState(false);
  const [replyId, setReplyId] = useState("");
  const [replyMessage, setReplyMessage] = useState("");
  const token = useSelector((state) => state.auth.accessToken);
  const user = useSelector((state) => state.auth.user);
  const comment = useSelector((state) => state.events.comment);
  const singleEventData = useSelector((state) => state.events.peoplePost);
  const dispatch = useDispatch();
  const [editComment, setEditComment] = useState(false);
  const [editMessage, setEditMessage] = useState("");
  const [editCommentId, setEditCommentId] = useState("");
  const [newDoc, setNewDoc] = useState(null);
  const [, setLoadingMore] = useState(false);
  const [currentCount, setCurrentCount] = useState(2);
  const [nestedComment, setNestedComment] = useState([]);
  const [showNestedComment, setShowNestedComment] = useState(false);

  const getSingleEvent = (id) => {
    dispatch(getPost(id));
  };

  useEffect(() => {
    dispatch(getPost(id));
    // eslint-disable-next-line
  }, [comment, id]);

  useEffect(() => {
    dispatch(getPostCommentStatus());
    // eslint-disable-next-line
  }, []);

  const handlePostComment = (eventId) => {
    if (message !== "") {
      dispatch(setEventCommentAction(message, eventId));
      dispatch(getPost(id));
      updateCounts();
      setMessage("");
    }
  };

  const showReplyBox = (isTrue, id) => {
    setReplyId(id);
    setOnReply(isTrue);
  };

  const handleReplyComment = async (commentId, username, displayName) => {
    try {
      if (replyMessage !== "") {
        const { data } = await axios.post(
          `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/events/${singleEventData?.eventId}/comment/${commentId}`,
          {
            text: replyMessage,
            replyTo: { username, displayName },
          },
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data) {
          getSingleEvent(id);
          setReplyMessage("");
        }
      }
    } catch (err) {}
  };

  const handleOpenEditComment = (comment) => {
    setEditComment(true);
    setEditCommentId(comment.commentId);
    setEditMessage(comment.text);
  };

  const handleEditPost = async () => {
    if (editMessage !== "") {
      try {
        const { data } = await axios.post(
          `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/comment/edit/${editCommentId}`,
          {
            text: editMessage,
          },
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data) {
          getSingleEvent(id);
        }
      } catch (err) {}
    }
  };

  const close = (data) => {
    updateCounts();
    onClose();
  };

  const allNestedComments = async (id) => {
    try {
      const { data } = await axios.get(
        `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/event/${singleEventData?.id}/comment/${id}/nestedComment/${newDoc}`,

        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNewDoc(data.lastDoc);
      setNestedComment(
        [...nestedComment, ...data.nestedCommentData]
        // .filter(
        //   (each, index, arr) =>
        //     index ===
        //     arr.findIndex(
        //       (v) =>
        //         v.id === each.id &&
        //         !nestedComment.map((v) => v.nestedCommentId).includes(each.id)
        //     )
        // )
      );
      let presentCount = currentCount - 10;
      setLoadingMore(false);
      if (presentCount <= 0) {
        setCurrentCount(0);
      } else {
        setCurrentCount(presentCount);
      }
    } catch (err) {
      setLoadingMore(false);
    }
  };
  const getAllNestedComments = (id) => {
    // if (!newDoc) return;
    setLoadingMore(true);
    allNestedComments(id);
    setShowNestedComment(true);
  };

  console.log(singleEventData);
  return (
    <Modal className="p-0 m-0" isOpen={isOpen} onClose={close} size={"4xl"}>
      <ModalOverlay />
      <ModalContent
        className="p-0 m-0 "
        containerProps={{ padding: 0, margin: 0 }}
      >
        {singleEventData && singleEventData !== {} && (
          <div className="w-full h-[650px] flex rounded-md  flex-col sm:flex-row">
            {/* slider */}
            <div className=" w-[50%] h-full bg-black">
              <div
                className=" ml-[27px] pt-[14px]  h-[10%] cursor-pointer"
                onClick={() => close()}
              >
                <div className=" bg-[#F9F9FB] flex items-center rounded-[50%] px-[.3rem] w-[27px] h-[27px] text-center">
                  <img className="w-full h-full " src={Xclose} alt="cancel" />
                </div>
              </div>
              <div className=" h-[90%] flex items-center w-full ">
                <div className=" h-[50%]  w-full ">
                  <Carousel
                    className=" "
                    autoPlay={false}
                    infiniteLoop={true}
                    showStatus={false}
                    showThumbs={false}
                    centerMode={true}
                    centerSlidePercentage={100}
                    showArrows={false}
                    interval={5000}
                  >
                    {singleEventData?.media?.videos?.length > 0 ? (
                      singleEventData?.media?.videos?.map((item, i) => (
                        <video
                          key={i}
                          loop
                          muted
                          playsInline
                          autoPlay={true}
                          controls
                          className="w-full object-cover rounded-[5px] h-[300px]  mx-auto"
                        >
                          <source src={item} type="video/mp4" />
                          {/* <source src="movie.ogg" type="video/ogg" /> */}
                          Your browser does not support the video tag.
                        </video>
                      ))
                    ) : singleEventData?.media?.images.length === 0 ? (
                      <div className="w-full bg-[#272323] h-[50%] mt-2 "></div>
                    ) : (
                      singleEventData?.media?.images?.map((item, i) => (
                        <img
                          key={i}
                          src={item}
                          className="w-full h-[300px] object-cover"
                          alt="user"
                        />
                      ))
                    )}
                  </Carousel>
                </div>
              </div>
            </div>

            <div
              className={`w-[50%] h-full flex flex-col overflow-x-hidden overflow-y-auto justify-between ${
                theme
                  ? "bg-kdark3 text-white border-[#323232]"
                  : "bg-[#fff] text-[#27272E] border-[#EAECF0]"
              }`}
            >
              {/* single message */}
              <div className="w-full  mt-[30px] ">
                <div className="flex px-[37px] justify-between">
                  <div className="flex  w-full">
                    <img
                      alt="user"
                      className="w-[40px] h-[40px] rounded-full"
                      src={singleEventData?.userImage}
                    />

                    <div className="ml-[10px] w-full ">
                      <div className="flex items-center justify-between w-full text-[13px]">
                        <div className="flex items-center">
                          <p className=" mr-[10px]">
                            {singleEventData?.username?.length > 10 ? (
                              <>
                                {`${singleEventData?.username.substring(
                                  0,
                                  10
                                )}...`}{" "}
                              </>
                            ) : (
                              singleEventData?.username
                            )}{" "}
                          </p>
                          <div className="w-[5px] h-[5px] bg-[#F97066] rounded-full"></div>
                          <div className=" m-[10px] flex">
                            {page === "profile"
                              ? "a while ago"
                              : dayjs(singleEventData?.createdAt)?.fromNow()}
                          </div>
                        </div>
                        <div className="text-[#DC6803] bg-[#FDEAD7] rounded-[15px] px-[.5rem] py-[.2rem] text-[12px] font-semibold ml-[6px]">
                          {getDistanceFromLatLonInKm(
                            user?.coords?.latitude,
                            user?.coords?.longitude,
                            singleEventData?.coords?.latitude,
                            singleEventData?.coords?.longitude
                          )}{" "}
                          km
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex px-[37px] justify-between  mr-[1rem] ">
                  <div className="text-[15px] text-[#253855] font-semibold">
                    {singleEventData?.title}
                  </div>
                </div>
                <div className="mt-2 px-[37px] text-[14px] text-[#425466]  mr-[1rem]">
                  {singleEventData?.text}
                </div>
                <div className="px-6 mt-2  mr-[1rem] pb-4">
                  {singleEventData?.coords && (
                    <Map
                      // eslint-disable-next-line
                      // onZoom={(item, e) => {
                      //   item.flyTo({
                      //     center: [
                      //       singleEventData?.coords?.latitude,
                      //       singleEventData?.coords?.longitude,
                      //     ],
                      //   });
                      // }}
                      zoom={[3]}
                      movingMethod="flyTo"
                      pitch={[10]}
                      boxZoom={true}
                      // renderChildrenInPortal={true}
                      // eslint-disable-next-line
                      style="mapbox://styles/mapbox/streets-v8?optimize=false"
                      center={[
                        singleEventData?.coords?.latitude,
                        singleEventData?.coords?.longitude,
                      ]}
                      containerStyle={{
                        height: "200px",
                        width: "100%",
                      }}
                    >
                      <Marker
                        coordinates={[
                          singleEventData?.coords?.latitude,
                          singleEventData?.coords?.longitude,
                        ]}
                        // anchor="top"
                        className=" w-[10px] h-[10px] rounded-[50%] bg-white p-[.1rem]"
                      >
                        <div className="bg-red-500  rounded-[50%] w-full h-full"></div>
                      </Marker>
                    </Map>
                  )}
                </div>
                <div className="mx-6 border-[#02023414] border-b-[.2px]"></div>
                <div className="w-full   mt-[.8rem] ">
                  <p className="text-[#425466] px-[37px]  my-[1rem] ">
                    Comments ({singleEventData?.commentCount})
                  </p>

                  <div className="w-full">
                    {singleEventData?.commentCount > 0
                      ? singleEventData?.comments.map((data, index) => (
                          <CommentCard
                            data={data}
                            userData={user}
                            index={index}
                            showNestedComment={showNestedComment}
                            nestedComment={nestedComment}
                            onReply={onReply}
                            replyId={replyId}
                            setReplyMessage={setReplyMessage}
                            handleReplyComment={handleReplyComment}
                            replyMessage={replyMessage}
                            getAllNestedComments={getAllNestedComments}
                            showReplyBox={showReplyBox}
                            handleOpenEditComment={handleOpenEditComment}
                          />
                        ))
                      : null}
                  </div>
                </div>
                <div className="border-t-[.2px] px-[37px] border-[#02023414]">
                  <UserPostShareHome
                    updateCounts={updateCounts}
                    callBacks={getSingleEvent}
                    commentCount={singleEventData?.commentCount}
                    likeCount={singleEventData?.likeCount}
                    page="single"
                    theme={theme}
                    user={user}
                    selectedPost={singleEventData}
                    handleOpenEditComment={handleOpenEditComment}
                  />
                </div>
              </div>
              {/* chat box */}
              {!editComment && (
                <div
                  className={`w-full h-[74px] flex items-center justify-self-end border-t 
                   
                   pl-[13px] ${
                     theme
                       ? "bg-kdark3 text-white border-[#7e7c7c]"
                       : "bg-[#fff] text-[#27272E] border-[#EAECF0]"
                   }`}
                >
                  {token === "" ? (
                    <div className="w-full h-full flex items-center">
                      <div className="mr-[13px]">
                        <img src={pickemoji} alt="emohi" />
                      </div>
                      <div className="w-[402px] h-full bg-[#F7F7F7] rounded-[20px]">
                        <input
                          className="border-none outline-none w-full h-full"
                          placeholder="Log in to post a comment"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className={`w-full h-full flex items-center `}>
                      <div className="w-[40px] h-[40px] bg-[#FCBE5A] rounded-[50%] flex justify-center items-center">
                        <img
                          src={user?.imageUrl}
                          alt="user "
                          className="h-full w-full rounded-[50%]"
                        />
                      </div>
                      {singleEventData?.comment === "on" ? (
                        <div
                          className={`w-[90%] flex  rounded-[31px] mx-3 my-2 px-2 ${
                            theme
                              ? "bg-kdark3 text-white border-[#323232]"
                              : "bg-[#F7F7F7] text-[#27272E] border-[#EAECF0]"
                          }`}
                        >
                          <div className="w-full h-[44px] ">
                            <input
                              className="w-full h-full bg-transparent border-none outline-none px-2"
                              value={message}
                              placeholder="Add a comment...."
                              onChange={(e) => setMessage(e.target.value)}
                            />
                          </div>
                          <button
                            className="text-[#FCBE5A]"
                            onClick={() =>
                              handlePostComment(singleEventData?.eventId)
                            }
                          >
                            Post
                          </button>
                        </div>
                      ) : (
                        <p className="text-[#7e7c7c] ml-[1rem]">
                          {" "}
                          Comment turned off
                        </p>
                      )}
                    </div>
                  )}
                </div>
              )}
              {editComment && (
                <div
                  className={`w-full h-[74px] flex items-center justify-self-end border-t ${
                    token === "" ? "bg-[#FCFCFD]" : "bg-white"
                  } border-[#EAECF0] pl-[13px]`}
                >
                  {token === "" ? (
                    <div className="w-full h-full flex items-center">
                      <div className="mr-[13px]">
                        <img src={pickemoji} alt="emoji" />
                      </div>
                      <div className="w-[402px] h-full bg-[#F7F7F7] rounded-[20px]">
                        <input
                          className="border-none outline-none w-full h-full"
                          placeholder="Log in to post a comment"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="w-full h-full flex items-center">
                      <div className="w-[44px] h-[44px] bg-[#FCBE5A] rounded-full flex justify-center items-center">
                        <FaUser />
                      </div>
                      <div className="w-full flex bg-[#F7F7F7] rounded-[31px] mx-3 px-2">
                        <div className="w-full h-[44px] ">
                          <input
                            className="w-full h-full bg-transparent border-none outline-none px-2"
                            value={editMessage}
                            placeholder="Edit Comment...."
                            onChange={(e) => setEditMessage(e.target.value)}
                          />
                        </div>
                        <button
                          className="text-[#FCBE5A]"
                          onClick={() =>
                            handleEditPost(singleEventData?.eventId)
                          }
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
}

export default SingleUserPost;
