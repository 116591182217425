import { useDisclosure } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllUserLivestreams, userPost } from "../../redux/api";
import { setModalStatus } from "../../redux/auth/auth.actions";
import EditProfile from "../AccountComponent/EditProfile";
import DashboardLayout from "../DashboardHome/DashboardLayout";
import SingleUserPost from "../SingleUserPost/SingleUserPost";
import SingleLivestream from "../Livestream/ViewModal";
import { fetchFlaggedPosts } from "../../redux/posts/posts.action";

function UserProfile() {
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const [activeTab, setActive] = useState("Posts");
  const [events, setEvents] = useState([]);
  const [selectedLivestreams, setSelectedLivestream] = useState({});
  const [livestreams, setLivestream] = useState([]);
  const [, setLivestreamView] = useState(false);
  const [, setLoading] = useState(false);
  const { user, accessToken } = useSelector((state) => state.auth);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [accountModalId, setAccountModalId] = useState(0);
  const [currentPostId, setCurrentPostId] = useState(null);
  const [, setSuspended] = useState(false);
  const uploadModalStatus = useSelector((state) => state.auth.showUploadModal);
  const flaggedPosts = useSelector((state) => state.posts.flagPosts);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectItem = (item) => {
    setActive(item);
  };

  const handleAccountModal = (id) => {
    setAccountModalId(id);
    onOpen();
  };

  const handleSinglePostModal = (post) => {
    if (accessToken) {
      if (user.suspend) {
        setSuspended(true);
      } else {
        dispatch(setModalStatus(10));
        setCurrentPostId(post?.id);
        onOpen();
        getUserEvents();
      }
    } else {
      navigate("/login");
    }
  };
  const getUserEvents = async () => {
    try {
      setLoading(true);
      const { data } = await userPost(user?.username);
      if (data) {
        setEvents(data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getUserLivestreams = async () => {
    try {
      setLoading(true);
      const { data } = await getAllUserLivestreams(user?.username);
      if (data) {
        setLivestream(data?.allStreams);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getData = useCallback(() => {
    getUserEvents(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUserEvents();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchFlaggedPosts());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getUserLivestreams();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!accessToken) {
      navigate("/dashboard/home");
    }
    // eslint-disable-next-line
  }, []);

  const veiwStream = (item) => {
    setSelectedLivestream(item);
    onOpen();
    setLivestreamView(true);
    dispatch(setModalStatus(13));
  };

  const closeStream = () => {
    onClose();
    setLivestreamView(false);
    dispatch(setModalStatus(0));
  };

  return (
    <DashboardLayout>
      <div className="w-full mb-[8rem]">
        <div className="mx-auto mt-[4rem]">
          <div className="mx-auto w-[160px] h-[160px] rounded-[50%] bg-black mt-[4rem]">
            <img
              src={user?.imageUrl}
              alt="user"
              className="w-full h-full rounded-[50%] object-cover"
            />
          </div>
          <h4 className="font-light text-center mt-[1rem] capitalize">
            {user?.username}
          </h4>
          <p className=" font-thin text-center ">{user?.email}</p>
          <p
            onClick={() => handleAccountModal(1)}
            className=" text-center mx-auto cursor-pointer w-[150px] mt-[1rem] px-[1rem] py-[.5rem] border-[#425466] border-[.4px] rounded-[20px]"
          >
            Edit Profile
          </p>
        </div>
        <div className="w-full">
          <ul className="flex cursor-pointer w-[70%] mt-[3rem] mx-auto justify-around  pb-[.5rem]">
            <li
              onClick={() => selectItem("Posts")}
              className={`pb-[1rem] text-center w-[33.3%] ${
                activeTab === "Posts"
                  ? "border-b-2 border-[#FC5059] "
                  : "border-b-[.1px] border-[#a8a9a9]"
              }`}
            >
              Posts
            </li>
            <li
              onClick={() => selectItem("Livestreams")}
              className={`pb-[1rem] text-center w-[33.3%] ${
                activeTab === "Livestreams"
                  ? "border-b-2 border-[#FC5059] "
                  : "border-b-[.1px] border-[#a8a9a9]"
              }`}
            >
              Livestreams
            </li>
            <li
              onClick={() => selectItem("Removed")}
              className={`text-center pb-[1rem] w-[33.3%] ${
                activeTab === "Removed"
                  ? "border-b-2 border-[#FC5059] "
                  : "border-b-[.1px] border-[#a8a9a9]"
              }`}
            >
              Removed
            </li>
          </ul>

          <div>
            {activeTab === "Posts" && (
              <div className="flex w-[70%] justify-between mx-auto flex-wrap ">
                {events &&
                  events?.map((item, i) => (
                    <div
                      className="w-[33%]  mb-[.5rem] cursor-pointer"
                      key={i}
                      onClick={() => handleSinglePostModal(item)}
                    >
                      {item?.media?.images[0] === undefined ? (
                        <div className="w-full bg-black h-full p-[8rem]"></div>
                      ) : (
                        <img
                          src={item?.media?.images[0]}
                          alt="post visual"
                          className="w-full h-full object-cover"
                        />
                      )}
                    </div>
                  ))}
              </div>
            )}
            {activeTab === "Livestreams" && (
              <div className="flex w-[70%] justify- gap-1 mx-auto flex-wrap ">
                {livestreams &&
                  livestreams?.map((item, i) => (
                    <div className="w-[33%]   cursor-pointer" key={i}>
                      {item?.replayLink === "" ? (
                        <div className="bg-[#e3eed6] flex text-center items-center rounded-md w-full h-[200px]">
                          <p>The livestream recording is not available.</p>
                        </div>
                      ) : (
                        <div
                          onClick={() => veiwStream(item)}
                          className=" flex text-center items-center rounded-md bg-black w-full  h-[200px]"
                        ></div>
                      )}
                    </div>
                  ))}
              </div>
            )}
            {activeTab === "Removed" && (
              <>
                <div className="flex w-[70%] justify-between mx-auto flex-wrap ">
                  {flaggedPosts
                    ?.filter((post) => post.username === user?.username)
                    .map((item) => (
                      <div className="w-[33%] mb-[.5rem]">
                        <div className="w-full bg-black h-[70%] p-[8rem]"></div>
                        <p>{item?.text}</p>
                      </div>
                    ))}
                </div>

                {flaggedPosts?.filter(
                  (post) => post.username === user?.username
                ).length === 0 && (
                  <div className="w-[50%] mx-auto mt-[5rem]  text-center bg-white px-[4rem] py-[2rem] rounded-[15px]">
                    <p>
                      There are no <strong>Flagged posts</strong> available yet,
                      your posts will show up here.
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {
        <SingleLivestream
          isOpen={isOpen && uploadModalStatus === 13}
          onClose={closeStream}
          liveStream={selectedLivestreams}
          type={"Edit"}
        />
      }
      {
        <EditProfile
          isOpen={accountModalId === 1 && isOpen}
          onClose={onClose}
          type={"Edit"}
        />
      }
      {currentPostId && accessToken && (
        <SingleUserPost
          page="profile"
          updateCounts={getData}
          handle={() => handleSinglePostModal()}
          theme={darktheme}
          id={currentPostId}
          isOpen={isOpen && uploadModalStatus === 10}
          onClose={onClose}
        />
      )}
    </DashboardLayout>
  );
}

export default UserProfile;
