import { combineReducers } from "redux";
import { authReducer } from "./auth/auth.reducer";
import { featuresReducer } from "./features/features.reducer";
import { postsReducer } from "./posts/posts.reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { peoplePostReducer } from "./peoplePost/peoplepost.reducer";
import { alertReducer } from "./alert/alert.reducer";
import { livestreamReducer } from "./live/live.reducer";

export const Allreducer = combineReducers({
  posts: postsReducer,
  features: featuresReducer,
  auth: authReducer,
  events: peoplePostReducer,
  alert: alertReducer,
  livestream: livestreamReducer,
});

const configStorage = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(configStorage, Allreducer);
export default persistedReducer;
