import { alertTypes } from "./alert.types"

const initialState = {
    Activitydata: [],
    AroundData:[],
    hasMore: true,
    index:1,
    error: false,
}

export const alertReducer = (state=initialState, action) => {
    switch(action.type){
        case alertTypes.SET_ALL_ACTIVITY_SUCCESS:
            return {
                ...state,
                Activitydata:[
                    ...state.Activitydata,
                    action.payload
                ]
            }
        case alertTypes.SET_ALL_AROUND_SUCCESS:
            return {
                ...state,
                AroundData:[
                    ...state.AroundData,
                    action.payload
                ]
            }
        case alertTypes.NO_MORE_DATA:
            return {
                ...state,
                hasMore: false
            }
        case alertTypes.GET_MORE_DATA:
            return {
                ...state,
                index: state.index + 1
            }
        case alertTypes.SET_ALL_ACTIVITY_ERROR:
            return {
                ...state,
                error:true
            }
        case alertTypes.SET_ALL_AROUND_ERROR:
            return {
                ...state,
                error:true
            }
        default:
            return state;
    }
}