import { postsType } from "./posts.type";

const initialState = {
  posts: [],
  post: [],
  error: false,
  loading: false,
  likes: 0,
  counts: 0,
  uploadLoading: false,
  uploadData: {},
  commentSwitch: {},
  switchLoading: false,
  viewLoading: false,
  postId: "",
  modalId: 0,
  flagPostsLoading: false,
  flagPosts: [],
  activeCategory: "",
};

export const postsReducer = (state = initialState, action) => {
  switch (action.type) {
    case postsType.FETCH_POSTS:
      return {
        ...state,
        posts: action.payload,
      };
    case postsType.SET_POSTS:
      return {
        ...state,
        post: action.payload,
      };
    case postsType.FETCH_POSTS_ERROR:
      return {
        ...state,
        error: true,
      };
    case postsType.LIKE_POST_START:
      return {
        ...state,
        loading: true,
      };
    case postsType.LIKE_POST:
      return {
        ...state,
        loading: false,

        likes: action.payload,
      };
    case postsType.LIKE_POST_FAILED:
      return {
        ...state,
        loading: false,

        error: true,
      };
    case postsType.FETCH_NOTIFICATON_COUNT_START:
      return {
        ...state,
        loading: true,
      };
    case postsType.FETCH_NOTIFICATON_COUNT:
      return {
        ...state,
        loading: false,

        counts: action.payload,
      };
    case postsType.FETCH_NOTIFICATON_COUNT_ERROR:
      return {
        ...state,
        loading: false,

        error: true,
      };

    case postsType.UPLOAD_POST_START:
      return {
        ...state,
        uploadLoading: true,
      };
    case postsType.UPLOAD_POST:
      return {
        ...state,
        uploadLoading: false,
        uploadData: action.payload,
      };
    case postsType.UPLOAD_POST_ERROR:
      return {
        ...state,
        uploadLoading: false,
        error: true,
      };
    case postsType.SWITCH_POST_COMMENT_START:
      return {
        ...state,
        switchLoading: true,
        commentSwitch: false,
      };
    case postsType.SWITCH_POST_COMMENT:
      return {
        ...state,
        switchLoading: false,
        commentSwitch: action.payload,
      };
    case postsType.SET_CATEGORY_TYPE_START:
      return {
        ...state,
        categoryLoading: true,
      };
    case postsType.SET_CATEGORY_TYPE_SUCCESSFUL:
      return {
        ...state,
        categoryLoading: false,
        activeCategory: action.payload,
      };
    case postsType.GET_POST_COMMENT_STATUS_START:
      return {
        ...state,
        switchLoading: true,
      };
    case postsType.GET_POST_COMMENT_STATUS:
      return {
        ...state,
        switchLoading: false,
        commentSwitch: state.commentSwitch,
      };
    case postsType.VIEW_SINGLE_POST:
      return {
        ...state,
        viewLoading: false,
        postId: action.payload.postId,
        modalId: action.payload.modalId,
        modalActive: true,
      };

    case postsType.CLOSE_SINGLE_POST:
      return {
        ...state,
        viewLoading: false,
        postId: 0,
        modalId: null,
        modalActive: false,
      };
    case postsType.FETCH_FLAGGED_POSTS_START:
      return {
        flagPostsLoading: true,
      };
    case postsType.FETCH_FLAGGED_POSTS_SUCCESSFUL:
      return {
        flagPostsLoading: false,
        flagPosts: action.payload,
      };
    case postsType.FETCH_FLAGGED_POSTS_FAILED:
      return {
        flagPostsLoading: false,
      };
    default:
      return state;
  }
};
