import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCompletePhoneOauthAction } from '../../../redux/auth/auth.actions';
import InputField from '../../InputField';
import Loader from '../../Loader';

const mapState = (state) => ({
  isLoading: state.features.isLoading
})
function WithUserName() {
  const {isLoading} = useSelector(mapState);
  const [username, setUsername] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCreateUsername = (e) => {
    e.preventDefault();
    dispatch(setCompletePhoneOauthAction(username,navigate));
  }

  const onChangeValue = (e) => {
    setUsername(e.target.value);
  }

  return (
    <form className='w-full' onSubmit={handleCreateUsername}>
        <div>
          <InputField label={'Create Username'} name="username" value={username} placeholder="Enter Username" changeValue={onChangeValue} />
        </div>
        <div className="flex justify-center items-center w-full h-[48px] mt-[21px] rounded-[5px] text-white bg-[#F04438]">
        {isLoading ? <Loader /> :<button type="submit" className='w-full h-full cursor-pointer border-none outline-none'>Continue</button>}
        </div>
        <div className="flex justify-center items-center w-full h-[48px] mt-[41px] text-black">
          Skip for later
        </div>
    </form>
  )
}

export default WithUserName