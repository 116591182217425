import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import user from "../../assets/images/userx.png";

function AccountModal({ isOpen, onClose, title, description, type }) {
  return (
    <Modal className="w-full " isOpen={isOpen} onClose={onClose} size={"md"}>
      <ModalOverlay />
      <ModalContent
        className="w-full overflow-auto "
        containerProps={{ justifyContent: "center" }}
      >
        <ModalCloseButton />
        <ModalBody>
          <div className="mt-[3rem] rounded-[50%] w-[50px]  h-[50px] flex align-middle justify-center  items-center bg-[#FFF0F1]">
            <img src={user} alt="User" className=" object-contain" />
          </div>
          <div className="mt-[.5rem]  pb-[3rem]">
            <h4 className="mb-[2rem]">{title}</h4>
            <h4 className="mb-[.1rem]">What happened?</h4>

            <p>{description}</p>
            {type === "Suspended" && (
              <>
                <h4 className="mb-[1rem] mt-[1rem]">What you can do:</h4>
                <ul className="list-disc">
                  To unlock your account, you must do the following:
                  <li className="ml-[1rem]">Pass an Arkose challenge</li>
                  <li className="ml-[1rem]">Verify your phone number</li>
                </ul>
              </>
            )}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default AccountModal;
