import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setThemeAction } from "../../redux/features/features.action";

function ThemeToggle() {
  const dispatch = useDispatch();
  const darktheme = useSelector((state) => state.features.settings.darktheme);

  const handleChange = (e) => {
    dispatch(setThemeAction());
  };

  return (
    <div class="flex items-center  justify-center  ">
      <div
        className={`w-10 h-4 flex items-center rounded-full  cursor-pointer ${
          darktheme ? "bg-gray-400 " : "bg-gray-300"
        }`}
        onClick={handleChange}
      >
        <div
          className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${
            darktheme ? "translate-x-6" : ""
          }`}
        />
      </div>
    </div>
  );
}

export default ThemeToggle;
