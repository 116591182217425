import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { footerRoutes } from "../../constants/data";
import { arrowRightIcon, bigKey, qrCodeIos, qrCodeAndroid } from "../../constants/images";

const platfroms = ["iOs", "Android"];

function GeneralFooter({ style = "" }) {
  const [platform, setPlaform] = useState("iOs");
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col space-y-4 md:space-y-0 md:space-x-4 justify-between md:flex-row py-12 md:pt-[72px] px-4 md:px-32 ${style}`}
    >
      <div className="space-y-4 flex-1">
        <div className="bg-[#E5484D] flex flex-col justify-center items-center rounded-3xl px-24 md:px-28 pt-10 pb-6">
          <img src={platform === "Android" ? qrCodeAndroid: qrCodeIos} alt="qrCode" className="object-contain w-full" />
          <p className="text-white mt-2 mb-[14px] font-SF-Pro-Regular">Scan & Download</p>
          <div className="flex flex-row bg-[#0000301B] p-2 rounded-full items-center cursor-pointer">
            {platfroms.map((item, index) => (
              <div
                key={index}
                onClick={() => setPlaform(item)}
                className={`text-white text-sm font-SF-Pro-Regular px-8 py-4 ${
                  platform === item && "rounded-full bg-white/30" 
                }`}
              >
                {item}
              </div>
            ))}
          </div>
        </div>

        <div className="rounded-3xl bg-footer-bg h-64 flex flex-col items-start justify-end bg-cover bg-no-repeat pb-6 pl-6">
          <div 
            onClick={() => { navigate("/"); }}
            className="cursor-pointer rounded-full space-x-3 flex-row justify-between px-6 py-[17px] items-center bg-white inline-flex"
          >
            <p className="font-Eina01-Regular">Team</p>
            <div>
              <img
                src={arrowRightIcon}
                alt="arrowRightIcon"
                className="object-contain"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-lightPink rounded-3xl flex-1 flex flex-col pl-8 justify-end pb-10 space-y-7">
        <img src={bigKey} alt="bigKey" className="hidden md:block w-[97px]" />
        <p className="text-3xl font-Eina01-Bold">
          Our <br /> approach to <br />
          privacy
        </p>
      </div>

      <div className="flex flex-col flex-1 space-y-4">
        <div className="bg-lightPink rounded-3xl pl-8 pt-24 md:pt-28 pb-6">
          <ul className="flex flex-col space-y-4">
            {footerRoutes.map((route, index) => (
              <li key={index}>
                <NavLink
                  className="text-[#1C2024] font-normal text-xl font-Eina01-Semibold"
                  to={route.path}
                >
                  {route.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>

        <div className="bg-lightPink rounded-3xl py-8 flex flex-1 px-8 items-center">
          <p className="font-SF-Pro-Regular">
            © 2024 Efficion LLC. Reach out to us at hello@efficion.org Built at
            Efficion.Org
          </p>
        </div>
      </div>
    </div>
  );
}

export default GeneralFooter;
