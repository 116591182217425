import React, { useEffect } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getAllActivityAction,
  getMoreData,
} from "../../../redux/alert/alert.actions";
import { setIsLoading } from "../../../redux/features/features.action";
import Loader from "../../Loader";
import { getRemainingDate } from "../../../utils/utils";
import EmptyState from "../../../assets/images/empty.png";
const mapState = (state) => ({
  token: state.auth.accessToken,
  Activitydata: state.alert.Activitydata,
  index: state.alert.index,
  hasMore: state.alert.hasMore,
  isLoading: state.features.isLoading,
});
function Activity() {
  const dispatch = useDispatch();
  const { Activitydata, index, hasMore, isLoading } = useSelector(mapState);
  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(getAllActivityAction());
    // eslint-disable-next-line
  }, [index]);

  const getMoreActivityData = () => {
    dispatch(getMoreData());
  };

  if (Activitydata.length > 0) {
  }
  return (
    <div className="w-full">
      <InfiniteScroll
        dataLength={Activitydata.length}
        next={getMoreActivityData}
        hasMore={hasMore}
        // loader={<div className="text-center">Loading ...</div>}
      >
        {isLoading ? (
          <div className="w-full mt-[20px] flex justify-center">
            <Loader big={true} />
          </div>
        ) : Activitydata?.length > 0 ? (
          Activitydata.map((data) => {
            return (
              <div className="w-full mb-[33px]">
                <div className="w-full flex justify-center items-center mt-9 mb-5">
                  <span className="w-[54px] h-[1px] bg-[#425466]"></span>
                  <span className="mx-[9px]">
                    {" "}
                    {getRemainingDate(data.createdAt)}
                  </span>
                  <span className="w-[54px] h-[1px] bg-[#425466]"></span>
                </div>
                <div className="max-w-[550px] mx-auto px-[20px] flex justify-between items-center">
                  <div className="flex">
                    <div className="rounded-[12px] mr-2">
                      <img
                        src={data?.media?.images[0]}
                        alt="post pics"
                        className="w-[78px] h-[78px] rounded-[12px]"
                      />
                    </div>
                    <div>
                      <div className="text-[#425466] text-kbody7">
                        3 mins ago
                      </div>
                      <div className="text-[#425466] text-kbody6 font-semibold my-2 w-[350px]">
                        {data.title}
                      </div>
                    </div>
                  </div>

                  <div>
                    <MdKeyboardArrowRight />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="mx-auto h-[80vh] flex items-center w-[80%] text-center shadow-sm py-[2rem]">
            <div className="">
              <img
                src={EmptyState}
                alt="Empty state"
                className="mx-auto w-[150px] object-contain"
              />
              <p className="text-xl ">
                {" "}
                You don’t have any activity yet, your activity will show up
                here.
              </p>
            </div>
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
}

export default Activity;
