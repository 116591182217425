import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../../../redux/features/features.action";
import { toast } from "react-toastify";
import { deleteUserAccount } from "../../../redux/auth/auth.actions";

function DeleteAccount({ isOpen, onClose }) {
  const { accessToken } = useSelector((state) => state.auth);
  const [userReason, setReason] = useState("");
  const [disable, setDisabled] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!accessToken) {
      navigate("/dashboard/home");
    }
    // eslint-disable-next-line
  }, []);
  const change = (e) => {
    if (e.target.value.length > 0) {
      setDisabled(false);
      setReason(e.target.value);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    if (userReason !== "" && disable === false) {
      dispatch(setIsLoading(true));
      dispatch(deleteUserAccount({ reason: userReason }, navigate));
      // window.location.reload(false);
    } else {
      toast.error("Kindly state your reason.");
    }
  };
  return (
    <Modal className="w-full" isOpen={isOpen} onClose={onClose} size={"md"}>
      <ModalOverlay />
      <ModalContent className=" " containerProps={{ justifyContent: "center" }}>
        <ModalHeader color="#2B2B2B">
          <div className="w-full flex justify-center">Delete Account</div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="py-3 p-2 pr-[rem] cursor-pointer">
            <h3>Why do you want to delete you account?</h3>

            <select
              name="reason"
              onChange={(e) => change(e)}
              className="w-[100%] mt-[1rem] px-[1rem] py-[.5rem] border-2 border-[blac]"
            >
              <option defaultValue>Select an option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </ModalBody>

        <ModalFooter>
          <button
            onClick={(e) => handleDelete(e)}
            className={`flex justify-center items-center w-full h-[48px] mt-[21px] rounded-[5px] text-white  ${
              disable ? "bg-[#dad9d9]" : "bg-pry"
            }`}
          >
            Delete Account
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default DeleteAccount;
