import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authTypes } from "../../../redux/auth/auth.types";

function SwitchAccount({ isOpen, onClose }) {
  const users = JSON.parse(localStorage.getItem("users"));
  const { accessToken } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!accessToken) {
      navigate("/dashboard/home");
    }
    // eslint-disable-next-line
  }, []);
  const switchUser = (payload) => {
    dispatch({
      type: authTypes.SWITCH_ACCOUNT,
      payload,
    });
  };
  return (
    <Modal className="w-full" isOpen={isOpen} onClose={onClose} size={"md"}>
      <ModalOverlay />
      <ModalContent className=" " containerProps={{ justifyContent: "center" }}>
        <ModalHeader color="#2B2B2B">
          <div className="w-full flex justify-center">Switch Account</div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="py-3 pl-2 pr-[3rem] cursor-pointer">
            {users &&
              users?.map((item, index) => (
                <div
                  className="flex items-center mb-[1rem] "
                  key={index}
                  onClick={() => switchUser(item)}
                >
                  <div className="w-[50px] h-[50px] rounded-full bg-black mr-3"></div>
                  <div>
                    <h5>{jwtDecode(item.accessToken)?.email}</h5>
                    <p>{jwtDecode(item.accessToken)?.email}</p>
                  </div>
                </div>
              ))}
          </div>
        </ModalBody>

        <ModalFooter>
          <div className="flex justify-center items-center w-full h-[48px] mt-[21px] rounded-[5px] text-white bg-[#D0D5DD]">
            Add new account
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SwitchAccount;
