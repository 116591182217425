export const peoplePostTypes = {
  FETCH_PEOPLE_POSTS: "FETCH_PEOPLE_POSTS",
  FETCH_PEOPLE_POSTS_START: "FETCH_PEOPLE_POSTS_START",
  FETCH_PEOPLE_POSTS_ERROR: "FETCH_PEOPLE_POSTS_ERROR",
  FETCH_PEOPLE_POST: "FETCH_PEOPLE_POST",
  FETCH_PEOPLE_POST_START: "FETCH_PEOPLE_POST_START",
  FETCH_PEOPLE_POST_ERROR: "FETCH_PEOPLE_POST_ERROR",
  SET_EVENT_POST_COMMENT: "SET_EVENT_POST_COMMENT",
  POST_COMMENT: "POST_COMMENT",
  POST_COMMENT_START: "POST_COMMENT_START",
  POST_COMMENT_FAILED: "POST_COMMENT_FAILED",
};
