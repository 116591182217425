import React from "react";
import { useNavigate } from "react-router-dom";
import { aradugbo_icon } from "../../constants/images";
function Logo({ containerStyle }) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };
  return (
    <div
      className={`flex items-center cursor-pointer ${containerStyle}`}
      onClick={handleNavigate}
    >
      <div>
        <img src={aradugbo_icon} alt="aradugbo logo" />
      </div>
      <span className="ml-[7px] text-white text-[24px] font-bold">
        aradugbo
      </span>
    </div>
  );
}

export default Logo;
